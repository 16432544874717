import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IntlMessages from "../../helpers/IntlMessages";
import {
  IGeneroStatistics,
  IReportStatsGlobal,
} from "../../interfaces/report.interface";
import AppLocale from "../../lang";

const GeneroStatistics = ({
  genders,
  currency,
  metrics,
}: IGeneroStatistics) => {
  const [maxReach, setMaxReach] = useState(0);
  const [maxClicks, setMaxClicks] = useState(0);
  const lang = useSelector((state: any) => state.cuenta.lang);
  const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];
  const global = useSelector(
    (state: any) => state.reportes.currentReport.stats.global
  ) as IReportStatsGlobal;

  useEffect(() => {
    let max1 = 0;
    let max2 = 0;
    genders.forEach((gender) => {
      const reach = parseInt(gender.reach);
      const clicks = parseInt(gender.clicks);
      if (reach > max1) {
        max1 = reach;
      }
      if (clicks > max2) {
        max2 = clicks;
      }
    });
    setMaxReach(max1);
    setMaxClicks(max2);
  }, []);
  return (
    <div className="ht__generoStatistics">
      <div className="ht__generoStatistics-row ageStatics-header">
        <div>
          <IntlMessages id="modal.reports.specifications.gender" />
        </div>
        {Object.keys(metrics).map((e) => {
          if (metrics[e]) {
            if (e === "reach") {
              return (
                <div className="ht__generoStatistics-reach">
                  <IntlMessages id="modal.reports.specifications.scope" />
                </div>
              );
            } else {
              if (e === "clicks") {
                return (
                  <div className="ht__generoStatistics-clicks">Clicks</div>
                );
              } else {
                if (e === "cpc") {
                  return (
                    <div className="ht__generoStatistics-clicks-cpc">CPC</div>
                  );
                } else {
                  if (e === "ctr") {
                    return <div className="ht__generoStatistics-ctr">CTR</div>;
                  } else {
                    if (e === "spend") {
                      return (
                        <div className="ht__generoStatistics-clicks-cpc">
                          <IntlMessages id="modal.reports.specifications.cost" />
                        </div>
                      );
                    }
                  }
                }
              }
            }
          }
          return <></>;
        })}
        {/* <div>CPC</div>
        <div>CTR</div> */}
      </div>
      {genders.map((gender) => {
        const color =
          gender.gender === "male"
            ? "blue"
            : gender.gender === "female"
            ? "yellow"
            : "blueLight";

        return (
          <div className="ht__generoStatistics-row">
            <div className={`ht__generoStatistics-row-${gender.gender}`}>
              {" "}
              <div></div>{" "}
              {gender.gender === "male"
                ? currentAppLocale.messages["modal.reports.specifications.male"]
                : gender.gender === "female"
                ? currentAppLocale.messages[
                    "modal.reports.specifications.female"
                  ]
                : currentAppLocale.messages[
                    "modal.reports.specifications.unknown"
                  ]}
            </div>
            <div className="ht__generoStatistics-row-data">
              {Object.keys(metrics).map((e) => {
                if (metrics[e]) {
                  if (e === "reach") {
                    return (
                      <div className="ht__generoStatistics-reach">
                        <div
                          style={{
                            width: `${
                              (parseInt(gender.reach) * 70) / maxReach
                            }%`,
                          }}
                          className={`ht__generoStatistics-row-${color} ht__generominwidth`}
                        >
                          {gender.reach}
                        </div>
                        <p className="ht__ageStatistics-percentage">
                          {(
                            (parseInt(gender.reach) * 100) /
                            parseFloat(global.reach!)
                          ).toFixed(2)}
                          %
                        </p>
                      </div>
                    );
                  } else {
                    if (e === "clicks") {
                      return (
                        <div className="ht__generoStatistics-clicks">
                          <div
                            style={{
                              width: `${
                                (parseInt(gender.clicks) * 70) / maxClicks
                              }%`,
                            }}
                            className={`ht__generoStatistics-row-${color} ht__generominwidth`}
                          >
                            {gender.clicks}
                          </div>
                          <p className="ht__ageStatistics-percentage">
                            {(
                              (parseInt(gender.clicks) * 100) /
                              parseFloat(global.clicks!)
                            ).toFixed(2)}
                            %
                          </p>
                        </div>
                      );
                    } else {
                      if (e === "cpc") {
                        return (
                          <div className="ht__generoStatistics-clicks-cpc">
                            <span className="ht__generoStatistics-usd">
                              {currency}
                            </span>{" "}
                            {parseFloat(gender.cpc).toFixed(4)}
                          </div>
                        );
                      } else {
                        if (e === "ctr") {
                          return (
                            <div className="ht__generoStatistics-ctr">
                              <div
                                className={`ht__generoStatistics-color-${color}`}
                              >
                                {parseFloat(gender.ctr).toFixed(3)}%
                              </div>
                            </div>
                          );
                        } else {
                          if (e === "spend") {
                            return (
                              <div className="ht__generoStatistics-clicks-cpc">
                                <span className="ht__generoStatistics-usd">
                                  {currency}
                                </span>{" "}
                                {parseFloat(gender.spend).toFixed(4)}
                              </div>
                            );
                          }
                        }
                      }
                    }
                  }
                }
                return <></>;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GeneroStatistics;
