export default {
    // 1.- General 
    "help": "Ayuda",
    "btn.search": "Buscar",
    "btn.copy": "Copiar",
    "btn.export": "Exportar",
    "btn.import": "Importar",
    "label.input.name": "Nombre",
    "label.input.email": "Email",
    "label.input.password": "Contraseña",
    "label.input.lang": "Idioma",
    "label.filters": "Filtros",
    "label.filtersDescription": "Filtra por las siguientes categorías de interés:",
    "label.sizeAudience": "Tamaño de la audiencia",
    "label.min": "Mínimo",
    "label.max": "Máximo",
    "label.categories": "Categorías",
    "label.apply": "Aplicar",
    "label.cancel": "Cancelar",
    "label.nichos": "Nichos",
    "label.arcade": "Arcade",
    "label.add": "Agregar",
    "label.en": "Inglés",
    "label.es": "Español",
    "label.active": "Activo",
    "label.disable": "Desactivado",
    "placeholder.tableFilter": "Busca un interés de la tabla",
    "title.search.interest": "Obteniendo intereses...",

    // Header
    "header.ai": "Inteligencia Artificial [BETA]",
    "header.aiUses": "de",
    "header.usesText": "usos",
    "header.unlimited": "de ilimitado",

    // pages 
    "pages.interest": "Intereses",
    "pages.library": "Libreria de audiencias",
    "pages.reports": "Reportes",
    "pages.extension": "Extensión para Chrome",
    "pages.support": "Soporte",
    "pages.workspace": "Equipo de trabajo",
    "pages.account": "Mi Cuenta",
    "pages.teamadmin": "Administrar equipo",
    "pages.logout": "Cerrar sesión",

    // interest Page
    "pages.interest.search": "Buscar intereses",
    "pages.interest.copy": "Copiar intereses",
    "pages.interest.related": "Intereses relacionados",
    "pages.interest.emptyDescription": "Introduce una palabra clave para buscar intereses",
    "pages.interest.placeholder": "Buscar Interés",
    "pages.interest.import": "Importar intereses",
    "pages.interest.library": "Librería",
    "pages.interest.searchResult": "Búsqueda de intereses",
    "pages.interest.filter": "Filtrar intereses",
    "pages.interest.realtedTo": "Relacionados con:",
    "pages.interest.suggestion": "Sugerencias de intereses",
    "pages.interest.searchRelatedTo": "Busca intereses relacionados con:",
    "pages.interest.listSelected": "Lista de intereses seleccionados",
    "pages.interest.select": "Selecciona intereses",
    "pages.interest.selectDescription": "Selecciona dos o más intereses para ver sugerencias relacionadas",
    "pages.interest.selectEmptyDescription": "Selecciona uno o varios intereses para ver sugerencias relacionadas",
    "pages.interest.noResultsTitle": "No se encontraron intereses",
    "pages.interest.noResultsDescription": "Intenta ingresando otra palabra clave relacionada",
    "pages.interest.searching": "... Obteniendo intereses",
    "pages.interest.searchingSuggestions": "Obteniendo sugerencias...",


    // My Account page
    "pages.account.personalInformation": "Información personal",
    "pages.account.plans": "Planes",
    "pages.account.fb": "Cuenta de Facebook",
    "pages.account.changepass": "Cambiar Contraseña",
    "pages.account.unLinkfb": "Desvincular Facebook",
    "pages.account.newPass": "Nueva contraseña",
    "pages.account.updatedAt": "Actualizada el",
    "pages.account.messagenofacebook": "La persona que te invitó no tiene asociada una cuenta de facebook. Pídele que asocie una para poder acceder a todas las herramientas de Hello targeting.",

    // TeamWorkPage
    "pages.teamwork.subtitle": "Invita a tu equipo",
    "pages.teamwork.description": "Invita hasta 5 personas para que sean parte de tu equipo de trabajo.",
    "pages.teamwork.notice": "Si necesitas agregar más miembros a tu equipo, contacta con soporte para solicitar un",
    "pages.teamwork.noticespan": "plan a medida",
    "pages.teamwork.btnsend": "Enviar Invitaciones",
    "pages.teamwork.title": "Mi equipo",
    "pages.teamwork.inviteMember": "Invitar miembro",
    "pages.teamwork.headeremail": "Correo Electrónico",
    "pages.teamwork.headername": "Nombre",
    "pages.teamwork.headerstatus": "Estatus",
    "pages.teamwork.headerlastconnection": "Última conexión",
    "pages.teamwork.submenudisable": "Desactivar",
    "pages.teamwork.submenudelete": "Eliminar",
    "pages.teamwork.submenuedit": "Editar",


    // Reports Page
    "pages.reports.create": "Crear Reporte",
    "pages.reports.generate": "Generar Reporte",
    "pages.reports.myreports": "Mis Reportes",
    "pages.reports.allclients": "Todos los clientes",
    "pages.reports.initialCreate": "Inicia generando un reporte a partir de tus campañas publicitarias.",
    "pages.reports.campaign": "Campañas",
    "pages.reports.client": "Cliente",
    "pages.reports.date": "Fecha",
    "pages.reports.actions": "Acciones",

    // Single audience
    "pages.audience.titleSizeGraphic": "Tamaño promedio de los intereses",
    "pages.audience.less100thousand": "Menos de 100 mil",
    "pages.audience.between100m500m": "De 100 mil a 500 mil",
    "pages.audience.between500m1mil": "De 500 mil a 1 millón",
    "pages.audience.between1mill2mill": "De 1 millón a 2,5 millones",
    "pages.audience.more2mill": "Más de 2,5 millones",
    "pages.audience.relevantInterests": "Intereses más relevantes",
    "pages.audience.interestNum": "Número de intereses",


    // Library
    "pages.audience.placeholderFind": "Busca un interés de la tabla",
    "pages.libray.placeholderFind": "Buscar audiencia",

    // Modals
    "modal.facebookaccount.title": "Sincroniza tu cuenta de Facebook",
    "modal.facebookaccount.description": "Para acceder a todas las funcionalidades de HelloTargeting debes sincronizar tu cuenta de Facebook.",
    "modal.facebookaccount.syncedText": "Cuenta sincronizada exitosamente",
    "modal.interest.deletetitle": "¿Seguro de eliminar todos tus intereses?",
    "modal.interest.deletemessage": "Esta acción no puede ser revertida.",
    "modal.interest.deletecancelbtn": "Cancelar",
    "modal.interest.deletebtn": "Eliminar intereses",
    "modal.account.deleteuser.title": "¿Deseas eliminar el usuario?",
    "modal.account.deleteuser.btn": "Eliminar usuario",
    "modal.account.titleGuest": "No hay una cuenta de Facebbok asociada",


    // Login Page
    "pages.login.sigin": "Inicia sesión",
    "pages.login.qforgotpass": "¿Olvidaste tu contraseña?",
    "pages.login.messageError": "Error al iniciar sesión",

    // Forgot password
    "pages.forgot.recover": "Recuperar contraseña",
    "pages.forgot.btnsend": "Enviar Link",
    "pages.forgot.returnlogin": "Volver al inicio de sesión",

    // Alertas
    "alerts.copied": "Copiado en portapapeles",
    "alerts.errorcopied": "No tienes hashtags seleccionado. Busca uno y agregalo!",
    "alerts.updateUserSuccess": "Tus datos se actualizaron con éxito",
    "alerts.updateUserError": "Hubo un error al actualizar tus datos. Intentalo de nuevo.",
    "alerts.recoveremptytitle": "Existen campos vacíos",
    "alerts.recoveremptymessage": "Escríbe tu correo para recuperar tu contraseña",
    "alerts.inputfinderrortitle": "Error al buscar el interés",
    "alerts.inputfinderrormessage": "Se produjo un error. Vuelve a intentarlo.",
    "alerts.inputfindemptyerrortitle": "Error al buscar el interes",
    "alerts.inputfindemptyerrormessage": "Escribe al menos una palabra",
    "alerts.sendemailerrortitle": "Error al invitar miembros",
    "alerts.sendemailerrormessage": "Escribe al menos un correo",
    "alerts.sendemailsuccessmessage": "Se agregó los usuarios a tu lista de invitados",
    "alerts.addinterestoktitle": "Se agregaron los intereses a tu lista",
    "alerts.addinterestokmessage": "Interéses Importados correctamente",

    // PLANS
    "pages.account.plans.memberspro": "5 miembros",
    "pages.account.plans.membersproteam": "de tu equipo",
    // "pages.account.plans.memberspronumsearchs": "300 búsquedas avanzadas por mes, potenciadas con inteligencia artificial",
    "pages.account.plans.memberspronumsearchs": "Búsquedas avanzadas potenciadas con inteligencia artificial",
    "pages.account.plans.membersproilimit": "Búsquedas ilimitadas de Intereses de Facebook",
    "pages.account.plans.membersproenginesearch": "Motor de búsqueda avanzada de intereses relacionados",
    "pages.account.plans.membersprolibrary": "Librería con más de 100 audiencias listas para usar",
    "pages.account.plans.membersproextension": "Extensión Hello Targeting para Google Chrome",
    "pages.account.plans.memberspromyplan": "Tu tienes plan",
    "pages.account.plans.membersliteAudiences": "Librerías listas para usar",
    "pages.account.plans.validate": "Plan Válido",
    "pages.account.plans.validateTo": "hasta",
    "pages.account.plans.useaitext": "+ uso de AI",
    "pages.account.plans.validateaccount": "Tu cuenta es válida desde",
    "pages.account.plans.upgradeplan": "Actualiza tu plan",

    // REPORT MODAL
    "modal.reports.title": "Genera tu reporte",
    "modal.reports.page1.description": "Filtra por las siguientes categorías de intereses:",
    "modal.reports.page1.step1": "Paso 1 de 3",
    "modal.reports.page1.account": "Cuenta",
    "modal.reports.page1.selectaccount": "Selecciona una cuenta",
    "modal.reports.page1.clientplaceholder": "Nombre de tu cliente",
    "modal.reports.page1.reportname": "Nombre del reporte",
    "modal.reports.page1.reportplaceholder": "Nombre de tu reporte",
    "modal.reports.page1.next": "Siguiente",
    "modal.reports.page.emptymessage": "Necesitas llenar todos los campos para continuar",
    "modal.reports.page.emptytitle": "Campos vacíos",
    "modal.reports.page2.step": "Paso 2 de 3",
    "modal.reports.page2.selectcampaign": "Selecciona una campaña",
    "modal.reports.page2.dates": "Fechas",
    "modal.reports.page2.startdate": "Fecha de inicio",
    "modal.reports.page2.endingdate": "Fecha de Fin",
    "modal.reports.page2.previous": "Anterior",
    "modal.reports.page3.step": "Paso 3 de 3",
    "modal.reports.page3.reportType": "Tipo de reporte",
    "modal.reports.page3.infoSelect": "Selecciona de acuerdo a tu campaña para obtener métricas adaptadas",
    "modal.reports.page3.plfcatchment": "PLF Captación",
    "modal.reports.page3.downloadLead": "Descarga Lead Magnet",
    "modal.reports.page3.directselling": "Venta directa - Tripwire",
    "modal.reports.page3.viewmetrics": "Ver métricas",
    "modal.reports.page3.metrics.description": "Estas son las métricas que se incluyen en este reporte:",

    // Specifications reports
    "modal.reports.specifications.title": "Reporte",
    "modal.reports.specifications.ofthe": "Del",
    "modal.reports.specifications.tothe": "al",
    "modal.reports.specifications.adDelivery": "Entrega de anuncios",
    "modal.reports.specifications.download": "Descargar",
    "modal.reports.specifications.totalBudget": "Presupuesto total",
    "modal.reports.specifications.amountSpent": "Importe gastado",
    "modal.reports.specifications.aggregate": "Agregado de todas las campañas",
    "modal.reports.specifications.mainResults": "Resultados principales",
    "modal.reports.specifications.frecuency": "Frecuencia",
    "modal.reports.specifications.scope": "Alcance",
    "modal.reports.specifications.cost": "Coste",
    "modal.reports.specifications.engagement": "Engagement e Interacciones",
    "modal.reports.specifications.distribution": "Distribución por sexo y edades",
    "modal.reports.specifications.gender": "Género",
    "modal.reports.specifications.male": "Hombre",
    "modal.reports.specifications.female": "Mujer",
    "modal.reports.specifications.unknown": "Desconocido",
    "modal.reports.specifications.ages": "Edades",
    "modal.reports.specifications.distributionCountries": "Distribución por países",
    "modal.reports.specifications.country": "País",
    "modal.reports.specifications.notificationDate": "La fecha seleccionada no puede ser menor a la fecha de creación de la campaña",
    "modal.reports.specifications.notificationnowDate": "La fecha seleccionada no puede ser mayor a la fecha actual",


    // Modal download
    "modal.reports.download.title": "Descargar reporte",
    "modal.reports.download.introductorymessage": "Incluye un mensaje introductorio personalizado para tu cliente",
    "modal.reports.download.messageplaceholder": "Mensaje",
    "modal.reports.download.labelcustomer": "Logotipo del cliente (opcional)",
    "modal.reports.download.labeltamanio": "El tamaño recomendado es de 80 x 100 pixeles",
    "modal.reports.download.selectfile": "Seleccionar un archivo",
    "modal.reports.download.dropfile": "Suelta el archivo",
    "modal.reports.download.btndownload": "Descargar reporte",
    "modal.reports.download.reportlanguage": "Idioma del lenguaje",

    // Modal report delete
    "modal.reports.modaldelete.title": "¿Eliminar reporte?",
    "modal.reports.modaldelete.deletebtn": "Eliminar reporte",

}
