import { PropsGenerarReportePage } from "../../../interfaces/interface";
import grafico from "../../../assets/icons/pasos_1.svg";
import iconArrow from "../../../assets/icons/ri_arrow-bottom-s-line.svg";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner/Spinner";
import { IAdAccounts } from "../../../interfaces/report.interface";
import { useState } from "react";
import { obtenerCampaign } from "../../../redux/actions/reportesAction";
import { NotificationManager } from "../../../components/common/react-notifications";
import AppLocale from "../../../lang";
import IntlMessages from "../../../helpers/IntlMessages";

const GenerarPage1: React.FC<PropsGenerarReportePage> = ({
  statePage,
  setValues,
  values,
}) => {
  const dispatch = useDispatch();
  const [cuenta, setCuenta] = useState<any>(null);
  const [cuentaOptions, setCuentaOptions] = useState(false);
  const { fetchAdAccounts, adAccounts } = useSelector(
    (state: any) => state.reportes
  );
  const { lang } = useSelector((state: any) => state.cuenta);
  const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];

  const selectCuenta = (account: IAdAccounts) => {
    setCuenta(account);
    // FIND ACCOUNT CAMPAING
    dispatch(obtenerCampaign(account.id));
  };

  const navigateToNextPage = () => {
    if (cuenta && values.clientName !== "" && values.reportName !== "") {
      statePage(2);
    } else {
      NotificationManager.warning(
        currentAppLocale.messages["modal.reports.page.emptymessage"],
        currentAppLocale.messages["modal.reports.page.emptytitle"],
        3000,
        null,
        "",
        ""
      );
    }
  };

  return (
    <div className="ht__generarPage1">
      {fetchAdAccounts ? (
        <div className="ht__generarPage1-load">
          {/* <label htmlFor="">Hola</label> */}
          <Spinner cssClass="ht__generarPage1-spinner" />
        </div>
      ) : (
        <>
          <p>
            <IntlMessages id="modal.reports.page1.step1" />
          </p>
          <div className="ht__generarPage1-grafico">
            <img src={grafico} alt="Grafico 1" />
          </div>
          <label htmlFor="cuenta">
            <IntlMessages id="modal.reports.page1.account" />
          </label>

          <div
            className="ht__generarPage1-cuenta"
            onClickCapture={() => setCuentaOptions(!cuentaOptions)}
          >
            <div className="ht__generarPage1-cuenta-value">
              {cuenta ? (
                cuenta?.name
              ) : (
                <IntlMessages id="modal.reports.page1.selectaccount" />
              )}
              <img src={iconArrow} alt="icon" />
            </div>
            <div
              className={`${
                cuentaOptions ? "ht__generarPage1-cuenta-show-options" : ""
              } ht__generarPage1-cuenta-options`}
            >
              {adAccounts?.map((account: IAdAccounts) => (
                <p
                  onClickCapture={() => selectCuenta(account)}
                  key={account.id}
                >
                  {account.name}
                </p>
              ))}
            </div>
          </div>
          <label htmlFor="cliente">
            <IntlMessages id="pages.reports.client" />
          </label>
          <input
            type="text"
            name="cliente"
            id="cliente"
            placeholder={
              currentAppLocale.messages["modal.reports.page1.clientplaceholder"]
            }
            value={values.clientName}
            onChange={(e) => {
              setValues({
                ...values,
                clientName: e.target.value,
              });
            }}
          />
          <label htmlFor="cliente">
            <IntlMessages id="modal.reports.page1.reportname" />
          </label>
          <input
            type="text"
            name="reportName"
            id="reportName"
            placeholder={
              currentAppLocale.messages["modal.reports.page1.reportplaceholder"]
            }
            onChange={(e) => {
              setValues({
                ...values,
                reportName: e.target.value,
              });
            }}
          />
          <div className="ht__generarPage1-containerBtn">
            <div className="btn" onClickCapture={navigateToNextPage}>
              <IntlMessages id="modal.reports.page1.next" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GenerarPage1;
