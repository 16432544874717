import moreIcon from "../../assets/icons/ri_more-2-fill.svg";
import iconDesactivar from "../../assets/icons/ri_forbid-2-line.svg";
import iconDelete from "../../assets/icons/ri_delete-bin-4-line.svg";
// import iconEdit from "../../assets/icons/ri_edit-line.svg";
import { useEffect, useState } from "react";
import { IUser } from "../../interfaces/interface";
import { getOneChartOfString } from "../../helpers";
import { useSelector } from "react-redux";
// import { deleteGuestUser } from "../../redux/actions/cuentaAction";
import ModalComponent from "../../components/modalComponents/modalComponent";
import ModalComponentDelete from "./modal/ModalComponentDelete";
import IntlMessages from "../../helpers/IntlMessages";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work

interface IPropsRow {
  user: IUser;
  me?: boolean;
}

function RowUnicUser({ user, me }: IPropsRow) {
  const { id } = useSelector((state: any) => state.cuenta.user);
  const [actions, setActions] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [lastConnection, setLastConnection] = useState("");
  const lang = useSelector((state: any) => state.cuenta.lang);
  moment.locale(lang === "en-US" ? "en" : "es");

  const calcLastConnection = () => {
    if (user?.logins[0]) {
      const momentJsDate = moment(user.logins[0]).fromNow();
      setLastConnection(momentJsDate);
    }
  };

  useEffect(() => {
    calcLastConnection();
  }, []);

  return (
    <>
      <div className="ht__equipoTrabajo-table-row">
        <div className="ht__equipoTrabajo-table-row-name">
          <div className="avatar">{getOneChartOfString(user.name)}</div>
          <p>{user.name}</p>
        </div>
        <div className="ht__equipoTrabajo-table-row-email">{user.email}</div>
        <div className="ht__equipoTrabajo-table-row-conection">
          {lastConnection}
        </div>
        <div className="ht__equipoTrabajo-table-row-status">
          {user.active ? (
            <div>
              {" "}
              <IntlMessages id="label.active" />{" "}
            </div>
          ) : (
            <div>
              {" "}
              <IntlMessages id="label.disable" />{" "}
            </div>
          )}
        </div>
        {id === user.id ? (
          <div className="ht__equipoTrabajo-table-row-action"></div>
        ) : (
          <div className="ht__equipoTrabajo-table-row-action">
            <img
              src={moreIcon}
              alt="Acciones"
              onClickCapture={() => setActions(!actions)}
            />
            <div
              className={`${
                actions ? "ht__equipoTrabajo-table-row-options-show" : ""
              }  ht__equipoTrabajo-table-row-options`}
            >
              <p>{user.name}</p>
              <div>
                <img src={iconDesactivar} alt="Desactivar" />
                <IntlMessages id="pages.teamwork.submenudisable" />
              </div>
              {!me && (
                <>
                  <div
                    onClickCapture={() => {
                      setActions(false);
                      setShowDialog(true);
                    }}
                  >
                    <img src={iconDelete} alt="Eliminar" />
                    <IntlMessages id="pages.teamwork.submenudelete" />
                  </div>
                  {/* <div>
                    <img src={iconEdit} alt="Eliminar" />
                    <IntlMessages id="pages.teamwork.submenuedit" />
                  </div> */}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {showDialog ? (
        <ModalComponent
          stateShowDialog={setShowDialog}
          component={ModalComponentDelete}
          idUser={user.id}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default RowUnicUser;
