import { useState } from "react";
import arrowIcon from "../../assets/icons/ri_arrow-bottom-s-line.svg";
import { PropsFiltroOption } from "../../interfaces/interface";

const FiltroOption: React.FC<PropsFiltroOption> = ({
  name,
  submenu,
  changeFiltroOptions,
  changeFiltroSubOptions,
  filtros,
}) => {
  const [open, setOpen] = useState(false);

  const verifyAll = () => {
    var contain = false;
    submenu?.forEach((item: string) => {
      const compare = `${name}>${item}`;
      if (filtros?.category?.includes(compare)) {
        contain = true;
      } else {
        contain = false;
      }
    });
    return contain;
  };

  return (
    <>
      <div className="ht__filtroComponent-containerCategorias-option">
        <div>
          <input
            type="checkbox"
            name={name}
            checked={verifyAll()}
            // onChangeCapture={changeFiltroOptions}
            onChange={(e) => changeFiltroOptions(e, submenu)}
          />
          <p>{name}</p>
        </div>
        <img src={arrowIcon} alt="Más" onClickCapture={() => setOpen(!open)} />
      </div>
      {open ? (
        <div className="ht__filtroComponent-containerCategorias-submenu">
          {submenu.map((value: string, i) => (
            <div key={i}>
              <input
                type="checkbox"
                name={value}
                checked={filtros?.category?.includes(`${name}>${value}`)}
                onChange={(e) => changeFiltroSubOptions(e, name)}
              />
              <p>{value}</p>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default FiltroOption;
