import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModelLogin } from '../interfaces/interface';
import AppLocale from "../lang";
import { login } from "../redux/actions/cuentaAction";

export const useLogin = (initialData: any) => {
    const dispatch = useDispatch();
    const cuenta = useSelector((state: any) => state.cuenta);
    const currentAppLocale = AppLocale[cuenta.lang === "es-ES" ? "es" : "en"];
    const [values, setValues] = useState<ModelLogin>(initialData);

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = target;
        setValues({
            ...values,
            [name]: value
        })
    }
    const onClickSend = () => {

        dispatch(login(values.email, values.password, currentAppLocale.messages["pages.login.messageError"]))
    }

    return { values, handleChange, onClickSend };
}