import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IntlMessages from "../../helpers/IntlMessages";
import {
  IAgeStatistics,
  IReportStatsGlobal,
} from "../../interfaces/report.interface";
// import AppLocale from "../../lang";

const AgeStatistics = ({ ages, currency, metrics }: IAgeStatistics) => {
  const [maxReach, setMaxReach] = useState(0);
  const [maxClicks, setMaxClicks] = useState(0);
  const global = useSelector(
    (state: any) => state.reportes.currentReport.stats.global
  ) as IReportStatsGlobal;
  // const lang = useSelector((state: any) => state.cuenta.lang);
  // const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];

  useEffect(() => {
    let max1 = 0;
    let max2 = 0;
    ages.forEach((ages) => {
      const reach = parseInt(ages.reach);
      const clicks = parseInt(ages.clicks);
      if (reach > max1) {
        max1 = reach;
      }
      if (clicks > max2) {
        max2 = clicks;
      }
    });
    setMaxReach(max1);
    setMaxClicks(max2);
  }, []);

  const checkColor = (index: number) => {
    let color = "";
    switch (index) {
      case 0:
        color = "orange";
        break;
      case 1:
        color = "redLight";
        break;
      case 2:
        color = "red";
        break;
      case 3:
        color = "blue";
        break;
      case 4:
        color = "yellow";
        break;
      case 5:
        color = "blueLight";
        break;
      default:
        color = "yellowLight";
        break;
    }
    return color;
  };
  return (
    <div className="ht__ageStatistics">
      <div className="ht__ageStatistics-row ageStatics-header">
        <div>
          <IntlMessages id="modal.reports.specifications.ages" />
        </div>
        {Object.keys(metrics).map((e) => {
          if (metrics[e]) {
            if (e === "reach") {
              return (
                <div className="ht__generoStatistics-reach">
                  <IntlMessages id="modal.reports.specifications.scope" />
                </div>
              );
            } else {
              if (e === "clicks") {
                return (
                  <div className="ht__generoStatistics-clicks">Clicks</div>
                );
              } else {
                if (e === "cpc") {
                  return (
                    <div className="ht__generoStatistics-clicks-cpc">CPC</div>
                  );
                } else {
                  if (e === "ctr") {
                    return <div className="ht__generoStatistics-ctr">CTR</div>;
                  } else {
                    if (e === "spend") {
                      return (
                        <div className="ht__generoStatistics-clicks-cpc">
                          <IntlMessages id="modal.reports.specifications.cost" />
                        </div>
                      );
                    }
                  }
                }
              }
            }
          }
          return <></>;
        })}
      </div>
      {ages.map((age, index) => {
        const color = checkColor(index);
        return (
          <div className="ht__ageStatistics-row">
            <div className={`ht__ageStatistics-rowdiv-${color}`}>
              {" "}
              <div></div> {age.age}
            </div>
            <div className="ht__generoStatistics-row-data">
              {Object.keys(metrics).map((e) => {
                if (metrics[e]) {
                  if (e === "reach") {
                    return (
                      <div className="ht__generoStatistics-reach">
                        <div
                          style={{
                            width: `${(parseInt(age.reach) * 70) / maxReach}%`,
                          }}
                          className={`ht__ageStatistics-row-${color} ht__ageminwidth`}
                        >
                          {age.reach}
                        </div>
                        <p className="ht__ageStatistics-percentage">
                          {(
                            (parseInt(age.reach) * 100) /
                            parseFloat(global.reach!)
                          ).toFixed(2)}
                          %
                        </p>
                      </div>
                    );
                  } else {
                    if (e === "clicks") {
                      return (
                        <div className="ht__generoStatistics-clicks">
                          <div
                            style={{
                              width: `${
                                (parseInt(age.clicks) * 70) / maxClicks
                              }%`,
                            }}
                            className={`ht__ageStatistics-row-${color} ht__ageminwidth`}
                          >
                            {age.clicks}
                          </div>
                          <p className="ht__ageStatistics-percentage">
                            {(
                              (parseInt(age.clicks) * 100) /
                              parseFloat(global.clicks!)
                            ).toFixed(2)}
                            %
                          </p>
                        </div>
                      );
                    } else {
                      if (e === "cpc") {
                        return (
                          <div className="ht__generoStatistics-clicks-cpc">
                            <span className="ht__ageStatistics-usd">
                              {currency}
                            </span>{" "}
                            {age.cpc ? parseFloat(age.cpc).toFixed(4) : "0.00"}
                          </div>
                        );
                      } else {
                        if (e === "ctr") {
                          return (
                            <div className="ht__generoStatistics-ctr">
                              <div
                                className={`ht__ageStatistics-color-${color}`}
                              >
                                {parseFloat(age.ctr).toFixed(3)}%
                              </div>
                            </div>
                          );
                        } else {
                          if (e === "spend") {
                            return (
                              <div className="ht__generoStatistics-clicks-cpc">
                                <span className="ht__ageStatistics-usd">
                                  {currency}
                                </span>{" "}
                                {age.spend
                                  ? parseFloat(age.spend).toFixed(4)
                                  : "0.00"}
                              </div>
                            );
                          }
                        }
                      }
                    }
                  }
                }
                return <></>;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AgeStatistics;
