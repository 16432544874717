import { EventEmitter } from 'events';
import { createUUID } from '../../../helpers';

const Constants = {
    CHANGE: 'change',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
};


class NotificationManager extends EventEmitter {

    listNotify: Array<any>;

    constructor() {
        super();
        this.listNotify = [];
    }

    create(notify: any) {
        const defaultNotify = {
            id: createUUID(),
            type: 'info',
            title: null,
            message: null,
            timeOut: 5000,
            customClassName: ""
        }

        if (notify.priority) {
            this.listNotify.unshift(Object.assign(defaultNotify, notify));
        } else {
            this.listNotify.push(Object.assign(defaultNotify, notify));
        }

        this.emitChange();
    }

    primary(message: string, title: string, timeOut: number, onClick: any, priority : any, customClassName: string) {
        this.create({
            type: Constants.PRIMARY,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName
        });
    }

    secondary(message: string, title: string, timeOut: number, onClick: any, priority : any, customClassName: string) {
        this.create({
            type: Constants.SECONDARY,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName
        });
    }

    info(message: string, title: string, timeOut: number, onClick: any, priority : any, customClassName: string) {
        this.create({
            type: Constants.INFO,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName
        });
    }

    success(message: string, title: string, timeOut: number, onClick: any, priority : any, customClassName: string) {
        this.create({
            type: Constants.SUCCESS,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName
        });
    }

    warning(message: string, title: string, timeOut: number, onClick: any, priority : any, customClassName: string) {
        this.create({
            type: Constants.WARNING,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName
        });
    }

    error(message: string, title: string, timeOut: number, onClick: any, priority : any, customClassName: string) {
        this.create({
            type: Constants.ERROR,
            message,
            title,
            timeOut,
            onClick,
            priority,
            customClassName
        });
    }

    remove(notification : any) {
        this.listNotify = this.listNotify.filter(n => notification.id !== n.id);
        this.emitChange();
    }

    emitChange() {
        this.emit(Constants.CHANGE, this.listNotify);
    }

    addChangeListener(callback: any) {
        this.addListener(Constants.CHANGE, callback);
    }

    removeChangeListener(callback: any) {
        this.removeListener(Constants.CHANGE, callback);
    }



}


export default new NotificationManager();