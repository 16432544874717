import {
    CHANGE_ITEM_TABBAR_INTERESES,
    SEARCH_INTEREST,
    SEARCH_INTEREST_ERROR,
    SEARCH_INTEREST_SUCCESS,
    ADD_INTEREST_TO_LIST,
    ADD_INTEREST_TO_SUGGESTION_LIST,
    GET_SUGGESTIONS,
    GET_SUGGESTIONS_ERROR,
    GET_SUGGESTIONS_SUCCESS,
    DELETE_INTEREST_TO_LIST,
    DELETE_ALL_INTEREST_TO_LIST,
    IMPLEMENT_INTEREST_FILTER,
    ADD_INTEREST_IMPORTED_TO_LIST,
    CHANGE_SEARCH_LANGUAGE,
    LOGOUT_SUCCESS,
    CHANGE_USINGAI,
    DELETE_SUGGESTION_TO_LIST,
    FILTER_RESET,
    UPDATE_KEYWORD
} from "../../types/types";

import {
    ActionModel, InterestFindInterface
} from "../../interfaces/interface";

const initialData = {
    itemTabbar: 0,
    listIntereses: localStorage.getItem("ht__myinterests") ? JSON.parse(localStorage.getItem("ht__myinterests")!) : [],
    listFindIntereses: localStorage.getItem("ht-listFindIntereses") ? JSON.parse(localStorage.getItem("ht-listFindIntereses")!) : null,
    keyword: localStorage.getItem("ht-keyword") ?? null,
    lang: null,
    listSuggestionsActive: localStorage.getItem("ht__myinterests") ? [JSON.parse(localStorage.getItem("ht__myinterests")!)[0]] : [],
    fetchFindInterests: false,
    fetchSuggestions: false,
    errorConsultSuggestion: null,
    listInterestSuggestion: null,
    categories: localStorage.getItem("ht-categories") ? JSON.parse(localStorage.getItem("ht-categories")!) : [],
    filterFindInteresCategory: [],
    filtersInterest: {
        category: [],
        size: {
            minimo: 0,
            maximo: 0
        }
    },
    searchLang: localStorage.getItem('searchLang') ? localStorage.getItem('searchLang') : navigator.language,
    usingAI: localStorage.getItem('ht__useAI') ? localStorage.getItem('ht__useAI') === "true" ? true : false : true,
    listFindAI: localStorage.getItem("ht-listFindAI") ? JSON.parse(localStorage.getItem("ht-listFindAI")!) : null,
}

export const interesesReducer = (state = initialData, action: ActionModel) => {
    switch (action.type) {
        case CHANGE_ITEM_TABBAR_INTERESES:
            return {
                ...state,
                itemTabbar: action.payload
            }
        case SEARCH_INTEREST:
            return {
                ...state,
                fetchFindInterests: true
            }
        case SEARCH_INTEREST_ERROR:
            return {
                ...state,
                fetchFindInterests: false,
                listFindIntereses: null,
                lang: null,
                categories: []
            }
        case SEARCH_INTEREST_SUCCESS:
            return {
                ...state,
                fetchFindInterests: false,
                listFindIntereses: action.payload.info,
                // keyword: action.payload.keyword,
                lang: action.payload.lang,
                categories: action.payload.categories,
                listFindAI: action.payload.listai
            }
        case UPDATE_KEYWORD:
            return {
                ...state,
                keyword: action.payload
            }
        case ADD_INTEREST_TO_LIST:
            return {
                ...state,
                listIntereses: [
                    ...state.listIntereses!,
                    action.payload
                ],
                listSuggestionsActive: state.listSuggestionsActive.length === 0 && state.listIntereses.length === 0 ? [action.payload] : state.listSuggestionsActive

            }
        case ADD_INTEREST_TO_SUGGESTION_LIST:
            return {
                ...state,
                listSuggestionsActive: action.payload
            }
        case ADD_INTEREST_IMPORTED_TO_LIST:
            return {
                ...state,
                listIntereses: [...state.listIntereses, ...action.payload]
            }
        case GET_SUGGESTIONS:
            return {
                ...state,
                fetchSuggestions: true,
                errorConsultSuggestion: null,
            }
        case GET_SUGGESTIONS_ERROR:
            return {
                ...state,
                fetchSuggestions: false,
                errorConsultSuggestion: true
            }
        case GET_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                fetchSuggestions: false,
                listInterestSuggestion: action.payload,
                errorConsultSuggestion: null
            }
        case DELETE_INTEREST_TO_LIST:
            return {
                ...state,
                listIntereses: state.listIntereses.filter((interest: InterestFindInterface) => interest.id !== action.payload),
                // listSuggestionsActive: state.listSuggestionsActive.filter((interest: InterestFindInterface) => interest.id !== action.payload),
            }
        case DELETE_SUGGESTION_TO_LIST:
            return {
                ...state,
                listSuggestionsActive: state.listSuggestionsActive.filter((interest: InterestFindInterface) => interest.id !== action.payload),
            }
        case DELETE_ALL_INTEREST_TO_LIST:
            return {
                ...state,
                listIntereses: [],
                listSuggestionsActive: [],
                listInterestSuggestion: null

            }
        case IMPLEMENT_INTEREST_FILTER:
            return {
                ...state,
                filterFindInteresCategory: action.payload.listInterests,
                filtersInterest: action.payload.filter
            }
        case CHANGE_SEARCH_LANGUAGE:
            return {
                ...state,
                searchLang: action.payload
            }
        case LOGOUT_SUCCESS:
            return initialData;
        case CHANGE_USINGAI:
            return {
                ...state,
                usingAI: action.payload
            }
        case FILTER_RESET:
            return {
                ...state,
                filterFindInteresCategory: initialData.filterFindInteresCategory,
                filtersInterest: initialData.filtersInterest
            }
        default:
            return state
    }
}

