// import { Dispatch } from "react";
import axios from "axios";
import { NotificationManager } from "../components/common/react-notifications";
import { facebookURL } from "../constants/constants";
import AppLocale from "../lang";
// import { addInterestImported } from "../redux/actions/interesesAction";

export const createUUID = () => {
    const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return pattern.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
};


export const getOneChartOfString = (text: string) => {
    return text.charAt(0);
}

export const validateEmail = (text: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase());
}

export const getLanguagebyLang = (l: string) => {
    switch (l) {
        case 'en-US':
            return 'English'
        case 'es-ES':
            return 'Español'
    }
}

export const addNotification = (lang: string) => {
    const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];
    const title = currentAppLocale.messages["alerts.addinterestoktitle"];
    const body = currentAppLocale.messages["alerts.addinterestokmessage"];
    return NotificationManager.success(
        body,
        title,
        1500,
        null,
        null,
        ""
    );
};


export const csvFileDestructuring = async (csv: any) => {
    const lines = (await csv.text()).split("\n");
    const headers = lines[0].split(";");

    if (lines[lines.length - 1] === undefined) {
        lines.splice(-1, 1);
    }
    const arr = lines.map(function (row: any, index: number) {
        const values = row.split(";");
        const el = headers?.reduce(function (
            object: any,
            header: any,
            index: any
        ) {
            header = header?.replace(/['"]+/g, "");
            object[header] = values[index]?.replace(/['\"]+/g, "");
            return object;
        },
            {});
        return el;
    });
    return arr.filter((a: any, i: number) => i !== 0);

};

export const abreviarNumeros = (value: any) => {
    var bN = 0;
    var sf = ["", "K", "M", "B", "T", "c", "q", "s"];
    while (value > 999) {
        value /= 1000;
        bN++;
    }
    let number = Number.parseFloat(value).toPrecision(3);
    return number + sf[bN];
}

export const verificarFacebookToken = async (token: string) => {
    // HACER UNA CONSULTA COMPROBAR TOKEN
    try {
        const respFb = await axios.get(`${facebookURL.baseURL}me/adaccounts`, {
            params: {
                access_token: token,
                // access_token: "EAAOrolUOY44BAKZAA03bG0iyGgyaaDwY9oIkoOkAupwmXUMbd6j5ZBGduXPYZA4RTM4krRHlyRbNUjWeesPqdDqhGnPTHkKBrWiOraV6x3ZBwt2keXhlLSzLBdL74pqr8RDTAMuDo8ZAM8ZBjpT5oZA160BS3MZBjw62VETZBZAOQqwQZDZD",
                fields: 'name,account_status'
            }
        })
        console.log("===> RESPUESTA");
        console.log(respFb.status);
        return true;
    } catch (error) {
        console.log("==Error");
        console.log(error.response);
        return false;
    }

}
