// import { useFormReset } from "../../hooks/useFormReset";
import iconMail from "../../assets/icons/ri_mail-line.svg";
import logo from "../../assets/img/logo_login.svg";
import iconPass from "../../assets/icons/ri_lock-password-line.svg";
import Spinner from "../../components/spinner/Spinner";
import { useSelector } from "react-redux";
import { IResetComponent } from "../../interfaces/interface";

const ResetPasswordComponent: React.FC<IResetComponent> = ({
  title,
  email,
  error,
  handleBlur,
  handleChange,
  onClickSend,
  password,
  repassword,
}) => {
  const { fetchRecover } = useSelector((state: any) => state.cuenta);
  //   const { values, handleChange, onClickSend, handleBlur } = useFormReset({
  //     email: "",
  //     password: "",
  //     repassword: "",
  //     error: "",
  //     // paramans: window.location.pathname.slice(17, -1),
  //   });

  // window.location.pathname.slice(0, 18);

  //   const { email, password, repassword, error } = values;
  return (
    <div className="ht__loginScreen">
      <div className="ht__loginScreen-container">
        <div>
          <div className="ht__loginScreen-container-logo">
            <img src={logo} alt="Hello targeting" />
          </div>
        </div>
        <div>
          <div className="ht__loginScreen-container-info">
            <h4>{title}</h4>
            <div>
              <label htmlFor="email">Correo</label>
              <div>
                <img src={iconMail} alt="Password" />
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Tu correo"
                  value={email}
                  onChange={handleChange}
                />
              </div>
              <label htmlFor="password">Contraseña</label>
              <div>
                <img src={iconPass} alt="Password" />
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Tu contraseña"
                  value={password}
                  onChange={handleChange}
                />
              </div>
              <label htmlFor="password">Repite la contraseña</label>
              <div>
                <img src={iconPass} alt="Password" />
                <input
                  type="password"
                  name="repassword"
                  id="repassword"
                  placeholder="Repite la contraseña"
                  value={repassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            {error ? (
              <div className="ht__login-error">
                <p>{error}</p>
              </div>
            ) : (
              <></>
            )}
            <div
              className="ht__loginScreen-btnLogin"
              onClickCapture={() => {
                if (!fetchRecover) {
                  onClickSend(window.location.pathname.slice(17, -1));
                }
              }}
            >
              {fetchRecover ? <Spinner cssClass="" /> : "Establecer contraseña"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordComponent;
