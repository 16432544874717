import IntlMessages from "../../../helpers/IntlMessages";
import deleteIcon from "../../../assets/icons/delete-icon.svg";
import closeIcon from "../../../assets/icons/ri_close-fill.svg";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { IPropsDeleteReport } from "../../../interfaces/report.interface";
import { useState } from "react";
import { deleteReportBD } from "../../../redux/actions/reportesAction";
import Spinner from "../../../components/spinner/Spinner";
// import { deleteGuestUser } from "../../../redux/actions/cuentaAction";

const ModalReportDelete: React.FC<IPropsDeleteReport> = ({
  stateClose,
  idReport,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [fetch, setFetch] = useState(false);

  const deleteReport = () => {
    if (!fetch) {
      dispatch(deleteReportBD(idReport, setFetch, stateClose, history));
    }
  };

  return (
    <div className="ht__modalDeleteInterest">
      <div className="header">
        <img src={deleteIcon} alt="Eliminar" />
        <img
          src={closeIcon}
          alt="Cerrar"
          onClickCapture={() => {
            if (!fetch) {
              stateClose(false);
            }
          }}
        />
      </div>
      <h4>
        <IntlMessages id="modal.reports.modaldelete.title" />
      </h4>
      <p>
        <IntlMessages id="modal.interest.deletemessage" />
      </p>
      <div className="container-btn">
        <div
          onClickCapture={() => {
            if (!fetch) {
              stateClose(false);
            }
          }}
        >
          <IntlMessages id="modal.interest.deletecancelbtn" />
        </div>
        <div onClickCapture={deleteReport}>
          {fetch ? (
            <Spinner />
          ) : (
            <IntlMessages id="modal.reports.modaldelete.deletebtn" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalReportDelete;
