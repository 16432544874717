import logo from "../../assets/img/logo.svg";
import arrowIcon from "../../assets/icons/ri_arrow-right-s-line.svg";
import closeIcon from "../../assets/icons/ri_close-fill.svg";
import menuIcon from "../../assets/icons/ri_menu-fill.svg";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IUser } from "../../interfaces/interface";
import { getOneChartOfString } from "../../helpers";
import IntlMessages from "../../helpers/IntlMessages";
import {
  changeItem,
  getAllTeamWork,
  startLogout,
} from "../../redux/actions/cuentaAction";

const Sidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [submenu, setSubmenu] = useState(false);
  const [menuWorkspace, setMenuWorkspace] = useState(false);
  // const [subMenuWorkspace, setSubMenuWorkspace] = useState(false);

  // const teamwork = useSelector<any, IUser[]>((state) => state.cuenta.teamwork);

  const user = useSelector<any, IUser>((state) => state.cuenta.user);
  const lang = useSelector((state: any) => state.cuenta.lang);

  // const changeuserWorkspace = (user: IUser) => {
  //   console.log("Cambiar a este usuario", user.name);
  // };

  const navigateTo = (page: number) => {
    switch (page) {
      case 1:
        if (window.location.pathname !== "/intereses") {
          history.push("/intereses");
        }
        break;
      case 2:
        if (window.location.pathname !== "/libreria-audiencias") {
          history.push("/libreria-audiencias");
        }
        break;
      case 3:
        if (window.location.pathname !== "/reportes") {
          history.push("/reportes");
        }
        break;

      default:
        break;
    }
  };

  const dispatchLogout = () => {
    dispatch(startLogout(lang));
  };

  const navigateToTeam = () => {
    history.push("/mi-cuenta");
    dispatch(changeItem(1));
  };

  useEffect(() => {
    dispatch(getAllTeamWork());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="ht__sidebar">
      <div className="ht__sidebar-containerHeader">
        <div className="ht__sidebar-logo">
          <img
            src={logo}
            alt="Hello Targeting"
            onClickCapture={() => {
              if (window.location.pathname !== "/") {
                history.push("/");
              }
            }}
          />
        </div>
        <div className="ht__sidebar-mobileMenu">
          <img
            src={menuIcon}
            alt="Menu"
            onClickCapture={() => setSubmenu(true)}
          />
        </div>
      </div>
      <div className={`${submenu ? "ht__sidebar-showMenu" : ""}`}>
        <div>
          <div className="container-icon-close">
            <img
              src={closeIcon}
              alt="Close"
              onClickCapture={() => setSubmenu(false)}
            />
          </div>
          <div className="ht__sidebar-items">
            <div
              className={`${
                window.location.pathname === "/intereses"
                  ? "ht__sidebar-itemActive"
                  : ""
              }`}
              onClickCapture={() => navigateTo(1)}
            >
              <p>
                <IntlMessages id="pages.interest" />
              </p>
            </div>
            <div
              className={`${
                window.location.pathname === "/libreria-audiencias"
                  ? "ht__sidebar-itemActive"
                  : ""
              }`}
              onClickCapture={() => navigateTo(2)}
            >
              <p>
                <IntlMessages id="pages.library" />
              </p>
            </div>
            {/* <div
              className={`${
                window.location.pathname === "/reportes"
                  ? "ht__sidebar-itemActive"
                  : ""
              }`}
              onClickCapture={() => navigateTo(3)}
            >
              <p>
                <IntlMessages id="pages.reports" />
              </p>
            </div> */}
          </div>
        </div>
        <div>
          <p
            onClickCapture={() => {
              window.open(
                "https://hellotargeting.com/chrome-extension/",
                "_blank"
              );
            }}
          >
            <IntlMessages id="pages.extension" />
          </p>
          <p
            onClickCapture={() => {
              window.open("https://hellotargeting.com/soporte/", "_blank");
            }}
          >
            <IntlMessages id="pages.support" />
          </p>

          <div onClickCapture={() => setMenuWorkspace(!menuWorkspace)}>
            <div>
              <div className="avatar">{getOneChartOfString(user.name)}</div>
              <div className="infoUser">
                <p>{user.name}</p>
                {/* <p>{user.email}</p> */}
              </div>
            </div>
            <div className="icon">
              <img src={arrowIcon} alt="" />
            </div>
          </div>

          <div
            className={`${
              menuWorkspace
                ? "ht__sidebar-container-menuShow"
                : "ht__sidebar-container-menuWorkspace"
            } `}
          >
            {/* <div className="userActive">
              <div className="avatarA">{getOneChartOfString(user.name)}</div>
              <p>{user.email}</p>
            </div> */}
            {/* <div
              className="workspace"
              onClickCapture={() => setSubMenuWorkspace(!subMenuWorkspace)}
            >
              <div>
                <p><IntlMessages id="pages.workspace" /></p>
                <p>{user.name}</p>
              </div>
              <div>
                <img src={arrowIcon} alt="mas" />
              </div>
            </div> */}
            {/* <div className=" options-workspace"> */}
            {/* <div
              className={`${subMenuWorkspace
                ? "options-workspace"
                : "options-workspace-hide"
                }`}
            >
              <p><IntlMessages id="pages.workspace" /></p>
              <p className={ !user.isGuest ? 'user-active' : '' } >Personal(Yo)</p>
              {teamwork.map( user => <p onClick={() => changeuserWorkspace(user)} >{user.email}</p> )}
              

            </div> */}
            <div className="options">
              <p onClickCapture={() => history.push("/mi-cuenta")}>
                <IntlMessages id="pages.account" />
              </p>
              {!user.isGuest ? (
                <p onClickCapture={navigateToTeam}>
                  <IntlMessages id="pages.teamadmin" />
                </p>
              ) : (
                <></>
              )}
              <p onClickCapture={dispatchLogout}>
                <IntlMessages id="pages.logout" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
