export const CHANGE_ITEM_TABBAR_INTERESES = "CHANGE_ITEM_TABBAR_INTERESES"
export const CHANGE_ITEM_TABBAR_CUENTA = "CHANGE_ITEM_TABBAR_CUENTA"
export const CHANGE_CURRENT_AUDIENCIA = "CHANGE_CURRENT_AUDIENCIA"
// LOGIN
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
// REFRESH
export const REFRESH = "REFRESH"
export const REFRESH_ERROR = "REFRESH_ERROR"

export const SEARCH_INTEREST = 'SEARCH_INTEREST';
export const SEARCH_INTEREST_SUCCESS = 'SEARCH_INTEREST_SUCCESS';
export const SEARCH_INTEREST_ERROR = 'SEARCH_INTEREST_ERROR';
export const CHANGE_SEARCH_LANGUAGE = "CHANGE_SEARCH_LANGUAGE";

export const ADD_INTEREST_TO_LIST = "ADD_INTEREST_TO_LIST";
export const ADD_INTEREST_TO_SUGGESTION_LIST = "ADD_INTEREST_TO_SUGGESTION_LIST";
export const ADD_INTEREST_IMPORTED_TO_LIST = "ADD_INTEREST_IMPORTED_TO_LIST";

export const DELETE_INTEREST_TO_LIST = "DELETE_INTEREST_TO_LIST"
export const DELETE_SUGGESTION_TO_LIST = "DELETE_SUGGESTION_TO_LIST"
export const DELETE_ALL_INTEREST_TO_LIST = "DELETE_ALL_INTEREST_TO_LIST"


export const GET_SUGGESTIONS = "GET_SUGGESTIONS";
export const GET_SUGGESTIONS_SUCCESS = "GET_SUGGESTIONS_SUCCESS";
export const GET_SUGGESTIONS_ERROR = "GET_SUGGESTIONS_ERROR";


export const GET_LIBRARY_AUDIENCES = 'GET_LIBRARY_AUDIENCES';
export const GET_LIBRARY_AUDIENCES_SUCESS = 'GET_LIBRARY_AUDIENCES_SUCESS';

export const ADD_UNIQ_AUDIENCE = 'ADD_UNIQ_AUDIENCE';

export const GET_TEAMWORK_SUCCESS = 'GET_TEAMWORK_SUCCESS';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_ERROR = "GET_REPORTS_ERROR";

export const IMPLEMENT_INTEREST_FILTER = "IMPLEMENT_INTEREST_FILTER";

export const GET_ADACCOUNTS = "GET_ADACCOUNTS";
export const GET_ADACCOUNTS_SUCCESS = "GET_ADACCOUNTS_SUCCESS";
export const GET_ADACCOUNTS_ERROR = "GET_ADACCOUNTS_ERROR";

export const GET_ACCOUNT_CAMPAIGN = "GET_ACCOUNT_CAMPAIGN";
export const GET_ACCOUNT_CAMPAIGN_SUCCESS = "GET_ACCOUNT_CAMPAIGN_SUCCESS";
export const GET_ACCOUNT_CAMPAIGN_ERROR = "GET_ACCOUNT_CAMPAIGN_ERROR";

export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";

export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_ERROR = "RECOVER_PASSWORD_ERROR";

export const GENERATE_REPORT = "GENERATE_REPORT";
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS";
export const GENERATE_REPORT_ERROR = "GENERATE_REPORT_ERROR";

export const GET_CURRENT_REPORT = "GET_CURRENT_REPORT";

export const IMPORT_INTERESTS = "IMPORT_INTERESTS";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_ERROR = "LOGOUT_ERROR"

export const UPDATE_DATA_USER = "UPDATE_DATA_USER"
export const UPDATE_DATA_USER_SUCCESS = "UPDATE_DATA_USER_SUCCESS"
export const UPDATE_DATA_USER_ERROR = "UPDATE_DATA_USER_ERROR"

export const CHANGE_USINGAI = "CHANGE_USINGAI"

export const FILTER_RESET = "FILTER_RESET"

export const DELETE_USER_GUEST = "DELETE_USER_GUEST"
export const DELETE_USER_GUEST_SUCESS = "DELETE_USER_GUEST_SUCESS"
export const DELETE_USER_GUEST_ERROR = "DELETE_USER_GUEST_ERROR"

export const DATA_USER_FATHER = "DATA_USER_FATHER";

export const UPDATE_KEYWORD = "UPDATE_KEYWORD";

export const SELECT_CURRENT_REPORT = "SELECT_CURRENT_REPORT";

// DELETE REPORT
export const REPORT_DELETE = "REPORT_DELETE";
export const REPORT_DELETE_SUCCESS = "REPORT_DELETE_SUCCESS";
export const REPORT_DELETE_ERROR = "REPORT_DELETE_ERROR";