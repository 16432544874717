import { useState } from "react";
// import { useDispatch } from "react-redux";
// import { useHistory } from "react-router";
// import { NotificationManager } from "../../components/common/react-notifications";
import HeaderScreen from "../../components/header-screens/HeaderScreen";
// import IntlMessages from "../../helpers/IntlMessages";
// import { IInterest, ILibrary } from "../../interfaces/library.interface";
// import { addInterestImported } from "../../redux/actions/interesesAction";
import ContainerGraphics from "./ContainerGraphics";
import ContainerInfoDescription from "./ContainerInfoDescription";
// import GraphCategory from "./graphCategory";
// import GraphSize from "./graphSize";
import SingleAudienceListInterest from "./SingleAudienceListInterest";

const SingleAudiencia = () => {
  // const library = useSelector<any, ILibrary>(
  //   (state) => state.audiencias.library
  // );
  // const listIntereses = useSelector<any, any>(
  //   (state) => state.intereses.listIntereses
  // );
  // const history = useHistory();
  const [interestSelected, setInteresSelected] = useState<any>([]);
  // const dispatch = useDispatch();
  // const addInterestToList = (interests: any) => {
  //   let newInterest;
  //   if (interestSelected.length > 0) {
  //     newInterest = interestSelected;
  //   } else {
  //     newInterest = interests.map(({ id, name }: any) => ({
  //       id,
  //       name,
  //     }));
  //   }
  //   let interestPass: any[] = [];
  //   newInterest = newInterest.map((interest: IInterest) => {
  //     let existe = listIntereses.filter((i: IInterest) => i.id == interest.id);
  //     if (existe.length <= 0) {
  //       interestPass.push(interest);
  //     }
  //   });

  //   dispatch(addInterestImported(interestPass, addNotification, history));
  // };

  // const addNotification = () => {
  //   return NotificationManager.success(
  //     "Se agregaron los intereses a tu lista",
  //     "Interéses Importados correctamente",
  //     1500,
  //     null,
  //     null,
  //     ""
  //   );
  // };

  // const getUniqueInterests = (interests = []) => {
  //   const uniqueInterests: any = [];
  //   interests.forEach((interest: any) => {
  //     const foundInterest = uniqueInterests.find(
  //       (uniqueInterest: any) => uniqueInterest.id === interest.id
  //     );
  //     if (!foundInterest) uniqueInterests.push(interest);
  //   });
  //   return uniqueInterests;
  // };

  return (
    <>
      <HeaderScreen
        title="Librería de audiencias"
        showInput={false}
        idMesagge="pages.library"
      />
      <div className="ht__singleAudiencia">
        <div className="ht__singleAudiencia-containerInfo">
          {/* First container */}
          <ContainerInfoDescription interestSelected={interestSelected} />
          {/* Second Container */}
          <ContainerGraphics />
        </div>

        <SingleAudienceListInterest
          interestSelected={interestSelected}
          setInteresSelected={setInteresSelected}
        />
      </div>
    </>
  );
};

export default SingleAudiencia;
