import IntlMessages from "../../../helpers/IntlMessages";
import closeIcon from "../../../assets/icons/ri_close-fill.svg";
import { Dispatch, SetStateAction, useState } from "react";
import AppLocale from "../../../lang";
import { useSelector } from "react-redux";
import Dropzone from "./Dropzone";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReportPdf from "./report-pdf";
import { IReport } from "../../../interfaces/report.interface";

interface IModalDownload {
  reportName: string;
  clientName: string;
  stateClose: Dispatch<SetStateAction<boolean>>;
  socialSpend: any;
  metrics: any;
}

const ModalDownload = ({
  stateClose,
  clientName,
  reportName,
  socialSpend,
  metrics,
}: IModalDownload) => {
  const [startDownload, setStartDownload] = useState(false);
  const [dataDownload, setDataDownload] = useState({
    clientName,
    reportName,
    message: "",
  });
  const userLang = useSelector<any, any>((state) => state.cuenta.lang);
  const report = useSelector(
    (state: any) => state.reportes.currentReport as IReport
  );
  const currentAppLocale = AppLocale[userLang === "es-ES" ? "es" : "en"];
  const [reportLanguage, setReportLanguage] = useState(
    userLang === "es-ES" ? "es" : "en"
  );

  const handleFile = (file: any) => {
    console.log("==> HANDLEFILE");
    console.log(file);
  };

  return (
    <div className="ht__modalDownload">
      <div className="ht__modalDownload-header">
        <h4>
          <IntlMessages id="modal.reports.download.title" />
        </h4>
        <img
          src={closeIcon}
          alt="Close"
          onClickCapture={() => {
            if (!startDownload) {
              stateClose(false);
            }
          }}
        />
      </div>
      {/* LOGOTIPO DEL CLIENTE  */}
      {/* //! COMPONENTE PARA LOGO DEL CLIENTE */}
      {/* <div className="ht__modalDownload-inputs">
        <p className="ht__modalDownload-label">
          <IntlMessages id="modal.reports.download.labelcustomer" />
        </p>
        <Dropzone handleFile={handleFile} />
        <p className="ht__modalDownload-labeltamanio">
          <IntlMessages id="modal.reports.download.labeltamanio" />
        </p>
      </div> */}
      {/* Nombre del reporte */}
      <div className="ht__modalDownload-inputs">
        <p className="ht__modalDownload-label">
          <IntlMessages id="modal.reports.page1.reportname" />
        </p>
        <input
          type="text"
          name="reportName"
          placeholder={
            currentAppLocale.messages["modal.reports.page1.reportname"]
          }
          value={dataDownload.reportName}
          onChange={(e) =>
            setDataDownload({
              ...dataDownload,
              [e.target.name]: e.target.value,
            })
          }
        />
      </div>
      {/* Nombre del cliente */}
      <div className="ht__modalDownload-inputs input-disable">
        <p className="ht__modalDownload-label">
          <IntlMessages id="modal.reports.page1.clientplaceholder" />
        </p>
        <input
          type="text"
          name="clientName"
          placeholder={
            currentAppLocale.messages["modal.reports.page1.clientplaceholder"]
          }
          defaultValue={dataDownload.clientName}
          disabled={true}
        />
      </div>
      {/* Mensaje */}
      <div className="ht__modalDownload-inputs">
        <p className="ht__modalDownload-label">
          <IntlMessages id="modal.reports.download.introductorymessage" />
        </p>
        <textarea
          name="message"
          placeholder={
            currentAppLocale.messages[
              "modal.reports.download.messageplaceholder"
            ]
          }
          value={dataDownload.message}
        ></textarea>
      </div>
      {/* Idioma del reporte */}
      <div className="ht__modalDownload-inputs">
        <p className="ht__modalDownload-label">
          <IntlMessages id="modal.reports.download.reportlanguage" />
        </p>
        <select
          name="language"
          value={reportLanguage}
          onChange={(e) => setReportLanguage(e.target.value)}
        >
          <option value="es">{currentAppLocale.messages["label.es"]}</option>
          <option value="en">{currentAppLocale.messages["label.en"]}</option>
        </select>
      </div>

      {/* BOTON DE DESCARGAR  */}
      <div className="ht__modalDownload-btndownload">
        <PDFDownloadLink
          fileName={`${dataDownload.reportName}.pdf`}
          document={
            <ReportPdf
              metrics={metrics}
              data={{ data: report, reportName: reportName }}
              language={reportLanguage}
              socialSpend={socialSpend}
            />
          }
        >
          <IntlMessages id="modal.reports.download.btndownload" />
        </PDFDownloadLink>
      </div>
      {/* <div className="ht__modalDownload-btndownload">
      </div> */}
    </div>
  );
};

export default ModalDownload;
