// Icons
import { useEffect, useState } from "react";
import arrowDown from "../../assets/icons/ri_arrow-drop-down-fill.svg";
import iconFind from "../../assets/icons/ri_search-2-line.svg";
import { NotificationManager } from "../../components/common/react-notifications/index";
import { useFindIntereses } from "../../hooks/useFindIntereses";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import IntlMessages from "../../helpers/IntlMessages";
import { changeSearchLangInterest } from "../../redux/actions/interesesAction";
import AppLocale from "../../lang";
import { IComponentFind } from "../../interfaces/interface";

const InputFindInteres: React.FC<IComponentFind> = ({ fromHome }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const dispatch = useDispatch();

  const searchLang = useSelector((state: any) => state.intereses.searchLang);
  const lang = useSelector((state: any) => state.cuenta.lang);

  const fetch = useSelector((state: any) => state.intereses.fetchFindInterests);
  const keyword = useSelector((state: any) => state.intereses.keyword);
  // Hook
  const { value, handleChange, sendKeywordToFind, setValue } =
    useFindIntereses(keyword);
  const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];

  const changeLanguage = (value: string) => {
    // setLanguage(value);
    // dispatch(changeSearchLang(value));
    dispatch(changeSearchLangInterest(value));
    setOpenSubMenu(!openSubMenu);
  };

  const messageError = () => {
    return NotificationManager.error(
      currentAppLocale.messages["alerts.inputfinderrortitle"],
      currentAppLocale.messages["alerts.inputfinderrormessage"],
      3000,
      null,
      null,
      ""
    );
  };

  const searchInsteresByKeyword = () => {
    if (keyword === "") {
      return NotificationManager.error(
        currentAppLocale.messages["alerts.inputfindemptyerrortitle"],
        currentAppLocale.messages["alerts.inputfindemptyerrormessage"],
        3000,
        null,
        null,
        ""
      );
    }
    // TODO: execute action to search interests
    sendKeywordToFind(searchLang, fromHome, messageError);
  };

  useEffect(() => {
    if (keyword && keyword !== "" && keyword !== value) {
      setValue(keyword);
    }
  }, [keyword]);

  return (
    <div className="ht_inputFindInteres">
      <input
        type="text"
        placeholder={currentAppLocale.messages["pages.interest.placeholder"]}
        value={value}
        onChange={handleChange}
        onKeyUp={(e) => e.keyCode === 13 && searchInsteresByKeyword()}
      />
      <div className="language">
        <ul>
          <li>
            <div
              className="ht__language-selected"
              onClickCapture={() => setOpenSubMenu(!openSubMenu)}
            >
              <p>{searchLang === "es_ES" ? "ES" : "EN"}</p>
              <img src={arrowDown} alt="Más" />
            </div>
            <ul className={`${openSubMenu ? "ht_show-submenu" : ""}`}>
              {searchLang !== "es_ES" ? (
                <li onClickCapture={() => changeLanguage("es_ES")}>ES</li>
              ) : (
                <li onClickCapture={() => changeLanguage("en_US")}>EN</li>
              )}
            </ul>
          </li>
        </ul>
      </div>
      <div
        className="btn-find"
        onClickCapture={() => {
          if (!fetch) {
            // sendKeywordToFind(language);
            searchInsteresByKeyword();
          }
        }}
      >
        {fetch ? (
          <Spinner cssClass="" />
        ) : (
          <>
            <img src={iconFind} alt="Buscar" />
            <p>
              <IntlMessages id="btn.search" />
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default InputFindInteres;
