// import { useParams } from "react-router";
import { useFormReset } from "../../hooks/useFormReset";
// import iconMail from "../../assets/icons/ri_mail-line.svg";
// import logo from "../../assets/img/logo_login.svg";
// import iconPass from "../../assets/icons/ri_lock-password-line.svg";
// import Spinner from "../../components/spinner/Spinner";
// import { useSelector } from "react-redux";
import ResetPasswordComponent from "../../components/resetPassword/ResetPasswordComponent";

const ForgotPassword = () => {
  const { values, handleChange, onClickSend, handleBlur } = useFormReset({
    email: "",
    password: "",
    repassword: "",
    error: "",
    // paramans: window.location.pathname.slice(17, -1),
  });

  // window.location.pathname.slice(0, 18);

  // console.log("params", window.location.pathname.slice(17, -1));

  const { email, password, repassword, error } = values;
  return (
    <ResetPasswordComponent
      email={email}
      error={error}
      handleBlur={handleBlur}
      handleChange={handleChange}
      onClickSend={onClickSend}
      password={password}
      repassword={repassword}
      title="Reestablecer contraseña"
    />
  );
};

export default ForgotPassword;
