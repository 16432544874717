import InputFindInteres from "../InputFindInteres";
import iconQuestion from "../../../assets/icons/ri_question-mark.svg";
import importIcon from "../../../assets/icons/ri_upload-line.svg";
import libreriaIcon from "../../../assets/icons/ri_book-2-line.svg";
import iconQuestion2 from "../../../assets/icons/icon_question2.svg";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner/Spinner";
import IntlMessages from "../../../helpers/IntlMessages";
import { addInterestImported } from "../../../redux/actions/interesesAction";
// import { NotificationManager } from "../../../components/common/react-notifications";
import { addNotification, csvFileDestructuring } from "../../../helpers";
// import AppLocale from "../../../lang";
import { IInterest } from "../../../interfaces/library.interface";
import { IEmptyComponent } from "../../../interfaces/interface";
import { useHistory } from "react-router";

const InteresesVacio: React.FC<IEmptyComponent> = ({ fromHome }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fetchFindInterests, listFindIntereses, listIntereses } = useSelector(
    (state: any) => state.intereses
  );
  const lang = useSelector((state: any) => state.cuenta.lang);

  // const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];

  return (
    <div className="ht__interesesVacio">
      <h3>
        <IntlMessages id="pages.interest.search" />
      </h3>
      <p>
        <IntlMessages id="pages.interest.emptyDescription" />
      </p>
      <InputFindInteres fromHome={fromHome} />
      {fetchFindInterests ? (
        <div className="ht_interesesScreen-buscando">
          <h4>
            {" "}
            <IntlMessages id="pages.interest.searching" />{" "}
          </h4>
          <Spinner cssClass="ht_interesesScreen-spinner" />
        </div>
      ) : listFindIntereses?.length === 0 ? (
        <div className="ht__intereses-noFind">
          <h4>
            {" "}
            <IntlMessages id="pages.interest.noResultsTitle" />{" "}
          </h4>
          <p>
            {" "}
            <IntlMessages id="pages.interest.noResultsDescription" />{" "}
          </p>
          <div>
            <img
              className="ht__iconhelp"
              onClickCapture={() =>
                window.open("https://hellotargeting.com/soporte/", "_blank")
              }
              src={iconQuestion2}
              alt="Ayuda"
            />
            <p>
              {" "}
              <IntlMessages id="help" />{" "}
            </p>
          </div>
        </div>
      ) : (
        <div className="ht__interesesVacio-icons">
          {/* AYUDA */}
          <div>
            <div
              onClickCapture={() =>
                window.open("https://hellotargeting.com/soporte/", "_blank")
              }
            >
              <img src={iconQuestion} alt="Question" />
            </div>

            <p>
              <IntlMessages id="help" />
            </p>
          </div>
          {/* IMPORTAR INTERESES */}
          <div
            className="ht__interesesVacio-importBtn"
            onClickCapture={() => {
              document.getElementById("import-csv")?.click();
            }}
          >
            <div>
              <img src={importIcon} alt="Importar" />
            </div>
            <p>
              <IntlMessages id="pages.interest.import" />
            </p>
            <input
              type="file"
              name="file"
              id="import-csv"
              accept=".csv"
              onChange={async (e) => {
                const csv = e.currentTarget.files![0];
                let array = await csvFileDestructuring(csv);
                let interestPass: any[] = [];
                array = array.map((interest: IInterest) => {
                  let existe = listIntereses.filter(
                    (i: IInterest) => i.id === interest.id
                  );
                  if (existe.length <= 0) {
                    interestPass.push(interest);
                  }
                });
                dispatch(
                  addInterestImported(interestPass, addNotification, lang)
                );
              }}
            />
          </div>
          {/* Libreria */}
          <div>
            <div
              onClickCapture={() => {
                history.push("/libreria-audiencias");
              }}
            >
              <img src={libreriaIcon} alt="Libreria" />
            </div>
            <p>
              <IntlMessages id="pages.interest.library" />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default InteresesVacio;
