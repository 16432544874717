import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import iconNote from "../../assets/icons/ri_lightbulb-line.svg";
import IntlMessages from "../../helpers/IntlMessages";
import { IUser } from "../../interfaces/interface";
import ContainerPlan from "./ContainerPlan";
import ContainerPlanProAi from "./ContainerPlanProAi";

const Planes = () => {
  const user = useSelector<any, IUser>((state: any) => state.cuenta.user);
  const [dateInit, setDateInit] = useState("");
  const [finalDate, setFinalDate] = useState("");

  // const typesAdmitsTeam = useSelector<any, any>(
  //   (state) => state.cuenta.typesAdmitsTeam
  // );

  const changeDatesUsesAi = (date: string) => {
    // moment
    const fecha = moment(date, "YYYY-MM-DD").toDate();
    const fechaFin = moment(date, "YYYY-MM-DD").add(1, "year").toDate();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    const monthFin = fechaFin.getMonth() + 1;
    const dayFin = fechaFin.getDate();
    // setDateInit(fecha.toLocaleString());
    setDateInit(
      `${day < 10 ? `0${day}` : day}/${
        month < 10 ? `0${month}` : month
      }/${fecha.getFullYear()}`
    );
    setFinalDate(
      `${dayFin < 10 ? `0${dayFin}` : dayFin}/${
        monthFin < 10 ? `0${monthFin}` : monthFin
      }/${fechaFin.getFullYear()}`
    );
  };

  useEffect(() => {
    if (user?.account && user.account === "proai") {
      changeDatesUsesAi(user.createdAt);
    } else {
      if (user.initAditionalPlan) {
        changeDatesUsesAi(user.initAditionalPlan);
      }
    }
  }, []);

  return (
    <div className="ht__planes">
      <h4>
        <IntlMessages id="pages.account.plans" />
      </h4>
      <div className="ht__planes-container">
        {/* PLAN LITE */}
        {user.lastAccount && user.lastAccount !== "" && (
          <ContainerPlan cssClass="plan-margin-rigth" />
        )}
        {/* <div className="ht__planes-container-lite">
          <div className="header">LITE</div>
          <div className="body">
            <p>Acceso ilimitado al software</p>
            {user.account === 'lite' && (
              <div className="ht__planPro-note">
                <img src={iconNote} alt="Nota" />
                <p>Tu tienes plan LITE</p>
              </div>
            )}
          </div>
          
        </div> */}
        {/* PLAN PRO */}
        {user.account === "proai" ? <ContainerPlanProAi /> : <ContainerPlan />}
        {/* COMING SOON */}
        {/* <div className="ht__planComingSoon">
          <div className="header">PROXIMAMENTE</div>
          <div className="body">
            <div className="message">Pronto lo estaremos anunciando</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default Planes;
