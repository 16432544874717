import { PropsModal } from "../../interfaces/interface";

const ModalComponent: React.FC<PropsModal> = ({
  stateShowDialog,
  idUser,
  isEliminar,
  component: Component,
  setOpenModalSynced,
  classCss,
  titleComponent,
  idReport,
  clientName,
  reportName,
  socialSpend,
  metrics,
}) => {
  return (
    <>
      <div
        className={`ht__modalComponent ${classCss ?? ""}`}
        // onClickCapture={() => stateShowDialog(false)}
      >
        <div className="ht__modalComponent-children">
          <Component
            isEliminar={isEliminar}
            id={idUser}
            stateClose={stateShowDialog}
            setOpenModalSynced={setOpenModalSynced}
            titleComponent={titleComponent}
            idReport={idReport}
            clientName={clientName}
            reportName={reportName}
            socialSpend={socialSpend}
            metrics={metrics}
          />
        </div>
      </div>
    </>
  );
};

export default ModalComponent;
