interface ICardsReportSingle {
  title?: string;
  value: string;
}

const CardsReportSingle = ({ title, value }: ICardsReportSingle) => {
  return (
    <div>
      <p>{title}</p>
      <p>{value}</p>
    </div>
  );
};

export default CardsReportSingle;
