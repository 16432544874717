import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { createNewUserPassword } from "../redux/actions/cuentaAction";
import { useHistory } from 'react-router';


export const useFormPasswordUser = (initialState: any) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const lang = useSelector((state: any) => state.cuenta.lang)

    const [values, setValues] = useState(initialState);

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = target;
        setValues({
            ...values,
            [name]: value
        })
    }
    const handleBlur = () => {
        if (values.password !== values.repassword) {
            setValues({
                ...values,
                error: "Las contraseñas no son iguales."
            })
        } else {
            setValues({
                ...values,
                error: ""
            })
        }
    }
    const onClickSend = (hash: string) => {
        if (values.error === "") {
            if (values.email !== '' && values.password !== '' && values.repassword !== '') {
                dispatch(createNewUserPassword(hash, values.email, values.password, history, lang))
            } else {
                setValues({
                    ...values,
                    error: "Existen datos vacíos"
                })
                setTimeout(() => {
                    setValues({
                        ...values,
                        error: ""
                    })
                }, 4000)
            }

        } else {
            setValues({
                ...values,
                error: "Existen Errores. Revisa el formulario"
            })
            setTimeout(() => {
                setValues({
                    ...values,
                    error: ""
                })
            }, 4000)
        }

    }

    return { values, handleChange, onClickSend, handleBlur };
}