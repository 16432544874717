import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { NotificationManager } from "../../components/common/react-notifications";
import iconMail from "../../assets/icons/ri_mail-line.svg";
import logo from "../../assets/img/logo_login.svg";
import Spinner from "../../components/spinner/Spinner";
import { sendEmailRecover } from "../../redux/actions/cuentaAction";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";

const RecoverPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const userLang = useSelector<any, any>((state) => state.cuenta.lang);

  const { fetchLogin, lang } = useSelector((state: any) => state.cuenta);
  const currentAppLocale = AppLocale[userLang === "es-ES" ? "es" : "en"];

  const sendEmail = () => {
    if (email === "") {
      NotificationManager.error(
        currentAppLocale.messages["alerts.recoveremptytitle"],
        currentAppLocale.messages["alerts.recoveremptymessage"],
        2000,
        null,
        null,
        ""
      );
    } else {
      dispatch(sendEmailRecover(email, history, lang));
    }
  };

  return (
    <div className="ht__loginScreen">
      <div className="ht__loginScreen-container">
        <div>
          <div className="ht__loginScreen-container-logo">
            <img src={logo} alt="Hello targeting" />
          </div>
        </div>
        <div>
          <div className="ht__loginScreen-container-info">
            <h4>
              {" "}
              <IntlMessages id="pages.forgot.recover" />{" "}
            </h4>
            <div>
              <label htmlFor="email">
                <IntlMessages id="label.input.name" />{" "}
              </label>
              <div>
                <img src={iconMail} alt="Password" />
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={
                    userLang === "es-ES" ? "Tu correo" : "Your Email"
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div
              className="ht__loginScreen-btnLogin"
              onClickCapture={sendEmail}
            >
              {fetchLogin ? (
                <Spinner cssClass="" />
              ) : (
                <IntlMessages id="pages.forgot.btnsend" />
              )}
            </div>
            <p
              className="ht__recoverPass-volver"
              onClickCapture={() => history.push("/login")}
            >
              <IntlMessages id="pages.forgot.returnlogin" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
