import {
    CHANGE_ITEM_TABBAR_CUENTA,
    CHANGE_LANGUAGE,
    GET_TEAMWORK_SUCCESS,
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    REFRESH,
    REFRESH_ERROR,
    SEND_EMAIL,
    SEND_EMAIL_ERROR,
    SEND_EMAIL_SUCCESS,
    RECOVER_PASSWORD,
    RECOVER_PASSWORD_ERROR,
    RECOVER_PASSWORD_SUCCESS,
    LOGOUT,
    LOGOUT_ERROR,
    LOGOUT_SUCCESS,
    UPDATE_DATA_USER,
    UPDATE_DATA_USER_ERROR,
    UPDATE_DATA_USER_SUCCESS,
    SEARCH_INTEREST_SUCCESS,
    // DELETE_USER_GUEST,
    // DELETE_USER_GUEST_ERROR,
    DELETE_USER_GUEST_SUCESS
} from "../../types/types";

import {
    ActionModel
} from "../../interfaces/interface";

const initialData = {
    tabbar: 0,
    accessToken: null,
    user: null,
    fetchLogin: false,
    fetchRefresh: false,
    errorLogin: null,
    teamwork: [],
    lang: localStorage.getItem('userLang') ? localStorage.getItem('userLang') : (navigator.language.slice(0,2) === 'es' ? 'es-ES' : 'en-US'),
    fetchRecover: false,
    fetchLogout: false,
    fetchUpdate: false,
    typesAdmits: ['proai'],
    typesAdmitsTeam: ['pro', 'proai'],
    fatherInfo: null
}

export const cuentaReducer = (state = initialData, action: ActionModel) => {
    switch (action.type) {
        case CHANGE_ITEM_TABBAR_CUENTA:
            return {
                ...state,
                tabbar: action.payload
            }
        case LOGIN:
            return {
                ...state,
                fetchLogin: true
            }
        case LOGIN_ERROR:
            return {
                ...state,
                errorLogin: action.payload,
                fetchLogin: false,
                fetchRefresh: false,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                user: action.payload.user,
                errorLogin: null,
                fetchRefresh: false,
                fatherInfo: action.payload.fatherInfo
            }
        case REFRESH:
            return {
                ...state,
                fetchRefresh: true,
            }
        case REFRESH_ERROR:
            return {
                ...state,
                fetchRefresh: false
            }
        case GET_TEAMWORK_SUCCESS:
            return {
                ...state,
                teamwork: action.payload
            }
        case CHANGE_LANGUAGE:
            return {
                ...state,
                lang: action.payload
            }
        case SEND_EMAIL:
            return {
                ...state,
                fetchLogin: true
            }
        case SEND_EMAIL_ERROR:
            return {
                ...state,
                fetchLogin: false
            }
        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                fetchLogin: false
            }
        case RECOVER_PASSWORD:
            return {
                ...state,
                fetchRecover: true
            }
        case RECOVER_PASSWORD_ERROR:
            return {
                ...state,
                fetchRecover: false
            }
        case RECOVER_PASSWORD_SUCCESS:
            return {
                ...state,
                fetchRecover: false
            }
        case LOGOUT:
            return {
                ...state,
                fetchLogout: true
            }
        case LOGOUT_SUCCESS:
            return initialData;
        case LOGOUT_ERROR:
            return {
                ...state,
                fetchLogout: false
            }
        case UPDATE_DATA_USER:
            return {
                ...state,
                fetchUpdate: true
            }
        case UPDATE_DATA_USER_ERROR:
            return {
                ...state,
                fetchUpdate: false
            }
        case UPDATE_DATA_USER_SUCCESS:
            return {
                ...state,
                fetchUpdate: false,
                user: action.payload
            }
        case SEARCH_INTEREST_SUCCESS:
            return {
                ...state,
                user: action.payload.user
            }
        case DELETE_USER_GUEST_SUCESS:
            return {
                ...state,
                teamwork: action.payload
            }
        default:
            return state
    }
}

