import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { NotificationManager } from "../../components/common/react-notifications";
import clienteAxios from "../../config/axios";
import { backendURL, facebookURL } from "../../constants/constants";
import {
    CHANGE_ITEM_TABBAR_CUENTA,
    CHANGE_LANGUAGE,
    GET_TEAMWORK_SUCCESS,
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    REFRESH,
    REFRESH_ERROR,
    SEND_EMAIL,
    SEND_EMAIL_ERROR,
    SEND_EMAIL_SUCCESS,
    RECOVER_PASSWORD,
    RECOVER_PASSWORD_ERROR,
    RECOVER_PASSWORD_SUCCESS,
    LOGOUT,
    LOGOUT_ERROR,
    LOGOUT_SUCCESS,
    UPDATE_DATA_USER,
    UPDATE_DATA_USER_ERROR,
    UPDATE_DATA_USER_SUCCESS,
    DELETE_USER_GUEST,
    DELETE_USER_GUEST_ERROR,
    DELETE_USER_GUEST_SUCESS
} from "../../types/types";
import { store } from "../store";

export const changeItem = (item: number) => {
    return (dispatch: any) => {
        dispatch({
            type: CHANGE_ITEM_TABBAR_CUENTA,
            payload: item
        })
    }
}

export const login = (email: string, password: string, messaggeError: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: LOGIN
        })
        try {
            const resp = await axios.post(`${backendURL.baseURL}auth/login`, {
                email,
                password
            },
                {
                    withCredentials: true,
                }
            );
            const user = resp.data.user;
            let fatherInfo: any = {}
            if (user?.isGuest) {
                const respGuest = await axios.get(`${backendURL.baseURL}guests/${user.id}`,
                    {
                        headers: {
                            "Authorization": `Bearer ${resp.data.accessToken}`
                        }
                    }
                );
                resp.data.user.facebookToken = respGuest.data.facebookToken;
                resp.data.user.facebookName = respGuest.data.facebookName;
                resp.data.user.avatar = respGuest.data.avatar;
                fatherInfo.aditionPlan = respGuest.data.aditionPlan;
                fatherInfo.maxAiUses = respGuest.data.maxAiUses;
                fatherInfo.initAditionalPlan = respGuest.data.initAditionalPlan;
                fatherInfo.account = respGuest.data.account;
                // dispatch({
                //     type: LOGIN_SUCCESS,
                //     payload: {
                //         accessToken: resp.data.accessToken,
                //         user: resp.data.user,
                //         father
                //     }
                // })
                // return;
            }
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    accessToken: resp.data.accessToken,
                    user: resp.data.user,
                    fatherInfo
                }
            })

        } catch (error: any) {
            console.log("===> Error al iniciar sesión");
            console.log(error);
            console.log(error?.message);
            NotificationManager.error(
                "",
                messaggeError,
                2000,
                null,
                "",
                ""
            )
            dispatch({
                type: LOGIN_ERROR,
            })
        }
    }
}

export const refresh = () => {
    return async (dispatch: any) => {
        dispatch({
            type: REFRESH
        })
        try {
            const resp = await axios.post(`${backendURL.baseURL}auth/refresh`,
                undefined,
                {
                    withCredentials: true
                }
            );
            const user = resp.data.user;
            let fatherInfo: any = {}
            if (user?.isGuest) {
                const respGuest = await axios.get(`${backendURL.baseURL}guests/${user.id}`,
                    {
                        headers: {
                            "Authorization": `Bearer ${resp.data.accessToken}`
                        }
                    }
                );
                user.facebookToken = respGuest.data.facebookToken;
                user.facebookName = respGuest.data.facebookName;
                user.avatar = respGuest.data.avatar;
                fatherInfo.aditionPlan = respGuest.data.aditionPlan;
                fatherInfo.maxAiUses = respGuest.data.maxAiUses;
                fatherInfo.initAditionalPlan = respGuest.data.initAditionalPlan;
                fatherInfo.account = respGuest.data.account;
            }

            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    accessToken: resp.data.accessToken,
                    user,
                    fatherInfo
                }
            });
        } catch (error: any) {
            console.log("==> Error al refrescar Token");
            console.log(error);
            console.log(error?.message);
            dispatch({
                type: REFRESH_ERROR
            })
        }
    }
}

interface ILoginFacebook {
    token: string;
    name?: string;
    url?: string;
}

export const loginFacebook = (data: ILoginFacebook, lang: string, setOpenModalSynced?: Dispatch<any>) => {
    return async (dispatch: any) => {
        try {
            // const respFb = await axios.get(`${facebookURL.baseURL}/me/adaccounts`, {
            //     params: {
            //         access_token: data.token,
            //         fields: 'name,account_status'
            //     }
            // })

            // if (respFb.data?.data?.length > 0) {
            const resp = await clienteAxios.post('oauth',
                data,
                {
                    withCredentials: true
                }
            );
            if (setOpenModalSynced !== null && setOpenModalSynced !== undefined) {
                setOpenModalSynced(true);
            }
            dispatch({
                type: UPDATE_DATA_USER_SUCCESS,
                payload: resp.data
            });

            // } else {
            //     NotificationManager.error(
            //         "Necesitas tener mínimo una cuenta AD en facebook para poder acceder a esta herramienta",
            //         "No hay Cuentas ADS",
            //         8000,
            //         null,
            //         "",
            //         ""
            //     )
            // }
        } catch (error) {
            console.log("===> Error al logear con FB");
            console.log(error);
            NotificationManager.error(
                `${lang === "es-ES" ? "Hubo un error al vincular tu cuenta de facebook" : "There was an error linking your facebook account"}`,
                `${lang === "es-ES" ? "Error con Facebook" : "Error with Facebook"}`,
                3000,
                null,
                "",
                ""
            )

        }
    }
}

export const unlinkFacebook = () => {
    return async (dispatch: any) => {
        try {
            const resp = await clienteAxios.delete('oauth',
                {
                    withCredentials: true
                }
            );

            dispatch({
                type: UPDATE_DATA_USER_SUCCESS,
                payload: resp.data
            });
        } catch (error) {

        }
    }
}

export const getAllTeamWork = () => {
    return async (dispatch: any) => {
        try {
            const resp = await clienteAxios.get('guests');
            dispatch({
                type: GET_TEAMWORK_SUCCESS,
                payload: resp.data
            });
        } catch (error) {
        }

    }
}

export const sendInvitations = (emails: string[], stateClose: any, currentAppLocale: any) => {
    return async (dispatch: any) => {
        try {
            const resp = await clienteAxios.post('guests',
                { emails },
                {
                    withCredentials: true
                }
            );
            dispatch({
                type: GET_TEAMWORK_SUCCESS,
                payload: resp.data
            });
            NotificationManager.success(
                currentAppLocale.messages["alerts.sendemailsuccessmessage"],
                "",
                1500,
                null,
                null,
                ""
            );
            stateClose(false);
        } catch (error) {
            console.log("===> Error al enviar correo");
            console.log(error);
            NotificationManager.error(
                "",
                currentAppLocale.messages["alerts.sendemailerrortitle"],
                3000,
                null,
                null,
                ""
            );
        }
    }
}

export const changeLanguageInit = (l: string) => {
    return (dispatch: any) => {
        dispatch({
            type: CHANGE_LANGUAGE,
            payload: l
        });
        localStorage.setItem('userLang', l);
    }
}

export const sendEmailRecover = (email: string, history: any, lang: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: SEND_EMAIL
        })
        try {
            await clienteAxios.post("auth/forgot", {
                email
            })
            NotificationManager.success(
                `${lang === "es-ES" ? "Link de recuperación envíado." : "Recovery link sent."}`,
                `${lang === "es-ES" ? "Se ha enviado un link a tu correo. Revisa tu bandeja de entrada" : "A link has been sent to your email. Check your inbox"}`,
                5000,
                null,
                null,
                ""
            );
            dispatch({
                type: SEND_EMAIL_SUCCESS
            })
            history.push("/login")

        } catch (error) {
            console.log("==> ERROR AL ENVIAR CORREO");
            console.log(error);
            NotificationManager.error(
                `${lang === "es-ES" ? "Error al enviar link" : "Error sending link"}`,
                `${lang === "es-ES" ? "Hubo un error al enviar el link a tu correo. Intentalo de nuevo." : "There was an error sending the link to your email. Try again."}`,
                4000,
                null,
                null,
                ""
            );
            dispatch({
                type: SEND_EMAIL_ERROR
            })

        }
    }
}

export const recoverPassword = (hash: string, email: string, password: string, history: any, lang: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: RECOVER_PASSWORD
        })
        try {
            await clienteAxios.patch('auth/recover', {
                email,
                password,
                hash
            })
            dispatch({
                type: RECOVER_PASSWORD_SUCCESS
            })
            NotificationManager.success(
                `${lang === "es-ES" ? "Contraseña reestablecida" : "Password reset"}`,
                `${lang === "es-ES" ? "Tu contraseña se ha reestablecido con éxito" : "Your password has been reset successfully"}`,
                4000,
                null,
                null,
                ""
            );
            history.push("/login");
        } catch (error) {
            console.log("==> ERROR AL RECUPERAR CONTRASEÑA");
            console.log(error);
            dispatch({
                type: RECOVER_PASSWORD_ERROR
            })
            NotificationManager.error(
                `${lang === "es-ES" ? "Error al reestablecer contraseña" : "Failed to reset password"}`,
                `${lang === "es-ES" ? "Hubo un error al reestablecer la contraseña. Intentalo de nuevo" : "There was an error resetting the password. Try again"}`,
                4000,
                null,
                null,
                ""
            );
        }
    }
}
export const createNewUserPassword = (hash: string, email: string, password: string, history: any, lang: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: RECOVER_PASSWORD
        })
        try {
            await clienteAxios.patch('auth/recover', {
                email,
                password,
                hash
            })
            dispatch({
                type: RECOVER_PASSWORD_SUCCESS
            })
            NotificationManager.success(
                `${lang === "es-ES" ? "Contraseña creada" : "Password created"}`,
                `${lang === "es-ES" ? "Tu contraseña se ha creado con éxito" : "Your password has been created successfully"}`,
                4000,
                null,
                null,
                ""
            );
            history.push("/login");
        } catch (error) {
            console.log("==> ERROR AL CREAR CONTRASEÑA");
            console.log(error);
            dispatch({
                type: RECOVER_PASSWORD_ERROR
            })
            NotificationManager.error(
                `${lang === "es-ES" ? "Error al crear contraseña" : "Failed to create password"}`,
                `${lang === "es-ES" ? "Hubo un error al crear la contraseña. Intentalo de nuevo" : "There was an error creating the password. Try again"}`,
                4000,
                null,
                null,
                ""
            );
        }
    }
}

export const startLogout = (lang: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: LOGOUT
        })
        try {
            await clienteAxios.delete('auth/logout');
            dispatch({
                type: LOGOUT_SUCCESS
            })
            // localStorage.removeItem("userLang");
            localStorage.removeItem("ht-keyword");
            localStorage.removeItem("ht-listFindIntereses");
            localStorage.removeItem("ht-categories");
            localStorage.removeItem("ht-listFindAI");
            localStorage.removeItem("searchLang");
            localStorage.removeItem("ht__useAI");
            localStorage.removeItem("ht__paginationInterest");
            localStorage.removeItem("ht__paginationSuggestion");
            localStorage.removeItem("ht__myinterests");
            localStorage.removeItem("ht__paginationAudience");

        } catch (error) {
            console.log("===> Error al cerrar sesión");
            console.log(error);
            dispatch({
                type: LOGOUT_ERROR
            })
            NotificationManager.error(
                `${lang === "es-ES" ? "Error al cerrar sesión" : "Logout error"}`,
                "",
                4000,
                null,
                null,
                ""
            );
        }
    }
}

export const updateUserData = (values: any, messageOk?: string, messageError?: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: UPDATE_DATA_USER
        })

        try {
            const state = store.getState();
            const resp = await clienteAxios.patch(`users/${state.cuenta.user.id}`, {
                ...values
            })

            dispatch({
                type: UPDATE_DATA_USER_SUCCESS,
                payload: resp.data
            })
            if (messageOk) {
                NotificationManager.success(
                    "",
                    messageOk!,
                    2000,
                    null,
                    "",
                    ""
                )
            }
        } catch (error) {
            console.log("====> Error al actualziar datos de usuario");
            console.log(error);
            dispatch({
                type: UPDATE_DATA_USER_ERROR
            })
            if (messageError) {
                NotificationManager.error(
                    "",
                    messageError,
                    2000,
                    null,
                    "",
                    ""
                )
            }
        }
    }
}

export const deleteGuestUser = (id: string, stateClose: Dispatch<SetStateAction<boolean>>) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: DELETE_USER_GUEST
        })
        try {
            const resp = await clienteAxios.delete(`guests/${id}`, {
                params: {
                    guestId: id
                }
            });
            dispatch({
                type: DELETE_USER_GUEST_SUCESS,
                payload: resp.data
            })
            stateClose(false);

        } catch (error) {
            console.log("Error al eliminar invitado");
            console.log(error);
            dispatch({
                type: DELETE_USER_GUEST_ERROR
            })


        }

    }
}