import closeIcon from "../../assets/icons/ri_close-fill.svg";
// import arrowIcon from "../../assets/icons/ri_arrow-bottom-s-line.svg";
// import IconHelp from "../../assets/icons/ri_question-line.svg";
import { PropsFiltro, PropsFiltroOption } from "../../interfaces/interface";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FiltroOption from "./FiltroOption";
import { filterListInterest } from "../../redux/actions/interesesAction";
import IntlMessages from "../../helpers/IntlMessages";

const FiltroComponent: React.FC<PropsFiltro> = ({ funcion, isAudiencia }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state: any) => state.intereses.categories);
  const filtersInterest = useSelector(
    (state: any) => state.intereses.filtersInterest
  );
  const listFindIntereses = useSelector(
    (state: any) => state.intereses.listFindIntereses
  );
  const [filters, setFilters] = useState<any>(filtersInterest);

  const changeFiltroOptions = (
    e: ChangeEvent<HTMLInputElement>,
    submenu: Array<string>
  ) => {
    const value = e.target.checked;
    const name = e.target.name;
    if (value) {
      // setFilters([...filters, name]);
      // let array = submenu.map((item: string) => `${name}>${item}`);
      setFilters({
        ...filters,
        category: [
          ...filters.category,
          ...submenu.map((item: string) => `${name}>${item}`),
        ],
      });
      // setFilters([...filters, ...array]);
    } else {
      var arrayTemp: Array<string> = filters?.category;
      submenu?.forEach((item: string) => {
        const compare = `${name}>${item}`;
        arrayTemp = arrayTemp.filter((filter: string) => filter !== compare);
      });

      setFilters({
        ...filters,
        category: arrayTemp,
      });
    }
  };
  const changeFiltroSubOptions = (
    e: ChangeEvent<HTMLInputElement>,
    nameInterest: string
  ) => {
    const value = e.target.checked;
    const name = e.target.name;
    if (value) {
      // if (filters?.category?.length === 0) {
      //   // setFilters([...filters, `${nameInterest}>${name}`]);
      //   setFilters
      // } else {
      //   // const array: Array<any> = [];
      //   var repeat = false;
      //   filters?.category?.forEach((filter: string) => {
      //     if (filter === `${nameInterest}>${name}`) {
      //       repeat = true;
      //     }
      //   });
      //   if (!repeat) {
      //     setFilters([...filters, `${nameInterest}>${name}`]);
      //   }
      // }
      var repeat = false;
      filters?.category?.forEach((filter: string) => {
        if (filter === `${nameInterest}>${name}`) {
          repeat = true;
        }
      });
      if (!repeat) {
        setFilters({
          ...filters,
          category: [...filters?.category, `${nameInterest}>${name}`],
        });
        // setFilters([...filters, `${nameInterest}>${name}`]);
      }
    } else {
      const arrTemp = filters.category?.filter(
        (filter: string) => filter !== `${nameInterest}>${name}`
      );
      setFilters({
        ...filters,
        category: arrTemp,
      });
    }
  };

  const changeFiltroSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setFilters({
        ...filters,
        size: {
          ...filters.size,
          [e.target.name]: 0,
        },
      });
    } else {
      const value = parseInt(e.target.value);
      if (!isNaN(value)) {
        setFilters({
          ...filters,
          size: {
            ...filters.size,
            [e.target.name]: value,
          },
        });
      }
    }
  };

  const implementFilter = () => {
    dispatch(filterListInterest(filters, listFindIntereses));
  };

  return (
    <>
      <div className="ht__filtroComponent-opacity"></div>
      <div className="ht__filtroComponent">
        <div>
          <div className="ht__filtroComponent-header">
            <img
              src={closeIcon}
              alt="Close"
              onClickCapture={() => funcion(false)}
            />
            <h4>
              <IntlMessages id="label.filters" />
              {Object.keys(filtersInterest?.category)?.length > 0 ||
              filtersInterest?.size?.minimo > 0 ||
              filtersInterest?.size?.maximo > 0 ? (
                <div className="container-filtro-filtersNum">
                  {filtersInterest?.category.length +
                    ((filtersInterest?.size?.minimo > 0 ? 1 : 0) +
                      (filtersInterest?.size?.maximo > 0 ? 1 : 0))}
                </div>
              ) : (
                <></>
              )}
            </h4>
            <p>
              <IntlMessages id="label.filtersDescription" />
            </p>
          </div>
          {/* {isAudiencia ? ( */}
          <div className="ht__filtroComponent-audiencia">
            <p>
              <IntlMessages id="label.sizeAudience" />
            </p>
            <div className="ht__filtroComponent-audiencia-inputs">
              <div>
                <label htmlFor="minimo">
                  <IntlMessages id="label.min" />
                </label>
                <input
                  onChange={changeFiltroSize}
                  type="text"
                  name="minimo"
                  value={filters?.size?.minimo}
                  placeholder={"Mínimo"}
                />
              </div>
              <div>
                <label htmlFor="maximo">
                  <IntlMessages id="label.max" />
                </label>
                <input
                  onChange={changeFiltroSize}
                  type="text"
                  name="maximo"
                  value={filters?.size?.maximo}
                  placeholder="Máximo"
                />
              </div>
            </div>
          </div>
          {/* ) : (
            <></>
          )} */}

          <h4 className="ht__filtroComponent-titleCategoria">
            <IntlMessages id="label.categories" />
          </h4>
          <div className="ht__filtroComponent-containerCategorias">
            {categories.map((category: PropsFiltroOption) => (
              <FiltroOption
                key={category.name}
                changeFiltroOptions={changeFiltroOptions}
                name={category.name}
                submenu={category.submenu}
                changeFiltroSubOptions={changeFiltroSubOptions}
                filtros={filters}
              />
            ))}
          </div>

          {isAudiencia ? (
            <></>
          ) : (
            // <div className="ht__filtroComponent-containerNichos">
            //   <div className="title">
            //     <p><IntlMessages id="label.nichos" /></p>
            //     <img src={IconHelp} alt="Ayuda" />
            //   </div>
            //   <select name="nicho" id="nicho">
            //     <option value="arcade"><IntlMessages id="label.arcade" /></option>
            //   </select>
            // </div>
            <></>
          )}
        </div>

        <div>
          <div className="btn-cancelar" onClickCapture={() => funcion(false)}>
            <IntlMessages id="label.cancel" />
          </div>
          <div
            className="btn-aplicar"
            onClickCapture={() => {
              implementFilter();
              funcion(false);
            }}
          >
            <IntlMessages id="label.apply" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltroComponent;
