import axios from 'axios';
import { backendURL } from '../constants/constants'
import { store } from '../redux/store';
import { LOGIN_SUCCESS } from '../types/types';

const clienteAxios = axios.create({
    baseURL: backendURL.baseURL,
    // headers,
    withCredentials: true
});

clienteAxios.interceptors.request.use(
    config => {
        const state = store.getState();
        const accessToken = state.cuenta.accessToken;
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
)


clienteAxios.interceptors.response.use(
    res => {
        return res;
    },
    async err => {
        const originalConfig = err.config;

        if (originalConfig.url !== '/login' && err.response) {
            // Access token was expired
            if (err.response.status > 400) {
                try {
                    const resp = await axios.post(`${backendURL.baseURL}auth/refresh`,
                        undefined,
                        {
                            withCredentials: true
                        }
                    );
                    const user = resp.data.user;
                    let fatherInfo: any = {};
                    if (user?.isGuest) {
                        const respGuest = await axios.get(`${backendURL.baseURL}guests/${user.id}`,
                            {
                                headers: {
                                    "Authorization": `Bearer ${resp.data.accessToken}`
                                }
                            }
                        );
                        resp.data.user.facebookToken = respGuest.data.facebookToken;
                        resp.data.user.facebookName = respGuest.data.facebookName;
                        resp.data.user.avatar = respGuest.data.avatar;
                        fatherInfo.aditionPlan = respGuest.data.aditionPlan;
                        fatherInfo.maxAiUses = respGuest.data.maxAiUses;
                        fatherInfo.initAditionalPlan = respGuest.data.initAditionalPlan;
                        fatherInfo.account = respGuest.data.account;
                    }
                    store.dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {
                            accessToken: resp.data.accessToken,
                            user: resp.data.user,
                            fatherInfo
                        }
                    })

                    return axios({
                        ...originalConfig,
                        headers: {
                            ...originalConfig.headers,
                            Authorization: `Bearer ${resp.data.accessToken}`,
                        },
                    });
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);

export default clienteAxios;