import { useEffect } from "react";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";
import Sidebar from "../components/sidebar/sidebar";
import Home from "../screens/home/Home";
// import { refresh } from "../redux/actions/cuentaAction";
// import HomeScreen from "../screens/HomeScreen";
import InteresesScreen from "../screens/Intereses/InteresesScreen";
import LibreriaScreen from "../screens/libreria/libreriaScreen";
import MiCuentaScreen from "../screens/miCuenta/MiCuenta";
import ReporteSpecifications from "../screens/reportes/reporteSpecifications";
import ReportesScreen from "../screens/reportes/reportesScreen";
import SingleAudiencia from "../screens/single-audiencia/SingleAudiencia";
import { PublicRouter } from "./PublicRouter";

export const DashboardRouter = () => {
  const report = useSelector((state: any) => state.reportes.currentReport);
  useEffect(() => {
    if (
      window.location.pathname !== "/single-audiencias" &&
      window.location.pathname !== "/reporte-specification"
    ) {
      localStorage.setItem("last_path", window.location.pathname);
    }
  }, [window.location]);

  return (
    <div className="ht__containerPrincipal">
      <Sidebar />
      <div className="ht__containerContent">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/intereses" component={InteresesScreen} />
          <Route exact path="/libreria-audiencias" component={LibreriaScreen} />
          <Route exact path="/single-audiencias" component={SingleAudiencia} />
          <Route exact path="/mi-cuenta" component={MiCuentaScreen} />
          <Route exact path="/reportes" component={ReportesScreen} />
          <PublicRouter
            isAutenticated={report ? false : true}
            path="/reporte-specification"
            component={ReporteSpecifications}
          />
        </Switch>
      </div>
    </div>
  );
};
