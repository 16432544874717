import HeaderScreen from "../../components/header-screens/HeaderScreen";
// import filterIcon from "../../assets/icons/ri_filter-3-line.svg";
import findIcon from "../../assets/icons/ri_search-line.svg";
import CardInteres from "./componentsLibreriaScreen/CardIntereses";
import { FormEvent, useState } from "react";
// import FiltroComponent from "../../components/filtroComponents/FiltroComponent";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "./componentsLibreriaScreen/LoadingComponent";
import { useEffect } from "react";
import { getAllAudiences } from "../../redux/actions/audienciasAction";
// import IntlMessages from "../../helpers/IntlMessages";
import { ILibrary } from "../../interfaces/library.interface";
import AppLocale from "../../lang";

const LibreriaScreen = () => {
  // const [openFiltro, setOpenFltro] = useState(false);
  const [libaryListFilter, setLibraryFilter] = useState<any>(null);
  const userLang = useSelector<any, any>((state) => state.cuenta.lang);
  const currentAppLocale = AppLocale[userLang === "es-ES" ? "es" : "en"];

  const { fetching, audiencias } = useSelector(
    (state: any) => state.audiencias
  );

  const dispatch = useDispatch();

  const getAudiences = () => dispatch(getAllAudiences());

  const handleChangeFilter = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value.trim() === "") {
      setLibraryFilter(null);
    } else {
      // let tempArray: any = [];
      let tempArray = audiencias?.filter((libary: ILibrary) => {
        if (libary.name[0].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
          return libary;
        }
      });
      setLibraryFilter(tempArray);
    }
  };

  useEffect(() => {
    getAudiences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="ht__libreriaScreen">
        <HeaderScreen
          title="Librería de audiecias"
          showInput={false}
          idMesagge="pages.library"
        />
        {fetching ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="ht__libreriaScreen-rowBetween">
              <div>
                <img src={findIcon} alt="Find" />
                <input
                  type="text"
                  name="audiencia"
                  placeholder={
                    currentAppLocale.messages["pages.libray.placeholderFind"]
                  }
                  onChangeCapture={(e) => handleChangeFilter(e)}
                />
              </div>
              {/* <div
                className="ht__libreriaScreen-rowBetween-filtro"
                onClickCapture={() => setOpenFltro(true)}
              >
                <div className="icon">
                  <img src={filterIcon} alt="" />
                </div>
                <div>
                  <p>
                    <IntlMessages id="label.filters" />
                  </p>
                </div>
              </div> */}
            </div>

            <div className="ht__libreriaScreen-containerCards">
              <CardInteres libaryListFilter={libaryListFilter} />
            </div>
          </>
        )}
      </div>

      {/* {openFiltro ? (
        <FiltroComponent funcion={setOpenFltro} isAudiencia={true} />
      ) : (
        <></>
      )} */}
    </>
  );
};

export default LibreriaScreen;
