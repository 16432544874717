import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, useParams } from "react-router-dom";
import Spinner from "../components/spinner/Spinner";
import { changeLanguageInit, refresh } from "../redux/actions/cuentaAction";
import LoginScreen from "../screens/login/loginScreen";
import RecoverPassword from "../screens/forgotPassword/RecoverPassword";
import { DashboardRouter } from "./DashboardRouter";
import { PrivateRoute } from "./PrivateRouter";
import { PublicRouter } from "./PublicRouter";
import { IntlProvider } from "react-intl";
import AppLocale from "../lang";
import ForgotPassword from "../screens/forgotPassword/ForgotPassword";
import NewUserResetPassword from "../screens/newUser/NewUserResetPassword";

export const AppRouter = () => {
  const accessToken = useSelector((state: any) => state.cuenta.accessToken);
  const fetchRefresh = useSelector((state: any) => state.cuenta.fetchRefresh);
  // const cuenta = useSelector((state: any) => state.cuenta?.user);

  const userLang = useSelector<any, string>((state) => state.cuenta.lang);

  const currentAppLocale = AppLocale[userLang === "es-ES" ? "es" : "en"];

  const dispatch = useDispatch();

  const refrescar = () => {
    dispatch(refresh());
  };

  const reviewLang = () => {
    if (!localStorage.getItem("userLang")) {
      let lang = localStorage.getItem("userLang")
        ? localStorage.getItem("userLang")
        : navigator.language;
      lang = lang?.slice(0, 2) === "es" ? "es-ES" : "en-US";
      dispatch(changeLanguageInit(lang!));
    }
  };

  const tester = localStorage.getItem("tester") || null;

  console.log("tester router", tester);

  useEffect(() => {
    refrescar();

    // Revisar el idiom
    reviewLang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {fetchRefresh ? (
        <div className="mh__appRouter-load">
          <Spinner cssClass="mh__appRouter-spinner" />
        </div>
      ) : (
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Router>
            <Switch>
              <PublicRouter
                isAutenticated={accessToken ? true : false}
                // isAutenticated={false}
                path="/login"
                component={LoginScreen}
              />
              <PublicRouter
                isAutenticated={accessToken ? true : false}
                // isAutenticated={false}
                path="/recover-password"
                component={RecoverPassword}
              />
              <PublicRouter
                isAutenticated={accessToken ? true : false}
                // isAutenticated={false}
                path="/forgot-password/:hash"
                component={ForgotPassword}
              />
              <PublicRouter
                isAutenticated={accessToken ? true : false}
                // isAutenticated={false}
                path="/new-user/:hash"
                component={NewUserResetPassword}
              />
              <PrivateRoute
                // isAutenticated={true}
                isAutenticated={accessToken ? true : false}
                path={"/"}
                component={DashboardRouter}
              />
            </Switch>
          </Router>
        </IntlProvider>
      )}
    </>
  );
};
