import HeaderScreen from "../../components/header-screens/HeaderScreen";
import iconClick from "../../assets/icons/ri_cursor-line.svg";

import iconDistribucion from "../../assets/icons/ri_coupon-4-line.svg";
import iconGenero from "../../assets/icons/ri_user-line.svg";
import iconEngagement from "../../assets/icons/ri_alarm-warning-line.svg";
import iconDownload from "../../assets/icons/ri_download-line-white.svg";
import iconMore from "../../assets/icons/ri_more-2-fill.svg";
import iconBin from "../../assets/icons/ri_delete-bin-line.svg";
// import EngagementComponent from "./EngagementComponent";
import PaisesComponent from "./paisesComponent";
import CardsReport from "./CardsReport";
import { useSelector } from "react-redux";
import { IReport } from "../../interfaces/report.interface";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import GeneroStatistics from "./GeneroStatistics";
import AgeStatistics from "./AgeStatistics";
import CardsReportEngagement from "./CardsReportEngagement";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import moment from "moment";
import ModalComponent from "../../components/modalComponents/modalComponent";
import ModalDownload from "./modalDownload/ModalDownload";
import DropdownMetrics from "./DropdownMetrics";
import ModalReportDelete from "./modal/modalDelete";

const ReporteSpecifications = () => {
  const history = useHistory();
  const report = useSelector(
    (state: any) => state.reportes.currentReport
  ) as IReport;
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const lang = useSelector((state: any) => state.cuenta.lang);
  const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];
  const [currency, setCurrency] = useState<any>({});
  const [openDrop, setOpenDrop] = useState(false);
  const [metrics, setMetrics] = useState<any>({
    reach: true,
    clicks: true,
    cpc: true,
    ctr: true,
    cpl: false,
    spend: false,
  });
  const [socialSpend, setSocialSpend] = useState<any>({});

  // const { clientName, name: nameCampaign, reportName } = report;
  const openModalDownload = () => {
    // * Mostrar Modal para descargar
    setShowModal(true);
  };
  const checkCurrencySymbol = (curr: string) => {
    let sym = "";
    switch (curr) {
      case "USD":
        sym = "$";
        break;
      case "EUR":
        sym = "€";
        break;
      default:
        break;
    }
    return sym;
  };

  const handleChangeMetrics = (name: string) => {
    if (metrics[name]) {
      setMetrics({
        ...metrics,
        [name]: !metrics[name],
      });
    } else {
      let count = 0;
      Object.values(metrics).forEach((e) => {
        if (e) {
          count++;
        }
      });
      if (count < 4) {
        setMetrics({
          ...metrics,
          [name]: !metrics[name],
        });
      }
    }
    console.log("name");
    console.log(name);
    console.log(metrics);
  };

  const handleReportDelete = () => {
    setShowModalDelete(true);
  };

  useEffect(() => {
    console.log("==> AQUI");
    if (!report) {
      history.push("/reportes");
    } else {
      const {
        stats: { global },
      } = report;
      const sym = checkCurrencySymbol(global.currency);
      setCurrency({
        label: global.currency,
        symbol: sym,
      });
      setSocialSpend({
        percent: (
          (parseFloat(global.social_spend!) * 100) /
          parseFloat(global.spend!)
        ).toFixed(2),
        value: global.social_spend,
      });
    }
  }, []);

  return (
    <>
      <HeaderScreen
        showInput={false}
        title={currentAppLocale.messages["modal.reports.specifications.title"]}
      />
      <div className="ht__reporteSpecifications">
        <div className="ht__reporteSpecifications-description">
          <div className="ht__reporteSpecifications-description-left">
            <h4>{report.reportName}</h4>
            <p className="fecha">
              <IntlMessages id="modal.reports.specifications.ofthe" />{" "}
              <span> {moment(report.dateStart).format("YYYY-MM-DD")} </span>{" "}
              <IntlMessages id="modal.reports.specifications.tothe" />
              <span> {moment(report.dateEnd).format("YYYY-MM-DD")}</span>{" "}
            </p>
            <div className="container-row">
              <div className="container-row-left">
                <div className="cliente">
                  <p className="title">
                    <IntlMessages id="pages.reports.client" />
                  </p>
                  <p className="name">{report.clientName}</p>
                </div>
                <div className="container-ad">
                  <div className="title">
                    <IntlMessages id="modal.reports.specifications.adDelivery" />
                  </div>
                  <div className="status">
                    <div></div>
                    Activa
                  </div>
                </div>
                <div className="container-reportType">
                  <div className="title">
                    <IntlMessages id="modal.reports.page3.reportType" />
                  </div>
                  <div className="type">E-commerce</div>
                </div>
              </div>
              <div className="container-row-right">
                <h4>
                  <IntlMessages id="pages.reports.campaign" />
                </h4>
                <p>{report.name}</p>
              </div>
            </div>
          </div>

          <div className="ht__reporteSpecifications-description-right">
            <div className="container-btns">
              <div
                className="download-btn"
                onClickCapture={() => openModalDownload()}
              >
                <IntlMessages id="modal.reports.specifications.download" />
                <img src={iconDownload} alt="Descargar" />
              </div>
              <div className="menu-btn">
                <img
                  src={iconMore}
                  alt="menu"
                  onClickCapture={handleReportDelete}
                />
                {/* <img
                  src={iconBin}
                  alt="Delete"
                  onClickCapture={handleReportDelete}
                /> */}
              </div>
            </div>
            <div className="containerPresupuesto">
              <p className="title">
                <IntlMessages id="modal.reports.specifications.totalBudget" />
              </p>
              <p className="value">
                {" "}
                <span>{currency.symbol}</span>
                {report.stats?.global?.spend}
              </p>
            </div>
            <div className="containerImporte">
              <p className="title">
                <IntlMessages id="modal.reports.specifications.amountSpent" />{" "}
                {socialSpend.percent}%
              </p>
              <p className="value">
                <span>{currency.symbol}</span>
                {socialSpend.value}
              </p>
            </div>
          </div>
        </div>

        {/* Container de reporte */}
        <div className="ht__reporteSpecifications-reporte">
          <h4>
            <IntlMessages id="modal.reports.specifications.aggregate" />
          </h4>
          {report.stats?.global.clicks ? (
            <CardsReport
              icon={iconClick}
              title={
                currentAppLocale.messages[
                  "modal.reports.specifications.mainResults"
                ]
              }
              global={report.stats.global}
            />
          ) : (
            <></>
          )}
          {report.stats?.engagement?.post_save ? (
            <CardsReportEngagement
              icon={iconEngagement}
              title={
                currentAppLocale.messages[
                  "modal.reports.specifications.engagement"
                ]
              }
              engagement={report.stats?.engagement}
            />
          ) : (
            <></>
          )}
          {/* //* ===> genero y edades */}
          <div className="ht__cardsReport-header-distribution">
            <div className="ht__cardsReport-header-distribution-first">
              <div>
                <img src={iconGenero} alt="icon" />
              </div>
              <IntlMessages id="modal.reports.specifications.distribution" />
            </div>
            <DropdownMetrics
              handleChangeMetrics={handleChangeMetrics}
              metrics={metrics}
              openDrop={openDrop}
              setOpenDrop={setOpenDrop}
            />
          </div>
          {/* //* Component */}
          <GeneroStatistics
            genders={report.stats?.gender}
            currency={currency.label}
            metrics={metrics}
          />
          <AgeStatistics
            metrics={metrics}
            ages={report.stats?.age}
            currency={currency.label}
          />
        </div>
        <div className="ht__reporteSpecifications-paises">
          <div className="ht__cardsReport-header">
            <div>
              <img src={iconDistribucion} alt="countries" />
            </div>
            <IntlMessages id="modal.reports.specifications.distributionCountries" />
          </div>
          <PaisesComponent currency={currency.label} />
        </div>
      </div>

      {showModal ? (
        <ModalComponent
          stateShowDialog={setShowModal}
          clientName={report.clientName}
          reportName={report.reportName}
          socialSpend={socialSpend}
          metrics={metrics}
          component={ModalDownload}
          // classCss="fb-modalclass"
        />
      ) : (
        // <></>
        <></>
      )}
      {showModalDelete ? (
        <ModalComponent
          stateShowDialog={setShowModalDelete}
          component={ModalReportDelete}
          classCss="ht__reportdelete"
          idReport={report._id}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default ReporteSpecifications;
