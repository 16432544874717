import { Provider } from "react-redux";
import { NotificationContainer } from "./components/common/react-notifications";
import { store } from "./redux/store";
import { AppRouter } from "./routers/AppRouter";
import { useGA4React } from "ga-4-react";
function App() {
  const ga = useGA4React();
  console.log(ga);
  return (
    <Provider store={store}>
      <NotificationContainer />
      <AppRouter />
    </Provider>
  );
}

export default App;
