import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderScreen from "../../components/header-screens/HeaderScreen";
import ModalComponent from "../../components/modalComponents/modalComponent";
import { verificarFacebookToken } from "../../helpers";
import { unlinkFacebook } from "../../redux/actions/cuentaAction";
import ModalFBAccountSynced from "../ModalNoFBAccount/ModalFBAccountSynced";
import ModalNoFBAccount from "../ModalNoFBAccount/ModalNoFBAccount";
import InteresSectionComplete from "./interesesCompleto/InteresesSectionComplete";
import InteresesSelected from "./interesesCompleto/InteresesSelected";
import InteresesFind from "./interesesFind/InteresesFind";
import InteresesVacio from "./interesesVacio/InteresesVacio";

const InteresesScreen = () => {
  const { listIntereses, listFindIntereses } = useSelector(
    (state: any) => state.intereses
  );
  const { facebookToken } = useSelector((state: any) => state.cuenta.user);
  const [openModalSynced, setOpenModalSynced] = useState(false);
  const dispatch = useDispatch();

  const verificarToken = async () => {
    const resp = await verificarFacebookToken(facebookToken);
    if (!resp) {
      // TODO: DESVINCULAR CUENTA FB
      console.log("==> Desvinculando cuenta...");
      dispatch(unlinkFacebook());
    }
  };

  useEffect(() => {
    if (facebookToken && facebookToken !== "") {
      verificarToken();
    }
  }, []);

  useEffect(() => {
    if (listIntereses?.length === 0) {
      localStorage.removeItem("ht__myinterests");
    } else {
      if (listIntereses?.length > 0) {
        localStorage.setItem("ht__myinterests", JSON.stringify(listIntereses));
      }
    }
  }, [listIntereses]);

  return (
    <>
      <HeaderScreen
        title="Intereses"
        showInput={true}
        idMesagge="pages.interest"
      />
      <div className="ht_interesesScreen">
        {listIntereses?.length > 0 ? <InteresesSelected /> : <></>}
        {listIntereses?.length > 0 && listFindIntereses?.length > 0 ? (
          <InteresSectionComplete />
        ) : listFindIntereses?.length > 0 ? (
          <InteresesFind />
        ) : (
          <InteresesVacio fromHome={false} />
        )}
      </div>

      {facebookToken === "" ? (
        <ModalComponent
          setOpenModalSynced={setOpenModalSynced}
          component={ModalNoFBAccount}
          classCss="fb-modalclass"
        />
      ) : (
        <></>
      )}
      {openModalSynced ? (
        <ModalComponent
          setOpenModalSynced={setOpenModalSynced}
          component={ModalFBAccountSynced}
          classCss="fb-modalclass"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default InteresesScreen;

// {

// itemTabbar === 0 ? (
// Buscar Intereses
// listIntereses?.length > 0 && listFindIntereses?.length > 0 ? (
//   <InteresSectionComplete />
// ) : listFindIntereses?.length > 0 ? (
//   <InteresesFind />
// ) : (
//   <InteresesVacio />
// )
// ) : (
// Intereses Relacionados
// <InteresesRelacionados />
// )}
