import { useEffect, useState } from "react";
import { PropsRowCountryTable } from "../../interfaces/interface";
import clickIcon from "../../assets/icons/ri_cursor-line_1.svg";
import cpcIcon from "../../assets/icons/ri_money-dollar-circle-line.svg";
import ctrIcon from "../../assets/icons/ri_percent-line.svg";
import costeIcon from "../../assets/icons/ri_creative-commons-nc-line.svg";
import arrowButtom from "../../assets/icons/ri_arrow-right-s-line-blue.svg";
import arrowTop from "../../assets/icons/ri_arrow-top-s-line-blue.svg";
import iconParaguay from "../../assets/icons/icon_paraguay.svg";
import Iconify from "@iconify/iconify";
import { countriesFlags } from "../../constants/constants";

const RowCountryTable = ({
  alcance,
  clicks,
  coste,
  cpc,
  ctr,
  country,
  currency,
}: PropsRowCountryTable) => {
  const [showMenu, setShowMenu] = useState(false);
  const [dataicon, setDataIcon] = useState("");

  useEffect(() => {
    const data = countriesFlags.find((data: any) => data.name === country);
    if (data) {
      setDataIcon(data.dataicon);
    } else {
      setDataIcon("noto:unknown-flag");
    }
  }, []);

  return (
    <div className="ht__rowCountryTable">
      <div>
        <div>
          {/* <img src={iconParaguay} alt="paraguay" /> */}
          <span className="iconify" data-icon={dataicon}></span>
          {country}
        </div>
        <div>{alcance}</div>
        <div>{clicks}</div>
        <div>
          <span>{currency}</span> {parseFloat(cpc).toFixed(4)}
        </div>
        <div>{parseFloat(ctr).toFixed(3)} %</div>
        <div>
          <span>{currency}</span> {coste}
        </div>
        <div>
          {showMenu ? (
            <img
              src={arrowTop}
              alt="Menos"
              onClickCapture={() => setShowMenu(false)}
            />
          ) : (
            <img
              src={arrowButtom}
              alt="Más"
              onClickCapture={() => setShowMenu(true)}
            />
          )}
        </div>
      </div>
      <div
        className={`${
          showMenu
            ? "ht__paisesComponent-table-content-mobile-show"
            : "ht__paisesComponent-table-content-mobile"
        }`}
      >
        <div>
          <div className="header">
            <img src={clickIcon} alt="Click" />
            Clicks
          </div>
          {clicks}
        </div>
        <div>
          <div className="header">
            <img src={cpcIcon} alt="CPC" />
            CPC
          </div>
          <p>
            <span>{currency}</span> {cpc}
          </p>
        </div>
        <div>
          <div className="header">
            <img src={costeIcon} alt="coste" />
            Coste
          </div>
          <p>
            <span>{currency}</span>
            {coste}
          </p>
        </div>
        <div>
          <div className="header">
            <img src={ctrIcon} alt="CTR" />
            CTR
          </div>
          {ctr}%
        </div>
      </div>
    </div>
  );
};

export default RowCountryTable;
