import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import IntlMessages from "../../helpers/IntlMessages";
import { changeItem } from "../../redux/actions/cuentaAction";
import { changeUsingAI } from "../../redux/actions/interesesAction";

export interface IPropsHeaderInput {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const HeaderInputComponent: React.FC<IPropsHeaderInput> = ({
  setShowModal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const usingAI = useSelector((state: any) => state.intereses.usingAI);
  const { role, aiUses, maxAiUses, isGuest, account } = useSelector(
    (state: any) => state.cuenta.user
  );
  const fatherInfo = useSelector((state: any) => state.cuenta.fatherInfo);

  const changeSwitchAI = () => {
    if (account === "proai") {
      dispatch(changeUsingAI(!usingAI));
    } else {
      if (isGuest) {
        if (fatherInfo.account === "proai") {
          dispatch(changeUsingAI(!usingAI));
        } else {
          setShowModal(true);
        }
      } else {
        history.push("/mi-cuenta");
        dispatch(changeItem(2));
      }
    }
  };
  return (
    <div className="ht_header-containerSwitch">
      {/* <img
                  src={helpIcon}
                  alt="Ayuda"
                  title="¿Qué es la inteligencia artificial?"
                /> */}
      <div className="ht_header-containerSwitch-title">
        <h6>
          {" "}
          <IntlMessages id="header.ai" />{" "}
        </h6>
        {role === "admin" || role === "editor" ? (
          <p>
            {aiUses} <IntlMessages id="header.unlimited" />
          </p>
        ) : (
          <p>
            {aiUses} <IntlMessages id="header.aiUses" /> {maxAiUses}{" "}
            <IntlMessages id="header.usesText" />
          </p>
        )}
      </div>
      <div className="ht_header-containerSwitch-switch">
        <label className="switch">
          <input type="checkbox" checked={usingAI} onChange={changeSwitchAI} />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default HeaderInputComponent;
