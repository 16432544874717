import {
    GET_REPORTS,
    GET_REPORTS_ERROR,
    GET_REPORTS_SUCCESS,
    GET_ADACCOUNTS,
    GET_ADACCOUNTS_SUCCESS,
    GET_ADACCOUNTS_ERROR,
    GET_ACCOUNT_CAMPAIGN,
    GET_ACCOUNT_CAMPAIGN_ERROR,
    GET_ACCOUNT_CAMPAIGN_SUCCESS,
    GENERATE_REPORT,
    GENERATE_REPORT_ERROR,
    GENERATE_REPORT_SUCCESS,
    LOGOUT_SUCCESS,
    SELECT_CURRENT_REPORT,
    REPORT_DELETE_SUCCESS
} from "../../types/types";

import {
    ActionModel
} from "../../interfaces/interface";
import {
    IReport
} from "../../interfaces/report.interface";

const initialData = {
    // misReportes: [
    //     "sdas"
    // ],
    misReportes: [],
    fetchAdAccounts: false,
    adAccounts: null,
    errorAdAccounts: null,
    fetchCampaign: false,
    accountCampaigns: null,
    errorAccountCampaign: null,
    fetchGenerateReport: false,
    fetchReports: false,
    currentReport: null
}

export const reportesReducer = (state = initialData, action: ActionModel) => {
    switch (action.type) {
        case GET_ADACCOUNTS:
            return {
                ...state,
                fetchAdAccounts: true,
                errorAdAccounts: null
            }
        case GET_ADACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchAdAccounts: false,
                adAccounts: action.payload,
                errorAdAccounts: null
            }
        case GET_ADACCOUNTS_ERROR:
            return {
                ...state,
                fetchAdAccounts: false,
                errorAdAccounts: "Error al obtener cuentas"
            }
        case GET_ACCOUNT_CAMPAIGN:
            return {
                ...state,
                fetchCampaign: true,
                errorAccountCampaign: null
            }
        case GET_ACCOUNT_CAMPAIGN_SUCCESS:
            return {
                ...state,
                fetchCampaign: false,
                accountCampaigns: action.payload,
                errorAccountCampaign: null
            }
        case GET_ACCOUNT_CAMPAIGN_ERROR:
            return {
                ...state,
                fetchCampaign: false,
                errorAccountCampaign: "Error al obtener cuentas"
            }
        case GENERATE_REPORT:
            return {
                ...state,
                fetchGenerateReport: true
            }
        case GENERATE_REPORT_ERROR:
        case GENERATE_REPORT_SUCCESS:
            return {
                ...state,
                fetchGenerateReport: false
            }
        case GET_REPORTS:
            return {
                ...state,
                fetchReports: true
            }
        case GET_REPORTS_SUCCESS:
            return {
                ...state,
                fetchReports: false,
                misReportes: action.payload
            }
        case GET_REPORTS_ERROR:
            return {
                ...state,
                fetchReports: false,
                misReportes: null
            }
        case SELECT_CURRENT_REPORT:
            return {
                ...state,
                currentReport: state.misReportes.find((report: IReport) => report._id === action.payload)
            }
        case REPORT_DELETE_SUCCESS:
            return {
                ...state,
                currentReport: null,
                misReportes: state.misReportes.filter((report: IReport) => report._id !== action.payload)
            }
        case LOGOUT_SUCCESS:
            return initialData;

        default:
            return state
    }
}

