import { useDispatch } from "react-redux"
import { changeItem } from "../redux/actions/cuentaAction";

export const useMiCuenta = () => {
    const disptach = useDispatch();

    const changeItemTabbar = (item: number) => {
        disptach(changeItem(item));
    }

    return { changeItemTabbar };
}