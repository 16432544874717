import iconarrowbottom from "../../assets/icons/ri_arrow-bottom-s-line.svg";
import { Dispatch, SetStateAction } from "react";

interface IDropdownMetrics {
  setOpenDrop: Dispatch<SetStateAction<boolean>>;
  openDrop: boolean;
  handleChangeMetrics: Function;
  metrics: any;
}

const DropdownMetrics = ({
  setOpenDrop,
  openDrop,
  handleChangeMetrics,
  metrics,
}: IDropdownMetrics) => {
  return (
    <div className="ht__cardsReport-headerdropbox">
      <div
        className="ht__cardsReport-headerdropbox-select"
        onClickCapture={() => setOpenDrop((prev) => !prev)}
      >
        <div>
          <p>Escoger métricas</p>
        </div>
        <img src={iconarrowbottom} alt="more" />
      </div>
      {/* OPTIONS */}
      <div
        className={`ht__cardsReport-headerdropbox-options ${
          openDrop ? "ht__cardsReport-open" : ""
        } `}
      >
        <div>Máximo 4 a la vez</div>
        <div
          className="options-dropbox"
          onClickCapture={() => handleChangeMetrics("cpc")}
        >
          <input
            onChange={() => handleChangeMetrics("cpc")}
            checked={metrics.cpc}
            type="checkbox"
            name="cpc"
          />
          <p>CPC</p>
        </div>
        <div
          className="options-dropbox"
          onClickCapture={() => handleChangeMetrics("ctr")}
        >
          <input
            onChange={() => handleChangeMetrics("ctr")}
            checked={metrics.ctr}
            type="checkbox"
            name="ctr"
          />
          <p>CTR</p>
        </div>
        {/* <div
          className="options-dropbox"
          onClickCapture={() => handleChangeMetrics("cpl")}
        >
          <input checked={metrics.cpl} type="checkbox" name="cpl" />
          <p>CPL</p>
        </div> */}
        <div
          className="options-dropbox"
          onClickCapture={() => handleChangeMetrics("clicks")}
        >
          <input
            onChange={() => handleChangeMetrics("clicks")}
            checked={metrics.clicks}
            type="checkbox"
            name="clicks"
          />
          <p>Clicks</p>
        </div>
        <div
          className="options-dropbox"
          onClickCapture={() => handleChangeMetrics("reach")}
        >
          <input
            onChange={() => handleChangeMetrics("reach")}
            checked={metrics.reach}
            type="checkbox"
            name="reach"
          />
          <p>Alcance</p>
        </div>
        <div
          className="options-dropbox"
          onClickCapture={() => handleChangeMetrics("spend")}
        >
          <input
            onChange={() => handleChangeMetrics("spend")}
            type="checkbox"
            checked={metrics.spend}
            name="spend"
          />
          <p>Coste</p>
        </div>
      </div>
    </div>
  );
};

export default DropdownMetrics;
