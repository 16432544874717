import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderScreen from "../../components/header-screens/HeaderScreen";
import ModalComponent from "../../components/modalComponents/modalComponent";
import { verificarFacebookToken } from "../../helpers";
import { unlinkFacebook } from "../../redux/actions/cuentaAction";
import InteresesVacio from "../Intereses/interesesVacio/InteresesVacio";
import ModalFBAccountSynced from "../ModalNoFBAccount/ModalFBAccountSynced";
import ModalNoFBAccount from "../ModalNoFBAccount/ModalNoFBAccount";

const Home = () => {
  const { facebookToken } = useSelector((state: any) => state.cuenta.user);
  const [openModalSynced, setOpenModalSynced] = useState(false);
  const dispatch = useDispatch();

  const verificarToken = async () => {
    const resp = await verificarFacebookToken(facebookToken);
    if (!resp) {
      // TODO: DESVINCULAR CUENTA FB
      console.log("==> Desvinculando cuenta...");
      dispatch(unlinkFacebook());
    }
  };

  useEffect(() => {
    if (facebookToken && facebookToken !== "") {
      verificarToken();
    }
  }, []);
  return (
    <>
      <HeaderScreen
        title="Intereses"
        showInput={true}
        idMesagge="pages.interest"
      />
      <div className="ht_interesesScreen">
        <InteresesVacio fromHome={true} />
      </div>
      {facebookToken === "" ? (
        <ModalComponent
          setOpenModalSynced={setOpenModalSynced}
          component={ModalNoFBAccount}
          classCss="fb-modalclass"
        />
      ) : (
        <></>
      )}
      {openModalSynced ? (
        <ModalComponent
          setOpenModalSynced={setOpenModalSynced}
          component={ModalFBAccountSynced}
          classCss="fb-modalclass"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Home;
