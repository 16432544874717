// import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { IInterest, ILibrary } from "../../interfaces/library.interface";
import findIcon from "../../assets/icons/ri_search-line.svg";
import { ChangeEvent, useState } from "react";
import IntlMessages from "../../helpers/IntlMessages";

const SingleAudienceListInterest: React.FC<any> = ({
  interestSelected,
  setInteresSelected,
}) => {
  const library = useSelector<any, ILibrary>(
    (state) => state.audiencias.library
  );
  const lang = useSelector((state: any) => state.cuenta.lang);
  const [listFilter, setListFilter] = useState<any>(null);

  const colums = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      omit: true,
    },
    {
      name: lang === "es-ES" ? "Interés" : "interest",
      selector: "name",
      sortable: true,
      wrap: true,
    },
    {
      name: lang === "es-ES" ? "Tamaño" : "Size",
      selector: "size",
      sortable: true,
    },
    {
      name: "Path",
      selector: "path",
      sortable: true,
      wrap: true,
    },
  ];

  const paginacionOpcionesEs = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };
  const paginacionOpcionesEn = {
    rowsPerPageText: "Rows per page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const conditionalRowStyles = [
    {
      when: (row: any) => {
        let filterInteres = interestSelected.filter(
          (i: any) => i.name === row.name
        );
        return filterInteres.length > 0 ? true : false;
      },
      style: {
        backgroundColor: "#f3f3f3",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const handleClick = (e: any) => {
    if (interestSelected.indexOf(e) === -1) {
      setInteresSelected([...interestSelected, e]);
    } else {
      setInteresSelected(
        interestSelected.filter((i: any) => i.name !== e.name)
      );
    }
  };

  const filterSuggestionInterest = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value === "") {
      setListFilter(null);
    } else {
      const tempArray = library?.interests.filter((interest: IInterest) => {
        if (interest?.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
          return interest;
        }
      });
      setListFilter(tempArray);
    }
  };

  return (
    <>
      <div className="ht_containerListIntereses">
        <div className="ht_containerListIntereses-header">
          <p>
            <IntlMessages id="pages.interest.library" />:{" "}
            <span>{library?.name}</span>
            <span className="num-intereses">
              ({library?.interests?.length})
            </span>
          </p>
          <div className="ht_containerListIntereses-header-right">
            <div className="ht_containerListIntereses-list-containerinput">
              <img src={findIcon} alt="Buscar" />
              <input
                className="ht_containerListIntereses-list-input"
                type="text"
                placeholder={
                  lang === "en-US"
                    ? "Find an interest from the table"
                    : "Busca un interés de la tabla"
                }
                onChange={(e) => filterSuggestionInterest(e)}
              />
            </div>
          </div>
        </div>

        <div className="ht_containerListIntereses-list">
          {/* Suggestion Table */}
          <DataTable
            pagination
            paginationPerPage={
              localStorage.getItem("ht__paginationAudience")
                ? parseInt(localStorage.getItem("ht__paginationAudience")!)
                : undefined
            }
            onChangeRowsPerPage={(e) => {
              localStorage.setItem("ht__paginationAudience", e.toString());
            }}
            paginationComponentOptions={
              lang === "es-ES" ? paginacionOpcionesEs : paginacionOpcionesEn
            }
            columns={colums}
            data={
              listFilter ??
              library?.interests.map((i: any) => ({
                ...i,
                size: new Intl.NumberFormat().format(i.size),
              }))
            }
            fixedHeader
            fixedHeaderScrollHeight="600px"
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={handleClick}
          />
        </div>
      </div>
    </>
  );
};
export default SingleAudienceListInterest;
