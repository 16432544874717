import IntlMessages from "../../../helpers/IntlMessages";
import deleteIcon from "../../../assets/icons/delete-icon.svg";
import closeIcon from "../../../assets/icons/ri_close-fill.svg";
import { IPropsDeleteUser } from "../../../interfaces/interface";
import { useDispatch } from "react-redux";
import { deleteGuestUser } from "../../../redux/actions/cuentaAction";

const ModalComponentDelete: React.FC<IPropsDeleteUser> = ({
  stateClose,
  id,
}) => {
  const dispatch = useDispatch();

  const deleteUser = () => {
    dispatch(deleteGuestUser(id, stateClose));
  };

  return (
    <div className="ht__modalDeleteInterest">
      <div className="header">
        <img src={deleteIcon} alt="Eliminar Intereses" />
        <img
          src={closeIcon}
          alt="Cerrar"
          onClickCapture={() => stateClose(false)}
        />
      </div>
      <h4>
        <IntlMessages id="modal.account.deleteuser.title" />
      </h4>
      <p>
        <IntlMessages id="modal.interest.deletemessage" />
      </p>
      <div className="container-btn">
        <div onClickCapture={() => stateClose(false)}>
          <IntlMessages id="modal.interest.deletecancelbtn" />
        </div>
        <div onClickCapture={deleteUser}>
          <IntlMessages id="modal.account.deleteuser.btn" />
        </div>
      </div>
    </div>
  );
};

export default ModalComponentDelete;
