import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ModalComponent from "../../components/modalComponents/modalComponent";
import IntlMessages from "../../helpers/IntlMessages";
import { PropsRowTableContent } from "../../interfaces/interface";
import { getReportInfo } from "../../redux/actions/reportesAction";
import ModalReportDelete from "./modal/modalDelete";

const RowTableContent: React.FC<PropsRowTableContent> = ({
  iconArrowButton,
  iconBin,
  iconDownload,
  iconShop,
  iconArrowTop,
  report,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showCampanias, setShowCampanias] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const navegateToSpecification = () => {
    dispatch(getReportInfo(report._id));
    history.push("/reporte-specification");
  };
  return (
    <>
      <div className="ht__reportesScreen-container-table-content">
        <div>
          {/* <div> */}
          <div onClickCapture={navegateToSpecification}>
            <img src={iconShop} alt="icon" />
            <p>{report.reportName.toUpperCase()}</p>
          </div>
          <div
            className={`${
              showCampanias ? "all-campanias-show" : ""
            } all-campanias`}
          >
            <div className="ht__reportesScreen-container-table-nameMobile">
              <p>Cliente</p>
              <p>{report.clientName}</p>
            </div>

            <h6>
              <IntlMessages id="pages.reports.campaign" />
            </h6>
            <p>{report.name}</p>
            <div className="ht__reportesScreen-container-table-actionsMobile">
              <p>Acciones</p>
              <div>
                <div>
                  <img src={iconDownload} alt="Descargar" />
                  Descargar
                </div>
                <div>
                  <img src={iconBin} alt="Eliminar" />
                  Eliminar
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <div onClickCapture={navegateToSpecification}> */}
          <p>{report.clientName}</p>
        </div>
        {/* <div onClickCapture={navegateToSpecification}>04-11-2021</div> */}
        <div>{moment(report.createdAt).format("YYYY-MM-DD")}</div>
        <div>
          {/* //! DESCOMENTAR PARA DESCARGAR REPORTE */}
          {/* <div>
          <img src={iconDownload} alt="Descargar" />
        </div> */}
          <div>
            <img
              src={iconBin}
              alt="Eliminar"
              onClickCapture={() => setShowModalDelete(true)}
            />
          </div>
        </div>
        <div>
          {showCampanias ? (
            <img
              src={iconArrowTop}
              alt="hide"
              onClickCapture={() => setShowCampanias(false)}
            />
          ) : (
            <img
              src={iconArrowButton}
              alt="More"
              onClickCapture={() => setShowCampanias(true)}
            />
          )}
        </div>
      </div>

      {showModalDelete ? (
        <ModalComponent
          stateShowDialog={setShowModalDelete}
          component={ModalReportDelete}
          classCss="ht__reportdelete"
          idReport={report._id}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default RowTableContent;
