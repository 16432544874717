
import enLang from './entries/en-US';
import esLang from './entries/es-ES';

const AppLocale = {
    en: enLang,
    es: esLang,
};

export default AppLocale;
