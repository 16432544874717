export default {
    // 1.- General 
    "help": "Help",
    "btn.search": "Search",
    "btn.copy": "Copy",
    "btn.export": "Export",
    "btn.import": "Import",
    "label.input.name": "Name",
    "label.input.email": "Email",
    "label.input.password": "Password",
    "label.input.lang": "Language",
    "label.filters": "Filters",
    "label.filtersDescription": "Filter by the following categories of interest:",
    "label.sizeAudience": "Audience size",
    "label.min": "Minimum",
    "label.max": "Maximum",
    "label.categories": "Categories:",
    "label.apply": "Apply",
    "label.cancel": "Cancel",
    "label.nichos": "Niches",
    "label.arcade": "Arcadian",
    "label.add": "Add",
    "label.en": "English",
    "label.es": "Spanish",
    "label.active": "Active",
    "label.disable": "Disabled",
    "placeholder.tableFilter": "Search interests on this table",
    "title.search.interest": "Searching interest...",

    // Header
    "header.ai": "Artificial Intelligence [BETA]",
    "header.aiUses": "of",
    "header.usesText": "uses",
    "header.unlimited": "of unlimited",

    // pages 
    "pages.interest": "Interests",
    "pages.library": "Audience library",
    "pages.reports": "Reports",
    "pages.extension": "Chrome Extension",
    "pages.support": "Support",
    "pages.workspace": "Team",
    "pages.account": "My Account",
    "pages.teamadmin": "Manage team",
    "pages.logout": "Sign out",


    //  interest Page
    "pages.interest.search": "Search interests",
    "pages.interest.copy": "Copy interests",
    "pages.interest.related": "Related interests",
    "pages.interest.emptyDescription": "Enter a keyword to explore interests",
    "pages.interest.placeholder": "Search Interest",
    "pages.interest.import": "Import interests",
    "pages.interest.library": "Library",
    "pages.interest.searchResult": "Search of interests",
    "pages.interest.filter": "Filter interests",
    "pages.interest.realtedTo": "Related with:",
    "pages.interest.suggestion": "Interests suggestions",
    "pages.interest.searchRelatedTo": "Look for interests related to:",
    "pages.interest.listSelected": "List of selected interests",
    "pages.interest.select": "Select interests",
    "pages.interest.selectDescription": "Select two or more interests to see related suggestions",
    "pages.interest.selectEmptyDescription": "Select one or more interests to see related suggestions",
    "pages.interest.noResultsTitle": "No interests found",
    "pages.interest.noResultsDescription": "Try with a different aor less specific keyword",
    "pages.interest.searching": "... Searching for interests",
    "pages.interest.searchingSuggestions": "Getting suggestions...",

    // My Account page
    "pages.account.personalInformation": "Personal Information",
    "pages.account.plans": "Plans",
    "pages.account.fb": "Facebook Account",
    "pages.account.changepass": "Change Password",
    "pages.account.unLinkfb": "Unlink Facebook",
    "pages.account.newPass": "New Password",
    "pages.account.updatedAt": "Updated on",
    "pages.account.messagenofacebook": "The person who invited you does not have a Facebook account associated with it. Ask them to associate one so you can access all the Hello targeting tools.",

    // TeamWorkPage
    "pages.teamwork.subtitle": "Invite your team",
    "pages.teamwork.description": "Invite up to 5 people to be part of your team.",
    "pages.teamwork.notice": "If you need to add more members to your team, contact support to request a ",
    "pages.teamwork.noticespan": "tailored plan",
    "pages.teamwork.btnsend": "Send Invitations",
    "pages.teamwork.title": "My team",
    "pages.teamwork.inviteMember": "Invite member",
    "pages.teamwork.headeremail": "Email",
    "pages.teamwork.headername": "Name",
    "pages.teamwork.headerlastconnection": "Last connection",
    "pages.teamwork.headerstatus": "Status",
    "pages.teamwork.submenudisable": "Disable",
    "pages.teamwork.submenudelete": "Delete",
    "pages.teamwork.submenuedit": "Edit",


    // Reports Page
    "pages.reports.create": "Create Report",
    "pages.reports.generate": "Generate Report",
    "pages.reports.myreports": "My Reports",
    "pages.reports.allclients": "All Clients",
    "pages.reports.initialCreate": "Start by generating a report from your advertising campaigns.",
    "pages.reports.campaign": "Campaigns",
    "pages.reports.client": "Client",
    "pages.reports.date": "Date",
    "pages.reports.actions": "Actions",

    // Single audience
    "pages.audience.titleSizeGraphic": "Average interest size",
    "pages.audience.less100thousand": "Less than 100 thousand",
    "pages.audience.between100m500m": "From 100 thousand to 500 thousand",
    "pages.audience.between500m1mil": "From 500 thousand to 1 million",
    "pages.audience.between1mill2mill": "1 million to 2.5 million",
    "pages.audience.more2mill": "More than 2.5 million",
    "pages.audience.relevantInterests": "Most relevant interests",
    "pages.audience.interestNum": "Number of interestss",

    //Library Page 
    "pages.audience.placeholderFind": "Find an interest from the table",
    "pages.libray.placeholderFind": "Search Audience",

    // Modals
    "modal.facebookaccount.title": "Synchronize your Facebook account",
    "modal.facebookaccount.description": "To access all the features of HelloTargeting you must synchronize your Facebook account.",
    "modal.facebookaccount.syncedText": "Account successfully synced",
    "modal.interest.deletetitle": "Are you sure to eliminate all your interests?",
    "modal.interest.deletemessage": "This action cannot be reversed.",
    "modal.interest.deletecancelbtn": "Cancel",
    "modal.interest.deletebtn": "Remove interests",
    "modal.account.deleteuser.title": "Do you want to delete the user?",
    "modal.account.deleteuser.btn": "Delete user",
    "modal.account.titleGuest": "There is no associated Facebook account",

    // Login Page
    "pages.login.sigin": "Sign In",
    "pages.login.qforgotpass": "¿Forgot your password?",
    "pages.login.messageError": "Failed to login",

    // Forgot password
    "pages.forgot.recover": "Recover password",
    "pages.forgot.btnsend": "Send Link",
    "pages.forgot.returnlogin": "Back to login",


    // Alertas
    "alerts.copied": "Copied to clipboard",
    "alerts.errorcopied": "You don't have hashtags selected. Find one and add it!",
    "alerts.updateUserSuccess": "Your data was updated successfully",
    "alerts.updateUserError": "There was an error updating your details. Try again.",
    "alerts.recoveremptytitle": "There are empty fields",
    "alerts.recoveremptymessage": "Write your email to recover your password",
    "alerts.inputfinderrortitle": "Failed to search interest",
    "alerts.inputfinderrormessage": "There was an error. He tries again.",
    "alerts.inputfindemptyerrortitle": "Error searching interest",
    "alerts.inputfindemptyerrormessage": "Write at least one word",
    "alerts.sendemailerrortitle": "Failed to invite members",
    "alerts.sendemailerrormessage": "Write at least one email",
    "alerts.sendemailsuccessmessage": "Users have been added to your guest list",
    "alerts.addinterestoktitle": "Interests were added to your list",
    "alerts.addinterestokmessage": "Interest Imported Correctly",

    // PLANS
    "pages.account.plans.memberspro": "5 members",
    "pages.account.plans.membersproteam": "of your team",
    // "pages.account.plans.memberspronumsearchs": "300 advanced searches per month, powered by artificial intelligence",
    "pages.account.plans.memberspronumsearchs": "Advanced searches powered by artificial intelligence",
    "pages.account.plans.membersproilimit": "Unlimited Facebook Interests Searches",
    "pages.account.plans.membersproenginesearch": "Advanced search engine for related interests",
    "pages.account.plans.membersprolibrary": "Library with over 100 ready-to-use audiences",
    "pages.account.plans.membersproextension": "Hello Targeting extension for Google Chrome",
    "pages.account.plans.memberspromyplan": "You have plan",
    "pages.account.plans.membersliteAudiences": "Ready-to-use libraries",
    "pages.account.plans.validate": "Valid Plan since",
    "pages.account.plans.validateTo": "until",
    "pages.account.plans.useaitext": "+ use of ai",
    "pages.account.plans.validateaccount": "Your account is valid from",
    "pages.account.plans.upgradeplan": "Upgrade your plan",

    // REPORT MODAL
    "modal.reports.title": "Generate your report",
    "modal.reports.page1.description": "Filter by the following interest categories:",
    "modal.reports.page1.step1": "Step 1 of 3",
    "modal.reports.page1.account": "Account",
    "modal.reports.page1.selectaccount": "Select an account",
    "modal.reports.page1.clientplaceholder": "Name of your client",
    "modal.reports.page1.reportname": "Report name",
    "modal.reports.page1.reportplaceholder": "Name of your report",
    "modal.reports.page1.next": "Next",
    "modal.reports.page.emptymessage": "You need to fill in all the fields to continue",
    "modal.reports.page.emptytitle": "Empty fields",
    "modal.reports.page2.step": "Step 2 of 3",
    "modal.reports.page2.selectcampaign": "Select a campaign",
    "modal.reports.page2.dates": "Dates",
    "modal.reports.page2.startdate": "Start date",
    "modal.reports.page2.endingdate": "Ending date",
    "modal.reports.page2.previous": "Previous",
    "modal.reports.page3.step": "Step 3 of 3",
    "modal.reports.page3.reportType": "Report type",
    "modal.reports.page3.infoSelect": "Select according to your campaign to obtain adapted metrics",
    "modal.reports.page3.plfcatchment": "PLF Catchment",
    "modal.reports.page3.downloadLead": "Download Lead Magnet",
    "modal.reports.page3.directselling": "Direct selling - Tripwire",
    "modal.reports.page3.viewmetrics": "View metrics",
    "modal.reports.page3.metrics.description": "These are the metrics included in this report:",

    // Specifications reports
    "modal.reports.specifications.title": "Report",
    "modal.reports.specifications.ofthe": "Of the",
    "modal.reports.specifications.tothe": "to the",
    "modal.reports.specifications.adDelivery": "Ad delivery",
    "modal.reports.specifications.download": "Download",
    "modal.reports.specifications.totalBudget": "Total budget",
    "modal.reports.specifications.amountSpent": "Amount spent",
    "modal.reports.specifications.aggregate": "Aggregate of all campaigns",
    "modal.reports.specifications.mainResults": "Main results",
    "modal.reports.specifications.frecuency": "Frecuency",
    "modal.reports.specifications.scope": "Scope",
    "modal.reports.specifications.cost": "Cost",
    "modal.reports.specifications.engagement": "Engagement and Interactions",
    "modal.reports.specifications.distribution": "Distribution by gender and age",
    "modal.reports.specifications.gender": "Gender",
    "modal.reports.specifications.male": "Male",
    "modal.reports.specifications.female": "Female",
    "modal.reports.specifications.unknown": "Unknown",
    "modal.reports.specifications.ages": "Ages",
    "modal.reports.specifications.distributionCountries": "Distribution by countries",
    "modal.reports.specifications.country": "Country",
    "modal.reports.specifications.notificationDate": "The selected date cannot be less than the campaign creation date",
    "modal.reports.specifications.notificationnowDate": "The selected date cannot be greater than the current date",


    // Modal download
    "modal.reports.download.title": "Download report",
    "modal.reports.download.introductorymessage": "Include a personalized introductory message for your client",
    "modal.reports.download.messageplaceholder": "Message",
    "modal.reports.download.labelcustomer": "Customer logo (optional)",
    "modal.reports.download.labeltamanio": "The recommended size is 80 x 100 pixels",
    "modal.reports.download.selectfile": "Select a file",
    "modal.reports.download.dropfile": "Drop the file",
    "modal.reports.download.btndownload": "Download report",
    "modal.reports.download.reportlanguage": "Report language",


    // Modal report delete
    "modal.reports.modaldelete.title": "Delete report?",
    "modal.reports.modaldelete.deletebtn": "Delete report",


}
