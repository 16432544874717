import { IPropsModalFB } from "../../../interfaces/interface";
import iconInfo from "../../../assets/icons/icon-modal-fb.svg";
import iconClose from "../../../assets/icons/ri_close-fill.svg";

const ModalLimitUses: React.FC<IPropsModalFB> = ({ setOpenModalSynced }) => {
  const closeModal = () => {
    setOpenModalSynced!(false);
  };
  return (
    <div className="ht__modalNoFbAccount">
      <>
        <div className="ht__modalNoFbAccount-header">
          <div className="ht__modalNoFbAccount-header-icon">
            <img src={iconInfo} alt="Informacion" />
          </div>
          <div className="ht__modal-header-close">
            <img src={iconClose} alt="close" onClickCapture={closeModal} />
          </div>
        </div>
        <div className="ht__modalNoFbAccount-body">
          <h4>Límite alcanzado</h4>
          <p>
            Haz alcanzado el máximo de búsqueda con inteligencia artificial por
            este mes.
          </p>
          <div className="ht__modalLimit-btn" onClickCapture={closeModal}>
            Aceptar
          </div>
        </div>
      </>
    </div>
  );
};

export default ModalLimitUses;
