import clienteAxios from "../../config/axios";
import { IUser } from "../../interfaces/interface";
import { IInterest, ILibrary } from "../../interfaces/library.interface";
import {
    ADD_UNIQ_AUDIENCE,
    CHANGE_CURRENT_AUDIENCIA, GET_LIBRARY_AUDIENCES, GET_LIBRARY_AUDIENCES_SUCESS
} from "../../types/types";
import { store } from "../store";


export const change_current_audiencia = (item: number) => {
    return (dispatch: any) => {
        dispatch({
            type: CHANGE_CURRENT_AUDIENCIA,
            payload: item
        })
    }
}

export const getAllAudiences = () => {
    return async (dispatch: any) => {
        dispatch({
            type: GET_LIBRARY_AUDIENCES
        })

        try {
            const res = await clienteAxios.get(`templates`);
            const user: IUser = store.getState().cuenta.user;
            let audiences = res.data;

            if (user.account === 'lite') {
                audiences = audiences.filter((a: ILibrary) => a.access === 'standar')
            }

            let arrayTemp = audiences.map((audience: ILibrary) => {
                audience.interests = audience.interests.filter(i => i !== null);
                return {
                    ...audience,
                    interests: audience.interests.map((interest: IInterest) => {
                        if (interest) {
                            return {
                                ...interest,
                                path: interest.path.join(">"),
                            }
                        }
                    })
                }
            });

            // const sortArray = arrayTemp.sort(function(a:any,b:any){
            //     return b.size - a.size
            // })
            // console.log("==> ATNES DE ORDENAR");
            // console.log(arrayTemp);

            const arraySort = arrayTemp.map((audience: ILibrary) => {
                const interests = audience.interests;
                return {
                    ...audience,
                    interests: interests.sort(function (a, b) {
                        const bNumber = parseInt(b.size);
                        const aNumber = parseInt(a.size);
                        return bNumber - aNumber;
                    })
                }
            })
            var hashTemp: any[] = []
            const newArray = arraySort?.map((audience: ILibrary) => {
                const interests = audience.interests;
                return {
                    ...audience,
                    interests: interests.filter((interest: any) => {
                        if (!hashTemp.includes(interest.name)) {
                            hashTemp.push(interest.name)
                            return true;
                        } else {
                            return false
                        }
                    })
                }

                // hashTemp[value._id] = true;
                // return element;
            })


            dispatch({
                type: GET_LIBRARY_AUDIENCES_SUCESS,
                payload: newArray
            })
        } catch (error) {
            console.log("Hubo un error");
        }

    }
}

export const addSingleAudience = (library: ILibrary, history: any) => {
    return (dispatch: any) => {
        // library.interests = library.interests.sort(function(a,b){

        //     return b.size - a.size;
        // })
        dispatch({
            type: ADD_UNIQ_AUDIENCE,
            payload: library
        })
        history.push("/single-audiencias");
    }
}

