import { useState } from "react";
import { useSelector } from "react-redux";
import closeIcon from "../../../assets/icons/ri_close-fill.svg";
import IntlMessages from "../../../helpers/IntlMessages";
import { PropsInivtarEquipo } from "../../../interfaces/interface";
import { IStateReportGenerate } from "../../../interfaces/report.interface";
import GenerarPage1 from "./generarPage1";
import GenerarPage2 from "./generarPage2";
import GenerarPage3 from "./generarPage3";

const GenerarReporte: React.FC<PropsInivtarEquipo> = ({ stateClose }) => {
  const [page, setPage] = useState(1);
  const fetchGenerateReport = useSelector(
    (state: any) => state.reportes.fetchGenerateReport
  );
  const [values, setValues] = useState<IStateReportGenerate>({
    campaign: "",
    clientName: "",
    reportName: "",
    date: {
      since: "",
      until: "",
    },
  });

  return (
    <div className="ht__generarReporte">
      <div className="ht__generarReporte-header">
        <div className="title">
          <h4>
            {" "}
            <IntlMessages id="modal.reports.title" />{" "}
          </h4>
          <img
            src={closeIcon}
            alt="Close"
            onClickCapture={() => {
              if (!fetchGenerateReport) {
                stateClose(false);
              }
            }}
          />
        </div>
        <p>
          <IntlMessages id="modal.reports.page1.description" />
        </p>
      </div>
      {page === 1 ? (
        <GenerarPage1
          statePage={setPage}
          setValues={setValues}
          values={values}
        />
      ) : page === 2 ? (
        <GenerarPage2
          setValues={setValues}
          values={values}
          statePage={setPage}
        />
      ) : (
        <GenerarPage3
          values={values}
          statePage={setPage}
          stateClose={stateClose}
          fetchGenerateReport={fetchGenerateReport}
        />
      )}
    </div>
  );
};

export default GenerarReporte;
