import React, { ChangeEvent } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import iconClose from "../../assets/icons/ri_close-fill.svg";
import iconEmail from "../../assets/icons/ri_mail-line.svg";
import { NotificationManager } from "../../components/common/react-notifications";
import { validateEmail } from "../../helpers";
import IntlMessages from "../../helpers/IntlMessages";
import { IUser, PropsInivtarEquipo } from "../../interfaces/interface";
import AppLocale from "../../lang";
import { sendInvitations } from "../../redux/actions/cuentaAction";

const InvitarMiembroModal: React.FC<PropsInivtarEquipo> = ({ stateClose }) => {
  const [emails, setEmails] = useState<any[]>([]);

  const dispatch = useDispatch();

  const teamwork = useSelector<any, IUser[]>((state) => state.cuenta.teamwork);
  const lang = useSelector((state: any) => state.cuenta.lang);
  const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];

  const addToList = (
    e: ChangeEvent<HTMLInputElement> | undefined,
    i: number
  ) => {
    let validEmail = validateEmail(e?.target.value!);
    if (!validEmail) {
    } else {
      let newEmails = [...emails];
      let emaislWithOuti = newEmails.filter((e) => e.i !== i);
      if (emaislWithOuti) {
        emaislWithOuti = [...emaislWithOuti, { i, value: e?.target.value }];
        setEmails(emaislWithOuti);
      } else {
        setEmails([...emails, { i, value: e?.target.value }]);
      }
    }
  };

  const sendInvitationsClick = () => {
    if (emails.length > 0) {
      let newEmails = emails.map((e) => e.value);
      dispatch(sendInvitations(newEmails, stateClose, currentAppLocale));
    } else {
      return NotificationManager.error(
        currentAppLocale.messages["alerts.sendemailerrormessage"],
        currentAppLocale.messages["alerts.sendemailerrortitle"],
        3000,
        null,
        null,
        ""
      );
    }
  };

  const showInputEmails = () => {
    let inputs = [];

    for (let index = 0; index < 5 - teamwork.length; index++) {
      inputs.push(
        <div key={index} className="ht__invitarMiembroModal-input">
          <img src={iconEmail} alt="Correo" />
          <input
            type="email"
            placeholder={currentAppLocale.messages["label.input.email"]}
            key-id={index}
            onChange={(e) => addToList(e, index)}
          />
        </div>
      );
    }

    return inputs;
  };

  return (
    <div className="ht__invitarMiembroModal">
      <div className="title">
        <h4>
          <IntlMessages id="pages.teamwork.subtitle" />
        </h4>
        <img
          src={iconClose}
          alt="Cerrar"
          onClickCapture={() => stateClose(false)}
        />
      </div>
      <p>
        <IntlMessages id="pages.teamwork.description" />
      </p>

      {showInputEmails()}
      <div className="ht__invitarMiembroModal-msg">
        <p>
          <IntlMessages id="pages.teamwork.notice" />{" "}
          <span>
            <IntlMessages id="pages.teamwork.noticespan" />
          </span>
          .
        </p>
      </div>
      <div
        className="ht__invitarMiembroModal-btnEnviar"
        onClickCapture={sendInvitationsClick}
      >
        <IntlMessages id="pages.teamwork.btnsend" />
      </div>
    </div>
  );
};

export default InvitarMiembroModal;
