import ResetPasswordComponent from "../../components/resetPassword/ResetPasswordComponent";
import { useFormPasswordUser } from "../../hooks/useFormPasswordUser";

const NewUserResetPassword = () => {
  const { values, handleChange, onClickSend, handleBlur } = useFormPasswordUser(
    {
      email: "",
      password: "",
      repassword: "",
      error: "",
      // paramans: window.location.pathname.slice(17, -1),
    }
  );

  // window.location.pathname.slice(0, 18);

  // console.log("params", window.location.pathname.slice(17, -1));

  const { email, password, repassword, error } = values;
  return (
    <ResetPasswordComponent
      email={email}
      error={error}
      handleBlur={handleBlur}
      handleChange={handleChange}
      onClickSend={onClickSend}
      password={password}
      repassword={repassword}
      title="Establece tu contraseña"
    />
  );
};

export default NewUserResetPassword;
