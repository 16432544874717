import { useSelector } from "react-redux";
import IntlMessages from "../../helpers/IntlMessages";
import { IUser } from "../../interfaces/interface";
import iconNote from "../../assets/icons/ri_lightbulb-line.svg";
import { useEffect, useState } from "react";
import moment from "moment";

const ContainerPlanProAi = () => {
  const user = useSelector<any, IUser>((state: any) => state.cuenta.user);
  const [dateInit, setDateInit] = useState("");
  const [finalDate, setFinalDate] = useState("");

  const typesAdmitsTeam = useSelector<any, any>(
    (state) => state.cuenta.typesAdmitsTeam
  );

  const changeDatesUsesAi = (dateI: string, dateE: string) => {
    // moment
    const fecha = moment(dateI, "YYYY-MM-DD").toDate();
    const fechaFin = moment(dateE, "YYYY-MM-DD").toDate();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    const monthFin = fechaFin.getMonth() + 1;
    const dayFin = fechaFin.getDate();
    // setDateInit(fecha.toLocaleString());
    setDateInit(
      `${day < 10 ? `0${day}` : day}/${
        month < 10 ? `0${month}` : month
      }/${fecha.getFullYear()}`
    );
    setFinalDate(
      `${dayFin < 10 ? `0${dayFin}` : dayFin}/${
        monthFin < 10 ? `0${monthFin}` : monthFin
      }/${fechaFin.getFullYear()}`
    );
  };

  useEffect(() => {
    if (user.initPlanDate && user.endPlanDate) {
      changeDatesUsesAi(user.initPlanDate, user.endPlanDate);
    }
  }, []);
  return (
    <div className="ht__planes-container-pro">
      {/* <div className="ht__planes-container-ribbon">
        <div className="ribbon">Recomendado</div>
      </div> */}
      <div className="header">
        <p>
          Pro
          <span className="text-ai">AI</span>
        </p>
      </div>
      <div className="body">
        {user.account === "lite" ? (
          <div>
            <p>
              <IntlMessages id="pages.account.plans.membersproenginesearch" />
            </p>
            <p>
              <IntlMessages id="pages.account.plans.membersliteAudiences" />
            </p>
            <p>
              {" "}
              <IntlMessages id="pages.account.plans.membersproextension" />{" "}
            </p>
          </div>
        ) : (
          //***** CUENTAS PRO *****
          <div>
            {/* <p>Acceso ilimitado al software</p> */}
            <p>
              <span>
                <IntlMessages id="pages.account.plans.memberspro" />
              </span>{" "}
              <IntlMessages id="pages.account.plans.membersproteam" />
            </p>
            <p>
              <IntlMessages id="pages.account.plans.memberspronumsearchs" />
            </p>
            <p>
              {" "}
              <IntlMessages id="pages.account.plans.membersproilimit" />{" "}
            </p>
            <p>
              <IntlMessages id="pages.account.plans.membersproenginesearch" />
            </p>
            <p>
              {" "}
              <IntlMessages id="pages.account.plans.membersprolibrary" />{" "}
            </p>
            <p>
              {" "}
              <IntlMessages id="pages.account.plans.membersproextension" />{" "}
            </p>
          </div>
        )}
        {/* {typesAdmitsTeam.includes(user.account) ? ( */}
        <div className="ht__planPro-note">
          <img src={iconNote} alt="Nota" />
          <p className="ht_textuses-relative">
            <IntlMessages id="pages.account.plans.memberspromyplan" /> Pro{" "}
            <span className="text-ai">ai</span>
          </p>
        </div>
        {user.initPlanDate && user.endPlanDate ? (
          <div className="ht__planPro-date">
            <p>
              <IntlMessages id="pages.account.plans.validate" /> {dateInit}{" "}
              <IntlMessages id="pages.account.plans.validateTo" /> {finalDate}
            </p>
          </div>
        ) : (
          <></>
        )}
        {/* ) : (
          // <p>Actualiza a una cuenta pro</p>
          <></>
        )} */}
      </div>
    </div>
  );
};

export default ContainerPlanProAi;
