import clickIcon from "../../assets/icons/ri_cursor-line_1.svg";
import cpcIcon from "../../assets/icons/ri_money-dollar-circle-line.svg";
import ctrIcon from "../../assets/icons/ri_percent-line.svg";
import costeIcon from "../../assets/icons/ri_creative-commons-nc-line.svg";
import arrowBottom from "../../assets/icons/ri_arrow-right-s-line-blue.svg";
import arrowTop from "../../assets/icons/ri_arrow-top-s-line-blue.svg";
import iconParaguay from "../../assets/icons/icon_paraguay.svg";
import RowCountryTable from "./RowCountryTable";
import { useSelector } from "react-redux";
import { IReportsStatsCountry } from "../../interfaces/report.interface";
import IntlMessages from "../../helpers/IntlMessages";

// const arrayTemp = ["sda", "sda", "sda", "sda", "sda"];

interface IPaisesComponent {
  currency: string;
}

const PaisesComponent = ({ currency }: IPaisesComponent) => {
  const countries = useSelector(
    (state: any) => state.reportes.currentReport.stats.countries
  ) as Array<IReportsStatsCountry>;

  return (
    <div className="ht__paisesComponent">
      <div className="ht__paisesComponent-table-header">
        <div>
          <IntlMessages id="modal.reports.specifications.country" />
        </div>
        <div>
          <IntlMessages id="modal.reports.specifications.scope" />
        </div>
        <div>
          <img src={clickIcon} alt="Click" />
          Clicks
        </div>
        <div>
          <img src={cpcIcon} alt="CPC" />
          CPC
        </div>
        <div>
          <img src={ctrIcon} alt="CTR" />
          CTR
        </div>
        <div>
          <img src={costeIcon} alt="coste" />
          <IntlMessages id="modal.reports.specifications.cost" />
        </div>
        <div></div>
      </div>

      <div className="ht__paisesComponent-table-content">
        {countries.map((value) => (
          <RowCountryTable
            alcance={value.reach}
            clicks={value.clicks}
            coste={value.spend}
            cpc={value.cpc}
            ctr={value.ctr}
            country={value.country}
            currency={currency}
          />
        ))}
      </div>
    </div>
  );
};

export default PaisesComponent;
