import { PropsGenerarReportePage3 } from "../../../interfaces/interface";
import grafico from "../../../assets/icons/pasos_3.svg";
import { useDispatch, useSelector } from "react-redux";
import { reportCreate } from "../../../redux/actions/reportesAction";
import Spinner from "../../../components/spinner/Spinner";
import { useState } from "react";
import ModalComponent from "../../../components/modalComponents/modalComponent";
import MetricsPage from "./MetricsPage";
import IntlMessages from "../../../helpers/IntlMessages";
import AppLocale from "../../../lang";

const GenerarPage3: React.FC<PropsGenerarReportePage3> = ({
  statePage,
  values,
  stateClose,
  fetchGenerateReport,
}) => {
  const dispatch = useDispatch();
  const lang = useSelector<any, any>((state) => state.cuenta.lang);
  const [reportType, setReportType] = useState("E-commerce");
  const [showDialog, setShowDialog] = useState(false);
  const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];

  const reportGenerate = () => {
    dispatch(reportCreate(values, stateClose));
  };
  const viewMetrics = () => {
    // if (reportType.length > 0) {
    //   setShowDialog(true);
    // }
    // ! Link aparte de soporte
  };

  return (
    <>
      <div className="ht__generarPage1">
        <p>
          <IntlMessages id="modal.reports.page3.step" />
        </p>
        <div className="ht__generarPage1-grafico">
          <img src={grafico} alt="Grafico 1" />
        </div>
        <h4>
          <IntlMessages id="modal.reports.page3.reportType" />
        </h4>
        <label htmlFor="cuenta" className="ht__generarPage1-label">
          <IntlMessages id="modal.reports.page3.infoSelect" />
        </label>
        <select
          name="cuenta"
          id="cuenta"
          value={reportType}
          onChange={(e) => setReportType(e.target.value)}
        >
          <option value="E-commerce">E-commerce</option>
          <option value="PLF Captacion">
            {currentAppLocale.messages["modal.reports.page3.plfcatchment"]}
            {/* <IntlMessages id="modal.reports.page3.plfcatchment" /> */}
          </option>
          <option value="PLF Rtg">PLF Rtg</option>
          <option value="Webinar funnel evergreen">
            Webinar funnel evergreen
          </option>
          <option value="Nuggets">Nuggets</option>
          <option value="Descarga Lead Magnet">
            {currentAppLocale.messages["modal.reports.page3.downloadLead"]}
            {/* <IntlMessages id="modal.reports.page3.downloadLead" /> */}
          </option>
          <option value="Venta directa - Tripwire">
            {currentAppLocale.messages["modal.reports.page3.directselling"]}
            {/* <IntlMessages id="modal.reports.page3.directselling" /> */}
          </option>
          <option value="Branding/Reach">Branding/Reach</option>
          <option value="Economics">Economics</option>
        </select>

        <div className="ht__generarPage3-metricas">
          <p onClickCapture={viewMetrics}>
            <IntlMessages id="modal.reports.page3.viewmetrics" />
          </p>
        </div>
        <div className="ht__generarPage2-containerBtn">
          <div
            onClickCapture={() => {
              if (!fetchGenerateReport) {
                statePage(2);
              }
            }}
          >
            <IntlMessages id="modal.reports.page2.previous" />
          </div>
          <div className="btn" onClickCapture={reportGenerate}>
            {fetchGenerateReport ? (
              <Spinner cssClass="" />
            ) : (
              <IntlMessages id="pages.reports.generate" />
            )}
          </div>
        </div>
      </div>
      {showDialog ? (
        <ModalComponent
          stateShowDialog={setShowDialog}
          component={MetricsPage}
          titleComponent={reportType}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default GenerarPage3;
