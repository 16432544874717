
export const backendURL = {
    // baseURL: 'http://localhost:9977/',
    // baseURL: 'https://apistaging.hellotargeting.com/',
    baseURL: 'https://api.hellotargeting.com/',
}

export const facebookURL = {
    baseURL: 'https://graph.facebook.com/v11.0/'
}

export const countriesFlags = [
    {
        name: "Ecuador",
        dataicon: "emojione-v1:flag-for-ecuador"
    },
    {
        name: "México",
        dataicon: "emojione-v1:flag-for-mexico"
    },
    {
        name: "Venezuela",
        dataicon: "emojione-v1:flag-for-venezuela"
    },
    {
        name: "Panamá",
        dataicon: "emojione-v1:flag-for-panama"
    },
    {
        name: "Francia",
        dataicon: "emojione-v1:flag-for-francia"
    },
    {
        name: "Paraguay",
        dataicon: "emojione-v1:flag-for-paraguay"
    },
    {
        name: "Estados Unidos",
        dataicon: "emojione-v1:flag-for-united-states"
    },
    {
        name: "España",
        dataicon: "emojione-v1:flag-for-spain"
    },
    {
        name: "China",
        dataicon: "emojione-v1:flag-for-china"
    },
    {
        name: "Brasil",
        dataicon: "emojione-v1:flag-for-Brazil"
    },
    {
        name: "Filipinas",
        dataicon: "emojione-v1:flag-for-philippines"
    },
    {
        name: "Alemania",
        dataicon: "emojione-v1:flag-for-germany"
    },
    {
        name: "Japón",
        dataicon: "emojione-v1:flag-for-japan"
    },
    {
        name: "Francia",
        dataicon: "emojione-v1:flag-for-france"
    },
    {
        name: "Italia",
        dataicon: "emojione-v1:flag-for-italy"
    },
    {
        name: "Colombia",
        dataicon: "emojione-v1:flag-for-colombia"
    },
    {
        name: "Argentina",
        dataicon: "emojione-v1:flag-for-argentina"
    },
    {
        name: "Canadá",
        dataicon: "emojione-v1:flag-for-canada"
    },
    {
        name: "Perú",
        dataicon: "emojione-v1:flag-for-peru"
    },
    {
        name: "Chile",
        dataicon: "emojione-v1:flag-for-chile"
    },
    {
        name: "Guatemala",
        dataicon: "emojione-v1:flag-for-guatemala"
    },
    {
        name: "Bolivia",
        dataicon: "emojione-v1:flag-for-bolivia"
    },
    {
        name: "Cuba",
        dataicon: "emojione-v1:flag-for-cuba"
    },
    {
        name: "República Dominicana",
        dataicon: "emojione-v1:flag-for-dominican-republic"
    },
    {
        name: "Portugal",
        dataicon: "emojione-v1:flag-for-portugal"
    },
    {
        name: "Costa Rica",
        dataicon: "emojione-v1:flag-for-costa-rica"
    },
    {
        name: "Uruguay",
        dataicon: "emojione-v1:flag-for-uruguay"
    },

];

export const engagementLabels = [
    {
        metric: "post_save",
        label: "Publicaciones guardadas",
        labelE: "Saved post"
    },
    {
        metric: "link_click",
        label: "Clicks en link",
        labelE: "Link clicks"
    },
    {
        metric: "post_reaction",
        label: "Reacciones en el post",
        labelE: "Post reaction"
    },
    {
        metric: "comment",
        label: "Comentarios",
        labelE: "Comments"
    },
    {
        metric: "fb_pixel_complete_registration",
        label: "Registro completo de píxeles",
        labelE: "Saved Posts Full Pixel Record"
    },
    {
        metric: "fb_pixel_view_content",
        label: "Contenido de visualización de píxeles",
        labelE: "Pixel display content"
    },
    {
        metric: "landing_page_view",
        label: "Vistas en landing page",
        labelE: "Landing page views"
    },
    {
        metric: "post_engagement",
        label: "Engagement del post",
        labelE: "Post engagement"
    },
    {
        metric: "page_engagement",
        label: "Engagement de la página",
        labelE: "Page engagement"
    },
    {
        metric: "omni_complete_registration",
        label: "Registros completados",
        labelE: "Registrations Completed"
    },
    {
        metric: "omni_view_content",
        label: "Vistas de contenido",
        labelE: "Content Views"
    },
    {
        metric: "fb_pixel_custom",
        label: "Eventos de píxeles personalizados definidos por el anunciante",
        labelE: "Custom pixel events defined by the advertiser"
    },
    {
        metric: "fb_pixel_initiate_checkout",
        label: "Píxel iniciar pago",
        labelE: "Pixel initiate checkout"
    },
    {
        metric: "fb_pixel_purchase",
        label: "Compras",
        labelE: "Purchases"
    },
    {
        metric: "omni_initiated_checkout",
        label: "Pagos iniciados",
        labelE: "Checkouts Initiated"
    },
    {
        metric: "lead",
        label: "Lead",
        labelE: "Lead"
    },
    {
        metric: "landing_page_view",
        label: "Vistas de la Landing Page",
        labelE: "Landing page view"
    },
    {
        metric: "post",
        label: "Publicaciones",
        labelE: "Posts"
    },

]

export const reportMetrics = [
    {
        name: "E-commerce",
        metrics: [
            {
                label: "Presupuesto",
                metric: ""
            },
            {
                label: "Importe Gastado",
                metric: ""
            },
            {
                label: "Resultado",
                metric: ""
            },
            {
                label: "Coste por resultado",
                metric: ""
            },
            {
                label: "Impresiones",
                metric: ""
            },
            {
                label: "Alcance",
                metric: ""
            },
            {
                label: "Frecuencia",
                metric: ""
            },
            {
                label: "CPM",
                metric: ""
            },
            {
                label: "CTR Saliente único",
                metric: ""
            },
            {
                label: "Clic Salientes únicos",
                metric: ""
            },
            {
                label: "Coste por click saliente único",
                metric: ""
            },
            {
                label: "Visitas a la página de destino",
                metric: ""
            },
            {
                label: "Coste por inicio de pago",
                metric: ""
            },
            {
                label: "Compras",
                metric: ""
            },
            {
                label: "Coste por compra",
                metric: ""
            },
            {
                label: "ROAS",
                metric: ""
            },
            {
                label: "Visualizaciones de contenido",
                metric: ""
            },
            {
                label: "Coste por visualización de contenido*",
                metric: ""
            },
            {
                label: "Artículos añadidos al carrito",
                metric: ""
            },
            {
                label: "Coste por artículo añadido al carrito",
                metric: ""
            },
            {
                label: "Valor de conversión de compra",
                metric: ""
            },
            {
                label: "Coste por visita a la página de destino",
                metric: ""
            },
        ]
    },
    {
        name: "PLF Captación",
        metrics: [
            {
                label: "Nombre de la campaña",
                metric: ""
            },
            {
                label: "Entrega",
                metric: ""
            },
            {
                label: "Estrategia de puja",
                metric: ""
            },
            {
                label: "Presupuesto",
                metric: ""
            },
            {
                label: "Importe gastado",
                metric: ""
            },
            {
                label: "Configuración de Atribución",
                metric: "action_attribution_windows"
            },
            {
                label: "Resultado",
                metric: ""
            },
            {
                label: "Coste por resultado",
                metric: "cost_per_conversion"
            },
            {
                label: "Impresiones",
                metric: ""
            },
            {
                label: "Alcance",
                metric: ""
            },
            {
                label: "Frecuencia",
                metric: ""
            },
            {
                label: "CPM",
                metric: ""
            },
            {
                label: "CTR Saliente único",
                metric: ""
            },
            {
                label: "Clic salientes únicos",
                metric: ""
            },
            {
                label: "Visitas a la página de destino",
                metric: ""
            },
            {
                label: "Clientes potenciales",
                metric: ""
            },
            {
                label: "Coste por clientes potenciales",
                metric: ""
            },
            {
                label: "Registro completado",
                metric: ""
            },
            {
                label: "Coste por registro completado",
                metric: ""
            },
            {
                label: "Coste por visita a la página de destino",
                metric: ""
            },
            {
                label: "Porcentaje de rebote",
                metric: ""
            },
            {
                label: "% landing",
                metric: ""
            },
        ]
    },
    {
        name: "PLF Rtg",
        metrics: [
            {
                label: "Nombre de la campaña",
                metric: ""
            },
            {
                label: "Entrega",
                metric: ""
            },
            {
                label: "Estrategia de puja",
                metric: ""
            },
            {
                label: "Presupuesto",
                metric: ""
            },
            {
                label: "Importe gastado",
                metric: ""
            },
            {
                label: "Configuración de Atribución",
                metric: "action_attribution_windows"
            },
            {
                label: "Resultado",
                metric: ""
            },
            {
                label: "Coste por resultado",
                metric: "cost_per_conversion"
            },
            {
                label: "Impresiones",
                metric: ""
            },
            {
                label: "Alcance",
                metric: ""
            },
            {
                label: "Frecuencia",
                metric: ""
            },
            {
                label: "CPM",
                metric: ""
            },
            {
                label: "CTR Saliente único",
                metric: ""
            },
            {
                label: "Clic salientes únicos",
                metric: ""
            },
            {
                label: "Visitas a la página de destino",
                metric: ""
            },
            {
                label: "Inicio de pago",
                metric: ""
            },
            {
                label: "Coste por inicio de pago",
                metric: ""
            },
            {
                label: "Compras",
                metric: ""
            },
            {
                label: "ROAS",
                metric: ""
            },
            {
                label: "Coste por visita a la página de destino",
                metric: ""
            },
            {
                label: "Porcentaje de rebote",
                metric: ""
            },

        ]
    },
    {
        name: "Webinar funnel evergreen",
        metrics: [
            {
                label: "Nombre de la campaña",
                metric: ""
            },
            {
                label: "Entrega",
                metric: ""
            },
            {
                label: "Estrategia de puja",
                metric: ""
            },
            {
                label: "Presupuesto",
                metric: ""
            },
            {
                label: "Importe gastado",
                metric: ""
            },
            {
                label: "Configuración de Atribución",
                metric: "action_attribution_windows"
            },
            {
                label: "Resultado",
                metric: ""
            },
            {
                label: "Coste por resultado",
                metric: "cost_per_conversion"
            },
            {
                label: "Impresiones",
                metric: ""
            },
            {
                label: "Alcance",
                metric: ""
            },
            {
                label: "Frecuencia",
                metric: ""
            },
            {
                label: "CPM",
                metric: ""
            },
            {
                label: "CTR Saliente único",
                metric: ""
            },
            {
                label: "Clic Salientes únicos",
                metric: ""
            },
            {
                label: "Coste por click saliente único",
                metric: ""
            },
            {
                label: "Visitas a la página de destino",
                metric: ""
            },
            {
                label: "Clientes potenciales",
                metric: ""
            },
            {
                label: "Coste por clientes potenciales",
                metric: ""
            },
            {
                label: "Registro completado",
                metric: ""
            },
            {
                label: "Coste por registro completado",
                metric: ""
            },
            {
                label: "Inicio de pago",
                metric: ""
            },
            {
                label: "Coste por inicio de pago",
                metric: ""
            },
            {
                label: "Compras",
                metric: ""
            },
            {
                label: "Coste por compra",
                metric: ""
            },
            {
                label: "ROAS",
                metric: ""
            },
            {
                label: "Visitas al Webinar",
                metric: ""
            },
            {
                label: "Valor de compra",
                metric: ""
            },
            {
                label: "Coste por visita a la página de destino",
                metric: ""
            },
            {
                label: "Porcentaje de rebote",
                metric: ""
            },
            {
                label: "Visitas a la página de ventas",
                metric: ""
            },
            {
                label: "% landing",
                metric: ""
            },

        ]
    },
    {
        name: "Nuggets",
        metrics: [
            {
                label: "Importe Gastado",
                metric: ""
            },
            {
                label: "Impresiones",
                metric: ""
            },
            {
                label: "Alcance",
                metric: ""
            },
            {
                label: "Frecuencia",
                metric: ""
            },
            {
                label: "CPM",
                metric: ""
            },
            {
                label: "ThruPlays",
                metric: ""
            },
            {
                label: "Coste por ThruPlays",
                metric: "cost_per_thruplay"
            },
            {
                label: "Reproducciones de vIdeo",
                metric: ""
            },
            {
                label: "Tiempo medio de reproducción de vídeo",
                metric: ""
            },
            {
                label: "Reproducciones de vídeo hasta el 25%",
                metric: ""
            },
            {
                label: "Reproducciones de vídeo hasta el 50%",
                metric: ""
            },
            {
                label: "Reproducciones de vídeo hasta el 75%",
                metric: ""
            },
            {
                label: "Compartir la publicación",
                metric: ""
            },
            {
                label: "Guardar la publicación",
                metric: ""
            },
            {
                label: "Comentario en la publicación",
                metric: ""
            },
        ]
    },
    {
        name: "Descarga Lead Magnet",
        metrics: [
            {
                label: "Presupuesto",
                metric: ""
            },
            {
                label: "Importe Gastado",
                metric: ""
            },
            {
                label: "Impresiones",
                metric: ""
            },
            {
                label: "Alcance",
                metric: ""
            },
            {
                label: "Frecuencia",
                metric: ""
            },
            {
                label: "CPM",
                metric: ""
            },
            {
                label: "CTR Saliente único",
                metric: ""
            },
            {
                label: "Clic Salientes únicos",
                metric: ""
            },
            {
                label: "Coste por click saliente único",
                metric: ""
            },
            {
                label: "Visitas a la página de destino",
                metric: ""
            },
            {
                label: "Tasa de conversión lead",
                metric: ""
            },
            {
                label: "Coste por visita a la página de destino",
                metric: ""
            },
            {
                label: "Lead",
                metric: ""
            },
            {
                label: "Coste por Lead",
                metric: ""
            },
        ]
    },
    {
        name: "Venta directa - Tripwire",
        metrics: [
            {
                label: "Presupuesto",
                metric: ""
            },
            {
                label: "Importe gastado",
                metric: ""
            },
            {
                label: "Resultado",
                metric: ""
            },
            {
                label: "Coste por resultado",
                metric: "cost_per_conversion"
            },
            {
                label: "Impresiones",
                metric: ""
            },
            {
                label: "Alcance",
                metric: ""
            },
            {
                label: "Frecuencia",
                metric: ""
            },
            {
                label: "CPM",
                metric: ""
            },
            {
                label: "CTR Saliente único",
                metric: ""
            },
            {
                label: "Clic Salientes únicos",
                metric: ""
            },
            {
                label: "Coste por click saliente único",
                metric: ""
            },
            {
                label: "Visitas a la página de destino",
                metric: ""
            },
            {
                label: "Inicio de pago",
                metric: ""
            },
            {
                label: "Coste por inicio de pago",
                metric: ""
            },
            {
                label: "Compras",
                metric: ""
            },
            {
                label: "Coste por compra",
                metric: ""
            },
            {
                label: "ROAS",
                metric: ""
            },
            {
                label: "Valor de conversión de compra",
                metric: ""
            },
            {
                label: "Coste por visita a la página de destino",
                metric: ""
            },
        ]
    },
    {
        name: "Branding/Reach",
        metrics: [
            {
                label: "Entrega",
                metric: ""
            },
            {
                label: "Presupuesto",
                metric: ""
            },
            {
                label: "Importe Gastado",
                metric: ""
            },
            {
                label: "Configuración de Atribución",
                metric: "action_attribution_windows"
            },
            {
                label: "Resultado",
                metric: ""
            },
            {
                label: "Coste por resultado",
                metric: "cost_per_conversion"
            },
            {
                label: "Impresiones",
                metric: ""
            },
            {
                label: "Alcance",
                metric: ""
            },
            {
                label: "Frecuencia",
                metric: ""
            },
            {
                label: "CPM",
                metric: ""
            },
            {
                label: "Guardar la publicación",
                metric: ""
            },
            {
                label: "Comentario en la publicación",
                metric: ""
            },
            {
                label: "Clic en el enlace",
                metric: ""
            },
            {
                label: "clic (Todos)",
                metric: ""
            },
            {
                label: "CPC",
                metric: ""
            },
            {
                label: "Cuantas veces se ha compartido la publicación",
                metric: ""
            },
            {
                label: "Me gustas de la página",
                metric: ""
            },
            {
                label: "CTR (Todos)",
                metric: ""
            },
        ]
    },
    {
        name: "Economics",
        metrics: [
            {
                label: "Importe Gastado",
                metric: ""
            },
            {
                label: "Compras",
                metric: ""
            },
            {
                label: "Coste por compra",
                metric: ""
            },
            {
                label: "ROAS",
                metric: ""
            },
            {
                label: "Valor de conversión de compra",
                metric: ""
            },
        ]
    },
]

