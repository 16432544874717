import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "../../helpers/IntlMessages";
import { PropsHeaderScreens } from "../../interfaces/interface";
import { changeUsingAI } from "../../redux/actions/interesesAction";
// import helpIcon from "../../assets/icons/ri_question-line-ai.svg";
import { useEffect, useState } from "react";
import ModalComponent from "../modalComponents/modalComponent";
import ModalLimitUses from "./ModalLimitUses/ModalLimitUses";
// import { userInfo } from "os";
// import { useHistory } from "react-router";
// import { changeItem } from "../../redux/actions/cuentaAction";
import HeaderInputComponent from "./HeaderInputComponent";

const HeaderScreen: React.FC<PropsHeaderScreens> = ({
  title,
  showInput,
  idMesagge,
}) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  // const usingAI = useSelector((state: any) => state.intereses.usingAI);
  const { role, aiUses, isGuest, aditionPlan, account, maxAiUses } =
    useSelector((state: any) => state.cuenta.user);
  const fatherInfo = useSelector((state: any) => state.cuenta.fatherInfo);

  const verifyAIUses = () => {
    dispatch(changeUsingAI(false));
  };

  useEffect(() => {
    if (aiUses! >= maxAiUses && role !== "admin") {
      if (isGuest) {
        if (fatherInfo.account !== "proai") {
          verifyAIUses();
        }
      } else {
        if (account !== "proai") {
          verifyAIUses();
        }
      }
    } else {
      if (account !== "proai") {
        verifyAIUses();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiUses]);
  // console.log(isGuest);
  // console.log(fatherAccount);

  return (
    <>
      <div className="ht_header">
        <div>
          <p>{idMesagge ? <IntlMessages id={idMesagge} /> : title}</p>
        </div>
        {showInput ? (
          // typesAdmits.includes(account) || isGuest ? (
          isGuest ? (
            fatherInfo.account === "proai" ? (
              <HeaderInputComponent setShowModal={setShowModal} />
            ) : fatherInfo.aditionPlan ? (
              <HeaderInputComponent setShowModal={setShowModal} />
            ) : (
              <></>
            )
          ) : (
            <HeaderInputComponent setShowModal={setShowModal} />
          )
        ) : (
          // ) : (
          //   <></>
          // )
          <></>
        )}
      </div>

      {showModal ? (
        <ModalComponent
          setOpenModalSynced={setShowModal}
          component={ModalLimitUses}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default HeaderScreen;
