import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { NotificationManager } from "../../components/common/react-notifications";
import clienteAxios from "../../config/axios";
import { facebookURL } from "../../constants/constants";
import {
    GET_REPORTS,
    GET_REPORTS_ERROR,
    GET_REPORTS_SUCCESS,
    GET_ADACCOUNTS,
    GET_ADACCOUNTS_SUCCESS,
    GET_ADACCOUNTS_ERROR,
    GET_ACCOUNT_CAMPAIGN,
    GET_ACCOUNT_CAMPAIGN_ERROR,
    GET_ACCOUNT_CAMPAIGN_SUCCESS,
    GENERATE_REPORT,
    GENERATE_REPORT_ERROR,
    GENERATE_REPORT_SUCCESS,
    SELECT_CURRENT_REPORT,
    REPORT_DELETE,
    REPORT_DELETE_ERROR,
    REPORT_DELETE_SUCCESS
} from "../../types/types";
import { store } from "../store";


export const getreports = () => {
    return async (dispatch: any) => {
        dispatch({
            type: GET_REPORTS
        })
        try {
            const resp = await clienteAxios.get("reports",
                {
                    params: {
                        perPage: 20,
                        page: 1
                    },
                    withCredentials: true
                }
            );
            dispatch({
                type: GET_REPORTS_SUCCESS,
                payload: resp.data.reports
            })

        } catch (error) {
            console.log("==> ERROR AL OBTENER REPORTES");
            console.log(error);
            dispatch({
                type: GET_REPORTS_ERROR,
            })

        }
    }
}

export const obtenerCuentas = () => {
    return async (dispatch: any) => {
        dispatch({
            type: GET_ADACCOUNTS
        })
        try {
            const state = store.getState();

            const resp = await axios.get(`${facebookURL.baseURL}me/adaccounts`, {
                params: {
                    access_token: state.cuenta.user.facebookToken,
                    fields: 'name,account_status'
                }
            })
            console.log(resp.data);
            dispatch({
                type: GET_ADACCOUNTS_SUCCESS,
                payload: resp.data.data
            })



        } catch (error) {
            console.log("===> ERROR AL OBTENER CUENTAS");
            console.log(error);
            dispatch({
                type: GET_ADACCOUNTS_ERROR
            })
        }
    }
}

export const obtenerCampaign = (idCuenta: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: GET_ACCOUNT_CAMPAIGN
        });

        try {
            const state = store.getState();
            const resp = await axios.get(`${facebookURL.baseURL}${idCuenta}/campaigns`, {
                params: {
                    access_token: state.cuenta.user.facebookToken,
                    fields: 'name, created_time, start_time,status,updated_time '
                }
            });
            dispatch({
                type: GET_ACCOUNT_CAMPAIGN_SUCCESS,
                payload: resp.data.data
            })


        } catch (error) {
            console.log("===> ERROR AL OBTENER CAMPAÑAS");
            console.log(error);
            dispatch({
                type: GET_ACCOUNT_CAMPAIGN_ERROR,
            })
        }
    }
}

export const reportCreate = (values: any, statePage: Dispatch<SetStateAction<boolean>>) => {
    return async (dispatch: any) => {
        dispatch({
            type: GENERATE_REPORT
        })
        console.log("%cvalues", "color:red");
        console.log(values);

        try {
            await clienteAxios.post("reports", values);
            dispatch(getreports())
            NotificationManager.success(
                "Tu reporte se generó con éxito",
                "Reporte generado",
                2000,
                null,
                "",
                ""
            );
            statePage(false);
            dispatch({
                type: GENERATE_REPORT_SUCCESS
            })
        } catch (error) {
            console.log("===> Error al crear reporte ");
            console.log(error);
            NotificationManager.error(
                "No se pudo generar tu reporte. Intentalo de nuevo",
                "Error al generar reporte",
                2000,
                null,
                "",
                ""
            );
            dispatch({
                type: GENERATE_REPORT_ERROR
            })
        }
    }
}

export const getReportInfo = (id: string) => {
    return async (dispatch: any) => {
        try {
            // const resp = await clienteAxios.get(`reports/${id}`);
            // console.log("INFO DE REPORTE");
            // console.log(resp.data);
            dispatch({
                type: SELECT_CURRENT_REPORT,
                payload: id
            });
        } catch (error) {
            console.log("Error la obtener información del reporte");
            console.log(error);


        }
    }
}

export const deleteReportBD = (id: string, setFetch: Dispatch<SetStateAction<boolean>>, setDialog: Dispatch<SetStateAction<boolean>>, history: any) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: REPORT_DELETE
        })
        setFetch(true);
        try {
            const resp = await clienteAxios.delete(`reports/${id}`, {
                withCredentials: true
            });
            console.log("==> ");

            setFetch(false);
            dispatch({
                type: REPORT_DELETE_SUCCESS,
                payload: id
            });
            NotificationManager.success("Reporte eliminado con éxito", "", 3000, null, null, "");
            setDialog(false)
            // history.push("/reportes");
        } catch (error) {
            console.log("===> Error al eliminar reporte");
            console.log(error);
            console.log(error.response);
            setFetch(false);
            dispatch({
                type: REPORT_DELETE_ERROR
            })
            NotificationManager.error("Error al eliminar reporte", "Error", 3000, null, null, "");
        }
    }
}