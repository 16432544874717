import { useSelector } from "react-redux";
import { engagementLabels } from "../../constants/constants";
import AppLocale from "../../lang";
// import { PropsCardsEngagementReport } from "../../interfaces/interface";
import CardsReportSingle from "./CardsReportSingle";

const CardsReportEngagement: React.FC<any> = ({ title, icon, engagement }) => {
  const lang = useSelector((state: any) => state.cuenta.lang);
  // const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];

  return (
    <div className="ht__cardsReport">
      <div className="ht__cardsReport-header">
        <div>
          <img src={icon} alt="icon" />
        </div>
        {title}
      </div>
      <div className="ht__cardsReport-container">
        {Object.keys(engagement).map((e, i) => {
          if (i !== 0) {
            const label = engagementLabels.find((value) => value.metric === e);
            return (
              <CardsReportSingle
                title={lang === "es-ES" ? label?.label : label?.labelE}
                value={engagement[e].value}
              />
            );
          } else {
            return <></>;
          }
        })}
      </div>
    </div>
  );
};

export default CardsReportEngagement;
