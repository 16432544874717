import { useSelector } from "react-redux";
import { PropsCardsReport } from "../../interfaces/interface";
import CardsReportSingle from "./CardsReportSingle";
import AppLocale from "../../lang";

const CardsReport: React.FC<PropsCardsReport> = ({ title, icon, global }) => {
  const global2: any = global;
  const lang = useSelector((state: any) => state.cuenta.lang);
  const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];
  const metricsGlobal = [
    {
      key: "clicks",
      title: "Clicks",
    },
    {
      key: "cpc",
      title: "CPC",
    },

    {
      key: "cpl",
      title: "CPL",
    },
    {
      key: "ctr",
      title: "CTR",
    },
    {
      key: "frequency",
      title:
        currentAppLocale.messages["modal.reports.specifications.frecuency"],
    },
    {
      key: "reach",
      title: currentAppLocale.messages["modal.reports.specifications.scope"],
    },
    {
      key: "spend",
      title: currentAppLocale.messages["modal.reports.specifications.cost"],
    },
  ];

  return (
    <div className="ht__cardsReport">
      <div className="ht__cardsReport-header">
        <div>
          <img src={icon} alt="icon" />
        </div>
        {title}
      </div>
      <div className="ht__cardsReport-container">
        {metricsGlobal.map((value: any) => (
          <CardsReportSingle title={value.title} value={global2[value.key]} />
        ))}
        {/* {global?.clicks ? (
          <CardsReportSingle title="Clicks" value={global.clicks} />
        ) : (
          <></>
        )}
        {global?.cpc ? (
          <CardsReportSingle title="CPC" value={global.cpc} />
        ) : (
          <></>
        )}
        {global?.cpl ? (
          <CardsReportSingle title="CPL" value={global.cpl.toString()} />
        ) : (
          <></>
        )}
        {global?.ctr ? (
          <CardsReportSingle title="CTR" value={global.ctr} />
        ) : (
          <></>
        )}
        {global?.frequency ? (
          <CardsReportSingle
            title={
              currentAppLocale.messages[
                "modal.reports.specifications.frecuency"
              ]
            }
            value={global.frequency}
          />
        ) : (
          <></>
        )}
        {global?.reach ? (
          <CardsReportSingle
            title={
              currentAppLocale.messages["modal.reports.specifications.scope"]
            }
            value={global.reach}
          />
        ) : (
          <></>
        )}
        {global?.spend ? (
          <CardsReportSingle
            title={
              currentAppLocale.messages["modal.reports.specifications.cost"]
            }
            value={global.spend}
          />
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
};

export default CardsReport;
