import { Dispatch, SetStateAction } from "react";
import clienteAxios from "../../config/axios";
import { InterestFindInterface } from "../../interfaces/interface";
import {
    CHANGE_ITEM_TABBAR_INTERESES,
    SEARCH_INTEREST,
    SEARCH_INTEREST_ERROR,
    SEARCH_INTEREST_SUCCESS,
    ADD_INTEREST_TO_LIST,
    ADD_INTEREST_TO_SUGGESTION_LIST,
    GET_SUGGESTIONS,
    GET_SUGGESTIONS_ERROR,
    GET_SUGGESTIONS_SUCCESS,
    DELETE_INTEREST_TO_LIST,
    DELETE_ALL_INTEREST_TO_LIST,
    IMPLEMENT_INTEREST_FILTER,
    ADD_INTEREST_IMPORTED_TO_LIST,
    CHANGE_SEARCH_LANGUAGE,
    CHANGE_USINGAI,
    DELETE_SUGGESTION_TO_LIST,
    FILTER_RESET,
    UPDATE_KEYWORD
} from "../../types/types";
import { store } from "../store";

export const change_item = (item: number) => {
    return (dispatch: any) => {
        dispatch({
            type: CHANGE_ITEM_TABBAR_INTERESES,
            payload: item
        })
    }
}


export const addInterest = (value: any, addNotification: Function, listIntereses?: Array<any>) => {
    return (dispatch: any) => {
        dispatch(
            {
                type: ADD_INTEREST_TO_LIST,
                payload: value
            }
        )
        if (listIntereses?.length === 0) {
            addNotification();
        }
    }
}
export const addToSuggestionList = (value: any, listSuggestion: Array<any>) => {
    return (dispatch: any) => {
        try {
            const length = listSuggestion?.length;
            const list = listSuggestion.filter((interest: InterestFindInterface) => value.id !== interest.id)
            if (list?.length === length) {
                list.push(value)
            }
            dispatch({
                type: ADD_INTEREST_TO_SUGGESTION_LIST,
                payload: list
            })
        } catch (error) {
            console.log("===> ERROR EN addToSuggestionList");
            console.log(error);
        }
    }
}

export const addInterestImported = (value: any, addNotification: any, userLang: string, history?: any) => {
    return (dispatch: any) => {
        dispatch(
            {
                type: ADD_INTEREST_IMPORTED_TO_LIST,
                payload: value
            }
        )
        addNotification(userLang);
        if (history) history.push('/');
    }
}

export const removeInterestToList = (id: string) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_INTEREST_TO_LIST,
            payload: id
        })
    }
}
export const removeInterestToSuggestion = (id: string) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_SUGGESTION_TO_LIST,
            payload: id
        })
    }
}

export const removeAllInterestList = (stateClose: Dispatch<SetStateAction<boolean>>) => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_ALL_INTEREST_TO_LIST
        })
        stateClose(false);
    }
}

export const consultSuggestions = (interestList: string, lang: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: GET_SUGGESTIONS
        })
        try {
            const resp = await clienteAxios.get('interests/suggestions',
                {
                    params: {
                        "interests": interestList,
                        "lang": lang
                    },
                    // withCredentials: true
                }
            );
            // console.log("==> Respuesta de suggestion")
            // console.log(resp.data);

            let arrayTemp: any = [];

            resp.data?.map((interest: any) => {
                var objectData = {
                    name: "",
                    description: "",
                    id: "",
                    path: "",
                    audience_size: 0 || "0"
                };
                objectData.name = interest.name;
                objectData.description = interest.description;
                objectData.id = interest.id;
                objectData.audience_size = interest.audience_size ? new Intl.NumberFormat().format(interest.audience_size) : interest.audience_size_upper_bound ? new Intl.NumberFormat().format(interest.audience_size_upper_bound) : "0";
                var path = ""
                interest.path?.map((value: string) => {
                    if (path === "") {
                        path += value
                    } else {
                        path += `>${value}`
                    }
                })
                objectData.path = path
                arrayTemp.push(objectData);

            });
            var hashTemp: any = {}
            const newArray = arrayTemp?.filter((value: any) => {
                var element = !hashTemp[value.id];
                hashTemp[value.id] = true;
                return element;

            })
            dispatch({
                type: GET_SUGGESTIONS_SUCCESS,
                payload: newArray
            })
        } catch (error: any) {
            console.log("==> Error al consultar sugerencias");
            console.log(error);
            console.log(error?.message);
            dispatch({
                type: GET_SUGGESTIONS_ERROR,
            })
        }
    }
}

export const updateKeywordFind = (keyword: string) => {
    return (dispatch: Dispatch<any>) => {
        localStorage.setItem("ht-keyword", keyword);
        dispatch({
            type: UPDATE_KEYWORD,
            payload: keyword
        })
    }
}

export const searchInteres = (keyword: string, lang: string, messageError: Function, usingAI: boolean, fromHome: boolean, history: any, fbtoken: string = "") => {
    return async (dispatch: any) => {
        dispatch({
            type: SEARCH_INTEREST
        })
        localStorage.removeItem("ht-listFindIntereses");
        localStorage.removeItem("ht-categories");
        localStorage.removeItem("ht-listFindAI");
        try {
            const state = store.getState();
            const useai = state.cuenta.typesAdmits.includes(state.cuenta?.user?.account) || state.cuenta?.user?.isGuest ? `${usingAI}` : "false";
            const resp = await clienteAxios.get('interests',
                {
                    params: {
                        keyword,
                        lang: lang.toLowerCase(),
                        useai,
                        fbtoken
                    },
                    withCredentials: true
                })
            // console.log("===> Respuesta al ontener intereses");
            // console.log(resp.data);

            let categories: Array<any> = [];
            let arrayTemp = resp.data?.results?.map((interest: any) => {

                var objectData = {
                    name: "",
                    description: "",
                    id: "",
                    path: "",
                    audience_size: 0 || '0'
                };
                objectData.name = interest.name;
                objectData.description = interest.description;
                objectData.id = interest.id;
                objectData.audience_size = interest.audience_size ? new Intl.NumberFormat().format(interest.audience_size) : interest.audience_size_upper_bound ? new Intl.NumberFormat().format(interest.audience_size_upper_bound) : "0";
                var path = ""
                let i = 0;
                var index = 0;
                interest.path.map((value: string) => {
                    if (path === "") {
                        path += value
                    } else {
                        path += `>${value}`
                    }
                    if (i === 0) {
                        var repeat = false;
                        var iTemp = 0
                        categories?.forEach((element: any) => {
                            if (element.name?.trim() === value.trim()) {
                                repeat = true;
                                index = iTemp;
                            }
                            iTemp++;
                        })
                        if (!repeat) {
                            categories.push({
                                name: value,
                                submenu: []
                            })
                            index = categories.length - 1;
                        }
                    } else {
                        if (i === 1) {
                            var repeat2 = false;

                            categories[index].submenu?.forEach((e: string) => {
                                if (e === value) {
                                    repeat2 = true;
                                }
                            })
                            if (!repeat2) {
                                if (categories[index].submenu?.length === 0) {
                                    categories[index].submenu = [
                                        value
                                    ]
                                } else {
                                    categories[index].submenu = [
                                        ...categories[index].submenu,
                                        value
                                    ]

                                }
                            }
                        }
                    }
                    i += 1;
                })
                objectData.path = path
                // arrayTemp.push(objectData);
                return objectData;
            })

            var hashTemp: any = {}
            const newArray = arrayTemp?.filter((value: any) => {
                var element = !hashTemp[value.id];
                hashTemp[value.id] = true;
                return element;

            })

            localStorage.setItem("ht-listFindIntereses", JSON.stringify(newArray));
            localStorage.setItem("ht-categories", JSON.stringify(categories));
            if (resp.data?.resultsAi) {
                localStorage.setItem("ht-listFindAI", JSON.stringify(resp.data?.resultsAi));
            }

            if (fromHome) {
                history.push("/intereses")
            }
            dispatch({
                type: SEARCH_INTEREST_SUCCESS,
                payload: {
                    info: newArray,
                    // keyword,
                    lang: lang.toLowerCase(),
                    categories,
                    user: resp.data?.user,
                    listai: resp.data?.resultsAi
                }
            })
        } catch (error: any) {
            console.log("===> ERROR AL BSUCAR INTERES")
            console.log(error);
            console.log(error?.message);
            dispatch({
                type: SEARCH_INTEREST_ERROR
            })
            localStorage.removeItem("ht-listFindIntereses");
            localStorage.removeItem("ht-categories");
            localStorage.removeItem("ht-keyword");
            localStorage.removeItem("ht-listFindAI");
            messageError();

        }
    }
}

export const filterListInterest = (filters: any, listFindIntereses: Array<any>) => {
    return (dispatch: any) => {
        let temp: Array<any> = [];
        filters?.category?.forEach((filter: string) => {
            listFindIntereses?.forEach((interest: InterestFindInterface) => {
                if (interest.path.indexOf(filter) > -1) {
                    temp.push(interest);
                    return;
                }
            })
        })

        if (filters?.category?.length === 0) {
            temp = listFindIntereses;
        }
        temp = temp?.filter((interest: InterestFindInterface) => {
            const minimo = filters?.size?.minimo;
            const maximo = filters?.size?.maximo;
            let audienceSizeFilterNumber = interest.audience_size.toString().replace(/[,.]/g, '');
            if (minimo > 0 && maximo > 0) {
                if (audienceSizeFilterNumber >= minimo && audienceSizeFilterNumber <= maximo) {
                    return interest;
                }
            } else {
                if (minimo > 0) {
                    if (audienceSizeFilterNumber >= minimo) {
                        return interest;
                    }
                } else {
                    if (maximo > 0) {
                        if (audienceSizeFilterNumber <= maximo) {
                            return interest;
                        }
                    } else {
                        if (maximo === 0 && minimo === 0) {
                            return interest;
                        }
                    }
                }
            }
        })
        dispatch({
            type: IMPLEMENT_INTEREST_FILTER,
            payload: {
                listInterests: filters?.category?.length === 0 && filters?.size?.minimo === 0 && filters?.size?.maximo === 0 ? [] : temp,
                filter: filters
            }
        })
    }
}

export const changeSearchLangInterest = (l: string) => {
    return (dispatch: any) => {
        dispatch({
            type: CHANGE_SEARCH_LANGUAGE,
            payload: l
        })
        localStorage.setItem('searchLang', l)
    }
}

export const changeUsingAI = (usingAI: boolean) => {
    return (dispatch: any) => {

        localStorage.setItem("ht__useAI", `${usingAI}`)

        dispatch({
            type: CHANGE_USINGAI,
            payload: usingAI
        })
    }
}

export const filterReset = (setResetCss: Dispatch<SetStateAction<boolean>>) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: FILTER_RESET
        })
        setResetCss(true)
        setTimeout(() => {
            setResetCss(false)
        }, 1000)
    }
}