import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InterestFindInterface } from "../../../interfaces/interface";
import {
  addInterestImported,
  removeInterestToList,
  removeInterestToSuggestion,
} from "../../../redux/actions/interesesAction";
// ICONS
import checkboxCopy from "../../../assets/icons/ri_checkbox-circle-line.svg";
import closeIcon from "../../../assets/icons/ri_close-fill.svg";
import binIcon from "../../../assets/icons/ri_delete-bin-line.svg";
// COMPONENTS
import ModalComponent from "../../../components/modalComponents/modalComponent";
import ModalDeleteInterest from "./modalDeleteInterest/ModalDeleteInterest";
import { CSVLink } from "react-csv";
import moment from "moment";
// import { NotificationManager } from "../../../components/common/react-notifications";
import { addNotification, csvFileDestructuring } from "../../../helpers";
import IntlMessages from "../../../helpers/IntlMessages";
import AppLocale from "../../../lang";
import { IInterest } from "../../../interfaces/library.interface";

const InteresesSelected = () => {
  const dispatch = useDispatch();
  const [errorCopy, setErrorCopy] = useState("");
  const [msgCopy, setMsgCopy] = useState("");
  const [showModal, setShowModal] = useState(false);

  const {
    intereses: { listIntereses, listSuggestionsActive },
  } = useSelector((state: any) => state);

  const userLang = useSelector<any, any>((state) => state.cuenta.lang);
  const currentAppLocale = AppLocale[userLang === "es-ES" ? "es" : "en"];

  const copyToClipboard = () => {
    if (listIntereses?.length > 0) {
      var textField = document.createElement("textarea");
      let textCopy = "";
      listIntereses.forEach((interest: InterestFindInterface) => {
        if (textCopy.length === 0) {
          textCopy = textCopy + `${interest.name}`;
        } else {
          textCopy = textCopy + `, ${interest.name}`;
        }
      });
      textField.innerText = textCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      setErrorCopy("");
      setMsgCopy(currentAppLocale.messages["alerts.copied"]);
      setTimeout(() => {
        setMsgCopy("");
      }, 2000);
    } else {
      // MOSTRAR ALERTA SIN HASHTAGS EN EL PROYECTO
      setErrorCopy(currentAppLocale.messages["alerts.errorcopied"]);
    }
  };

  const removeToList = (id: string) => {
    // Preguntar si quiere eliminar interes
    dispatch(removeInterestToList(id));
    const listSugg = listSuggestionsActive?.filter(
      (interest: InterestFindInterface) => interest.id === id
    );
    if (listSugg?.length > 0) {
      dispatch(removeInterestToSuggestion(id));
    }
  };

  return (
    <>
      <div className="ht__interesesSelected">
        <div className="ht__interesesSelected-title">
          <p>
            <IntlMessages id="pages.interest.listSelected" />
          </p>
        </div>
        <div className="ht__interesesSelected-actions">
          <div className="btn-copy" onClickCapture={() => copyToClipboard()}>
            <p>
              <IntlMessages id="btn.copy" />
            </p>
          </div>
          <CSVLink
            data={listIntereses}
            filename={`intereses-${moment(Date.now()).format(
              "YYYY-MM-DD"
            )}.csv`}
            className="btn-csv"
            target="_blank"
            separator={";"}
            enclosingCharacter={`'`}
          >
            <IntlMessages id="btn.export" />
          </CSVLink>

          <div
            onClickCapture={() => {
              document.getElementById("import-csv2")?.click();
            }}
            className="btn-csv-import"
          >
            <input
              type="file"
              name="file"
              id="import-csv2"
              accept=".csv"
              onChange={async (e) => {
                const csv = e.currentTarget.files![0];
                let interestPass: any[] = [];
                let array = await csvFileDestructuring(csv);
                array = array.map((interest: IInterest) => {
                  let existe = listIntereses.filter(
                    (i: IInterest) => i.id === interest.id
                  );
                  if (existe.length <= 0) {
                    interestPass.push(interest);
                  }
                });
                dispatch(
                  addInterestImported(interestPass, addNotification, userLang)
                );
              }}
            />
            <p>
              <IntlMessages id="btn.import" />
            </p>
          </div>
          <div onClickCapture={() => setShowModal(true)} className="btn-delete">
            {/* <p>BORRAR TODO</p> */}
            <img src={binIcon} alt="Borrar todo" />
          </div>
        </div>
      </div>
      {errorCopy.length !== 0 ? (
        <div className="ht__interesesSelected-error">
          <p>{errorCopy}</p>
        </div>
      ) : (
        <></>
      )}

      <div className="ht__interesesSelected-containerIntereses">
        {msgCopy.length !== 0 ? (
          <div className="ht__interesesSelected-copy">
            <div className="ht__interesesSelected-copy-img">
              <img src={checkboxCopy} alt="copy" />
            </div>
            <p>{msgCopy}</p>
          </div>
        ) : (
          <></>
        )}

        {listIntereses?.map((interes: InterestFindInterface) => {
          return (
            <div id={interes.id} key={interes.id}>
              <div>
                <p>{interes.name}</p>
                <img
                  src={closeIcon}
                  alt="Delete"
                  onClickCapture={() => removeToList(interes.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="ht__interesesSelected-actions ht__interesesSelected-actionMobile ">
        <div onClickCapture={() => setShowModal(true)} className="btn-delete">
          {/* <p>BORRAR TODO</p> */}
          <img src={binIcon} alt="Borrar todo" />
        </div>
        <div>
          <CSVLink
            data={listIntereses}
            filename={`intereses-${moment(Date.now()).format(
              "YYYY-MM-DD"
            )}.csv`}
            className="btn-csv"
            target="_blank"
            separator={";"}
            enclosingCharacter={`'`}
          >
            <IntlMessages id="btn.export" />
          </CSVLink>
          <div className="btn-copy" onClickCapture={() => copyToClipboard()}>
            <p>
              <IntlMessages id="btn.copy" />
            </p>
          </div>
        </div>
      </div>
      {showModal ? (
        <ModalComponent
          component={ModalDeleteInterest}
          stateShowDialog={setShowModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default InteresesSelected;
