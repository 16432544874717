import { useSelector } from "react-redux";
import HeaderScreen from "../../components/header-screens/HeaderScreen";
import EquipoTrabajo from "./equipoTrabajo";
import InformacionPersonal from "./informacionPersonal";
import MiCuentaTabbar from "./MiCuentaTabbar";
import Planes from "./Planes";

const MiCuentaScreen = () => {
  const {
    cuenta: { tabbar },
  } = useSelector((state: any) => state);

  return (
    <>
      <HeaderScreen
        showInput={false}
        title="Mi cuenta"
        idMesagge="pages.account"
      />
      <div className="ht__miCuenta">
        <MiCuentaTabbar />
        {tabbar === 0 ? (
          <InformacionPersonal />
        ) : tabbar === 1 ? (
          <EquipoTrabajo />
        ) : (
          <Planes />
        )}
      </div>
    </>
  );
};

export default MiCuentaScreen;
