import { useDispatch } from "react-redux";
import deleteIcon from "../../../../assets/icons/delete-icon.svg";
import closeIcon from "../../../../assets/icons/ri_close-fill.svg";
import IntlMessages from "../../../../helpers/IntlMessages";
import { PropsModalEliminar } from "../../../../interfaces/interface";
import { removeAllInterestList } from "../../../../redux/actions/interesesAction";

const ModalDeleteInterest: React.FC<PropsModalEliminar> = ({ stateClose }) => {
  const dispatch = useDispatch();

  return (
    <div className="ht__modalDeleteInterest">
      <div className="header">
        <img src={deleteIcon} alt="Eliminar Intereses" />
        <img
          src={closeIcon}
          alt="Cerrar"
          onClickCapture={() => stateClose(false)}
        />
      </div>
      <h4>
        <IntlMessages id="modal.interest.deletetitle" />
      </h4>
      <p>
        <IntlMessages id="modal.interest.deletemessage" />
      </p>
      <div className="container-btn">
        <div onClickCapture={() => stateClose(false)}>
          <IntlMessages id="modal.interest.deletecancelbtn" />
        </div>
        <div onClickCapture={() => dispatch(removeAllInterestList(stateClose))}>
          <IntlMessages id="modal.interest.deletebtn" />
        </div>
      </div>
    </div>
  );
};

export default ModalDeleteInterest;
