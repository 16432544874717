import { PropsGenerarReportePage } from "../../../interfaces/interface";
import iconGrafico from "../../../assets/icons/pasos_2.svg";
import iconArrow from "../../../assets/icons/ri_arrow-bottom-s-line.svg";
import iconQuestion from "../../../assets/icons/ri_question-line_1.svg";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ICampaign } from "../../../interfaces/report.interface";
import moment from "moment";
import { NotificationManager } from "../../../components/common/react-notifications";
import AppLocale from "../../../lang";
import IntlMessages from "../../../helpers/IntlMessages";

const GenerarPage2: React.FC<PropsGenerarReportePage> = ({
  statePage,
  setValues,
  values,
}) => {
  const { accountCampaigns } = useSelector((state: any) => state.reportes);
  const { lang } = useSelector((state: any) => state.cuenta);
  const [currentCampaign, setCurrentCampaign] = useState<any>(null);
  const [cuentaOptions, setCuentaOptions] = useState(false);

  const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];

  const selectCampaign = (campaign: ICampaign) => {
    setValues({
      ...values,
      campaign: campaign.id,
      date: {
        ...values.date,
        since: moment(campaign.start_time).format("YYYY-MM-DD"),
      },
    });
    console.log(moment(campaign.start_time).format("YYYY-MM-DD"));

    setCurrentCampaign(campaign);
  };
  const updateStateDate = (dateString: string, e: any) => {
    const date = moment(dateString).format("YYYY-MM-DD");
    setValues({
      ...values,
      date: {
        ...values.date,
        [e.currentTarget.name]: date,
      },
    });
  };

  const changeDate = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value !== "") {
      const dateString = e.currentTarget.value;
      if (currentCampaign.start_time) {
        if (e.currentTarget.name === "since") {
          if (!moment(dateString).isBefore(currentCampaign.start_time)) {
            updateStateDate(dateString, e);
          } else {
            NotificationManager.warning(
              currentAppLocale.messages[
                "modal.reports.specifications.notificationDate"
              ],
              "",
              4000,
              null,
              "",
              ""
            );
          }
        } else {
          if (!moment(dateString).isAfter()) {
            updateStateDate(dateString, e);
          } else {
            NotificationManager.warning(
              currentAppLocale.messages[
                "modal.reports.specifications.notificationnowDate"
              ],
              "",
              4000,
              null,
              "",
              ""
            );
          }
        }
      }
    }

    // console.log(typeof(date));

    // console.log(e.currentTarget);
  };

  const navigateToNextPage = () => {
    if (
      currentCampaign &&
      values.date.since !== "" &&
      values.date.until !== ""
    ) {
      statePage(3);
    } else {
      NotificationManager.warning(
        currentAppLocale.messages["modal.reports.page.emptymessage"],
        currentAppLocale.messages["modal.reports.page.emptytitle"],
        3000,
        null,
        "",
        ""
      );
    }
  };

  useEffect(() => {
    setValues({
      ...values,
      date: {
        ...values.date,
        until: moment().format("YYYY-MM-DD"),
      },
    });
  }, []);

  return (
    <div className="ht__generarPage1 ht__generarPage2">
      <p>
        <IntlMessages id="modal.reports.page2.step" />
      </p>
      <div className="ht__generarPage1-grafico">
        <img src={iconGrafico} alt="Grafico 2" />
      </div>
      <label htmlFor="campanias">
        <IntlMessages id="pages.reports.campaign" />
      </label>
      <div
        className="ht__generarPage1-cuenta"
        onClickCapture={() => setCuentaOptions(!cuentaOptions)}
      >
        <div className="ht__generarPage1-cuenta-value">
          {currentCampaign ? (
            currentCampaign?.name
          ) : (
            <IntlMessages id="modal.reports.page2.selectcampaign" />
          )}
          <img src={iconArrow} alt="icon" />
        </div>
        <div
          className={`${
            cuentaOptions ? "ht__generarPage1-cuenta-show-options" : ""
          } ht__generarPage1-cuenta-options`}
        >
          {accountCampaigns?.map((campaign: ICampaign) => (
            <p
              onClickCapture={() => selectCampaign(campaign)}
              key={campaign.id}
            >
              {campaign.name}
            </p>
          ))}
        </div>
      </div>
      {/* FECHAS */}
      <div className="ht__generarPage1-fechas">
        <div className="fecha">
          <h4>
            <IntlMessages id="modal.reports.page2.dates" />
          </h4>
          <img src={iconQuestion} alt="Ayuda" />
        </div>
        <div className="ht__generarPage1-fechas-inputs">
          <div>
            <label>
              <IntlMessages id="modal.reports.page2.startdate" />
            </label>
            <input
              onChange={(e) => changeDate(e)}
              value={values?.date.since.toString()}
              // value="11-10-2020"
              type="date"
              name="since"
              id="since"
              full-date="yyyy-mm-dd"
              lang={lang}
            />
          </div>
          <div>
            <label>
              <IntlMessages id="modal.reports.page2.endingdate" />
            </label>
            <input
              type="date"
              name="until"
              id="until"
              full-date="yyyy-mm-dd"
              value={values?.date.until.toString()}
              onChange={(e) => changeDate(e)}
              lang={lang}
            />
          </div>
        </div>
      </div>
      <div className="ht__generarPage2-containerBtn">
        <div onClickCapture={() => statePage(1)}>
          <IntlMessages id="modal.reports.page2.previous" />
        </div>
        <div className="btn" onClickCapture={navigateToNextPage}>
          <IntlMessages id="modal.reports.page1.next" />
        </div>
      </div>
    </div>
  );
};

export default GenerarPage2;
