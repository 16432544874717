import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  ILibrary,
  IPropsCardIntereses,
} from "../../../interfaces/library.interface";
import { addSingleAudience } from "../../../redux/actions/audienciasAction";

// const listaTemp = [
//   {
//     title: "Gamers",
//     intereses: "78",
//     description: "Amantes de l34os videojuegos",
//     urlImg:
//       "https://cdn.pixabay.com/photo/2020/05/25/17/54/library-5219747_1280.jpg",
//   },
//   {
//     title: "Gamers",
//     intereses: "78",
//     description: "Amantes de los v12ideojuegos",
//     urlImg:
//       "https://cdn.pixabay.com/photo/2021/08/14/11/46/sunflower-6545123_1280.jpg",
//   },
//   {
//     title: "Gamers",
//     intereses: "78",
//     description: "Amantes de los 12videojuego1s",
//     urlImg:
//       "https://cdn.pixabay.com/photo/2021/07/23/17/07/volcano-6487743_1280.jpg",
//   },
//   {
//     title: "Gamers",
//     intereses: "78",
//     description: "Amantes de los videoj21uegos1",
//     urlImg:
//       "https://cdn.pixabay.com/photo/2020/05/25/17/54/library-5219747_1280.jpg",
//   },
//   {
//     title: "Gamers",
//     intereses: "78",
//     description: "Amantes de los videojuegos",
//     urlImg:
//       "https://cdn.pixabay.com/photo/2021/08/14/11/46/sunflower-6545123_1280.jpg",
//   },
//   {
//     title: "Gamers",
//     intereses: "78",
//     description: "Amantes de los videojuegoss",
//     urlImg:
//       "https://cdn.pixabay.com/photo/2021/07/23/17/07/volcano-6487743_1280.jpg",
//   },
// ];

const CardInteres: React.FC<IPropsCardIntereses> = ({ libaryListFilter }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const audiencias = useSelector<any, ILibrary[]>(
    (state) => state.audiencias.audiencias
  );

  const navegateTo = (library: ILibrary) => {
    dispatch(addSingleAudience(library, history));
  };

  if (audiencias.length <= 0) {
    return <p>No Hay audiencias</p>;
  }

  return (
    <>
      {(libaryListFilter ?? audiencias).map((element: ILibrary) => (
        <div
          key={element.hash}
          className="ht__cardInteres"
          onClickCapture={() => navegateTo(element)}
        >
          {element.access === "pro" && (
            <div className="ht__cardInteres-tag">
              <p>Premium</p>
            </div>
          )}

          <div className="ht__cardInteres-singleImg">
            <img src={element.media} alt={element.media} />
          </div>
          <div className="ht__cardInteres-infoInteres">
            <div className="title-interes">
              <p>{element.name}</p>
            </div>
            <div className="num-interes">
              <p>{element.interests.length} intereses</p>
            </div>
            {/* <div className="description-interes">
              <p>{element.description}</p>
            </div> */}
          </div>
        </div>
      ))}
    </>
  );
};

export default CardInteres;
