import { ADD_UNIQ_AUDIENCE, GET_LIBRARY_AUDIENCES, GET_LIBRARY_AUDIENCES_SUCESS, LOGOUT_SUCCESS } from "../../types/types";

import {
    ActionModel
} from "../../interfaces/interface";

const initialData = {
    audiencias: [],
    fetching: false,
    library: null
}

export const audienciasReducer = (state = initialData, action: ActionModel) => {
    switch (action.type) {
        case GET_LIBRARY_AUDIENCES:
            return {
                ...state,
                fetching: true
            }
        case GET_LIBRARY_AUDIENCES_SUCESS:
            return {
                ...state,
                audiencias: action.payload,
                fetching: false
            }
        case ADD_UNIQ_AUDIENCE:
            return {
                ...state,
                library: action.payload
            }
        case LOGOUT_SUCCESS:
            return initialData;
        default:
            return state
    }
}

