import React from "react";
import PropTypes from "prop-types";
import NotificationManager from "./NotificationManager";
import Notifications from "./Notifications";

class NotificationContainer extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleStoreChange = this.handleStoreChange.bind(this);
    NotificationManager.addChangeListener(this.handleStoreChange);
  }

  static propTypes = {
    enterTimeout: PropTypes.number,
    leaveTimeout: PropTypes.number,
  };

  static defaultProps = {
    enterTimeout: 400,
    leaveTimeout: 400,
  };

  state = {
    notifications: [],
  };

  componentWillUnmount = () => {
    NotificationManager.removeChangeListener(this.handleStoreChange);
  };

  handleStoreChange = (notifications: any) => {
    this.setState({
      notifications,
    });
  };

  handleRequestHide = (notification: any) => {
    NotificationManager.remove(notification);
  };

  render() {
    const { notifications } = this.state;
    const { enterTimeout, leaveTimeout } = this.props;
    return (
      <Notifications
        enterTimeout={enterTimeout}
        leaveTimeout={leaveTimeout}
        notifications={notifications}
        onRequestHide={this.handleRequestHide}
      />
    );
  }
}

export default NotificationContainer;
