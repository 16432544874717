// import iconArrow from "../../assets/icons/ri_arrow-bottom-s-line.svg";

import { useState } from "react";
import ModalComponent from "../../components/modalComponents/modalComponent";
// import PlanMedidaModal from "./planMedidaModal";
// import EliminarModal from "./EliminarModal";
import RowUnicUser from "./RowUnicUser";
import { useSelector } from "react-redux";
// import { getAllTeamWork } from "../../redux/actions/cuentaAction";
import { IUser } from "../../interfaces/interface";
import InvitarMiembroModal from "./invitarMiembroModal";
import IntlMessages from "../../helpers/IntlMessages";

const EquipoTrabajo = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [isEliminar, setIsEliminar] = useState(false);

  const teamwork = useSelector<any, any>((state) => state.cuenta.teamwork);
  const user = useSelector<any, IUser>((state) => state.cuenta.user);

  // useEffect(() => {
  //   dispatch(getAllTeamWork());
  // }, []);

  return (
    <>
      <div className="ht__equipoTrabajo">
        <div className="ht__equipoTrabajo-header">
          <p>
            <IntlMessages id="pages.teamwork.title" />
          </p>
          <div>
            {/* <div className="ht__equipoTrabajo-header-status">
              <div className="btn-status">
                <p>Todos los estatus</p>
                <img src={iconArrow} alt="arrow" />
              </div>
            </div> */}
            <div
              className="btn-invitar"
              onClickCapture={() => setShowDialog(true)}
            >
              <IntlMessages id="pages.teamwork.inviteMember" />
            </div>
          </div>
        </div>

        <div className="ht__equipoTrabajo-table-header">
          <div className="ht__equipoTrabajo-table-header-name">
            <IntlMessages id="pages.teamwork.headername" />
          </div>
          <div className="ht__equipoTrabajo-table-header-email">
            <IntlMessages id="pages.teamwork.headeremail" />
          </div>
          <div className="ht__equipoTrabajo-table-header-conection">
            <IntlMessages id="pages.teamwork.headerlastconnection" />
          </div>
          <div className="ht__equipoTrabajo-table-header-status">
            <IntlMessages id="pages.teamwork.headerstatus" />
          </div>
          <div className="ht__equipoTrabajo-table-header-action"></div>
        </div>
        {/* FILA DE TABLE */}

        <RowUnicUser user={user} />
        {teamwork.map((userTeam: IUser) => (
          <RowUnicUser user={userTeam} />
        ))}
      </div>
      <div
        className="ht__equipoTrabajo-btnInvitar"
        onClickCapture={() => {
          window.scroll({
            top: 0,
          });
          setShowDialog(true);
        }}
      >
        Invitar Miembro
      </div>
      {showDialog ? (
        <ModalComponent
          stateShowDialog={setShowDialog}
          idUser={isEliminar ? "123456" : undefined}
          isEliminar={setIsEliminar}
          component={InvitarMiembroModal}
          // component={isEliminar ? EliminarModal : PlanMedidaModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default EquipoTrabajo;
