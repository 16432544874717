import iconInfo from "../../assets/icons/icon-modal-fb.svg";
import ReactFacebookLogin from "react-facebook-login";
import { useDispatch, useSelector } from "react-redux";
import { loginFacebook } from "../../redux/actions/cuentaAction";
import { IPropsModalFB } from "../../interfaces/interface";
import IntlMessages from "../../helpers/IntlMessages";

const ModalNoFBAccount: React.FC<IPropsModalFB> = ({ setOpenModalSynced }) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state: any) => state.cuenta);
  const { isGuest } = useSelector((state: any) => state.cuenta.user);
  const onHandleFacebookLogin = (response: any) => {
    if (response.accessToken) {
      let dataFacebook = {
        token: response.accessToken,
        name: response.name,
        avatar: response.picture.data.url,
      };
      dispatch(loginFacebook(dataFacebook, lang, setOpenModalSynced));
    }
  };
  return (
    <>
      <div className="ht__modalNoFbAccount">
        <>
          <div className="ht__modalNoFbAccount-header">
            <div className="ht__modalNoFbAccount-header-icon">
              <img src={iconInfo} alt="Informacion" />
            </div>
            {/* <div className="ht__modalNoFbAccount-header-close">
          <img src={iconClose} alt="close" />
        </div> */}
          </div>
          <div className="ht__modalNoFbAccount-body">
            <h4>
              {!isGuest ? (
                <IntlMessages id="modal.facebookaccount.title" />
              ) : (
                <IntlMessages id="modal.account.titleGuest" />
              )}
            </h4>
            <p>
              {!isGuest ? (
                <IntlMessages id="modal.facebookaccount.description" />
              ) : (
                <IntlMessages id="pages.account.messagenofacebook" />
              )}
            </p>
            {!isGuest ? (
              <ReactFacebookLogin
                appId="1033138630189966"
                autoLoad={false}
                fields="name,email,picture"
                scope={
                  "ads_management,ads_read,email,read_insights,public_profile,pages_show_list"
                }
                callback={onHandleFacebookLogin}
                cssClass={"btn-facebook"}
                textButton={
                  lang === "es-ES"
                    ? "Vincular con Facebook"
                    : "Link with Facebook"
                }
                isMobile={false}
                disableMobileRedirect={true}
                version={"11.0"}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default ModalNoFBAccount;
