import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Svg,
} from "@react-pdf/renderer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { engagementLabels } from "../../../constants/constants";
import { IReport } from "../../../interfaces/report.interface";
import AppLocale from "../../../lang";

interface IDataReport {
  data: IReport;
  reportName: string;
}
interface IReportPdf {
  data: IDataReport;
  language: string;
  socialSpend: any;
  metrics: any;
}
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: "20px 10px",
    width: "100%",
  },
  //   ? HEADER INFORMATION
  viewDescription: {
    padding: "20px",
    border: "1px solid #e6ecef",
    marginBottom: "22px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // flexWrap: "wrap",
  },
  //   ? HEADER INFORMATION LEFT
  viewDescriptionLeft: {
    width: "60%",
  },
  pH4: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#11214f",
    margin: 0,
    textTransform: "uppercase",
    marginBottom: "8px",
  },
  pFecha: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#6e757c",
  },
  containerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cliente: {
    marginTop: "14px",
  },
  pTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#6e757c",
  },
  pName: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    color: "#11214f",
  },
  containerAd: {
    marginTop: "14px",
  },
  pTitleAd: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#6e757c",
    marginBottom: "2px",
  },
  statusAd: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#11214f",
  },
  statusActive: {
    height: "6px",
    width: "6px",
    backgroundColor: "#38b249",
    borderRadius: "50%",
    marginRight: "8px",
  },
  reportType: {
    marginTop: "14px",
  },
  reportTypeTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#6e757c",
    marginBottom: "2px",
  },
  reportTypeType: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    color: "#11214f",
  },
  containerRowRight: {
    paddingLeft: "50px",
  },
  containerRowRighth4: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#6e757c",
    marginBottom: "2px",
  },
  containerRowRightCampaign: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    marginBottom: "9px",
  },
  //   ? HEADER INFORMATION RIGHT
  viewDescriptionRight: {
    width: "40%",
  },
  containerPresupuesto: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "47px",
  },
  presupuestoTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#6e757c",
  },
  presupuestoValue: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#11214f",
  },
  containerImporte: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "12px",
  },
  containerImporteTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#6e757c",
  },
  containerImporteValue: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#11214f",
  },
  //   ? METRICAS REPORTE (GLOBAL)
  containerReporte: {
    padding: "20px",
    border: "1px solid #e6ecef",
  },
  containerReporteh4: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#11214f",
    marginBottom: "25px",
  },
  cardsReport: {
    width: "100%",
  },
  cardsReportHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#11214f",
  },
  cardsReportimgContainer: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
    backgroundColor: "#eaefff",
  },
  cardsReportimg: {
    width: "50%",
  },
  cardsReportContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "10px",
    marginBottom: "56px",
  },
  cardsReportEngament: {
    width: "21%",
    border: "1px solid #e6ecef",
    borderRadius: "4px",
    padding: "16px 10px 16px 10px",
    marginBottom: "7px",
    marginRight: "12px",
    overflow: "hidden",
  },
  cardsReportEngamentTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#6e757c",
    textOverflow: "ellipsis",
  },
  cardsReportEngagementValue: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    color: "#11214f",
  },
  //? GRAFICO POR EDADES
  cardsHeaderDistribution: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: "48px",
  },
  cardsHeaderDistributionFirst: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#11214f",
  },
  generoStatistics: {
    marginTop: "21px",
    marginBottom: "20px",
  },
  ageStaticsHeader1: {
    marginBottom: "8px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  ageStaticsTitle: {
    width: "20%",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#061624",
  },
  generoStatisticsReach: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#11214f",
    width: "26.2%",
    fontStyle: "normal",
    textAlign: "left",
  },
  generoStatisticsClicks: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#11214f",
    width: "26.2%",
    textAlign: "left",
  },
  generoStatisticsCpc: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#11214f",
    width: "12.9%",
    textAlign: "left",
  },
  generoStatisticsCtr: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#11214f",
    width: "12.9%",
  },
  generoStatisticsRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "12px",
  },
  generoFirst: {
    width: "20%",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#061624",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  generoMaleColor: {
    width: "16px",
    height: "6px",
    backgroundColor: "#1772c2",
    color: "#fff",
    marginRight: "5px",
  },
  generoFemaleColor: {
    width: "16px",
    height: "6px",
    backgroundColor: "#ffc301",
    color: "#061624",
    marginRight: "5px",
  },
  generoUnknowColor: {
    width: "16px",
    height: "6px",
    backgroundColor: "#dbe4ff",
    marginRight: "10px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#061624",
  },
  genderRowData: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "80%",
  },
  genderReach: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "33%",
    fontSize: "12px",
    color: "#061624",
  },
  genderPercentage: {
    fontSize: "10px",
    color: "#6e757c",
    marginLeft: "8px",
  },
  genderClickscpc: {
    textAlign: "right",
    paddingRight: "16px",
    width: "13.5%",
    fontWeight: "normal",
    fontSize: "10px",
    color: "#11214f",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  genderUsd: {
    fontSize: "10px",
    color: "#b2b6b9",
    textAlign: "right",
    marginRight: "10px",
  },
  genderCtr: {
    width: "13.5%",
    fontWeight: "normal",
    fontSize: "10px",
    paddingLeft: "10px",
  },
  ageRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "12px",
  },
  ageRowDiv: {
    width: "20%",
    fontSize: "10px",
    color: "#061624",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  ageData: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "80%",
  },
  paisesComponent: {
    paddingBottom: "19px",
    marginTop: "15px",
  },
  paisesComponentHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-between",
    backgroundColor: "#fafcfc",
    padding: "5px 0",
  },
  paisesComponentHeader1: {
    width: "25%",
    paddingLeft: "10px",
    textAlign: "left",
    fontWeight: 500,
    fontSize: "10px",
    color: "#061624",
    // backgroundColor: "red",
  },
  paisesComponentHeader2: {
    width: "15%",
    textAlign: "left",
    fontWeight: 500,
    fontSize: "10px",
    color: "#061624",
    // backgroundColor: "yellow",
  },
  paisesComponentHeaderSingle: {
    width: "15%",
    textAlign: "left",
    fontWeight: 500,
    fontSize: "10px",
    color: "#061624",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconHeaderCountry: {
    width: "10%",
    marginRight: "4px",
  },
  rowCountryTable: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 0",
  },
  countryDiv: {
    width: "25%",
    paddingLeft: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "10px",
    color: "#061624",
  },
  scopeDiv: {
    fontSize: "10px",
    color: "#061624",
    width: "15%",
  },
  cpcDiv: {
    fontSize: "10px",
    color: "#061624",
    width: "15%",
    textAlign: "right",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: "5px",
  },
  currency: {
    marginRight: "5px",
  },
});

const ReportPdf = ({ data, language, socialSpend, metrics }: IReportPdf) => {
  const [maxReach, setMaxReach] = useState(0);
  const [maxClicks, setMaxClicks] = useState(0);
  const [maxReachAges, setMaxReachAges] = useState(0);
  const [maxClicksAges, setMaxClicksAges] = useState(0);
  const currency = data.data.stats.global.currency || "USD";
  const metricsGlobal = [
    {
      key: "clicks",
      title: "Clicks",
    },
    {
      key: "cpc",
      title: "CPC",
    },

    {
      key: "cpl",
      title: "CPL",
    },
    {
      key: "ctr",
      title: "CTR",
    },
    {
      key: "frequency",
      title: `${language == "en" ? "Frecuency" : "Frecuencia"}`,
    },
    {
      key: "reach",
      title: `${language == "en" ? "Scope" : "Alcance"}`,
    },
    {
      key: "spend",
      title: `${language == "en" ? "Cost" : "Coste"}`,
    },
  ];
  const global2: any = data.data.stats.global;
  const engagement: any = data.data.stats.engagement;
  const genders = data.data.stats.gender;
  const ages = data.data.stats.age;
  const countries = data.data.stats.countries;

  const checkColor = (index: number) => {
    let color = "";
    switch (index) {
      case 0:
        color = "#ff4d00";
        break;
      case 1:
        color = "#ffe6e4";
        break;
      case 2:
        color = "#e12c21";
        break;
      case 3:
        color = "#1772c2";
        break;
      case 4:
        color = "#ff9c01";
        break;
      case 5:
        color = "#eaefff";
        break;
      default:
        color = "#ffe48e";
        break;
    }
    return color;
  };
  const checkColorCtr = (index: number) => {
    let color = "";
    switch (index) {
      case 0:
        color = "#ff4d00";
        break;
      case 1:
      case 5:
        color = "#000";
        break;
      case 2:
        color = "#e12c21";
        break;
      case 3:
        color = "#1772c2";
        break;
      case 4:
        color = "#ff9c01";
        break;
      default:
        color = "#000";
        break;
    }
    return color;
  };

  useEffect(() => {
    let max1 = 0;
    let max2 = 0;
    genders.forEach((gender) => {
      const reach = parseInt(gender.reach);
      const clicks = parseInt(gender.clicks);
      if (reach > max1) {
        max1 = reach;
      }
      if (clicks > max2) {
        max2 = clicks;
      }
    });
    setMaxReach(max1);
    setMaxClicks(max2);
    //? AGES
    let max1A = 0;
    let max2A = 0;
    ages.forEach((ages) => {
      const reach = parseInt(ages.reach);
      const clicks = parseInt(ages.clicks);
      if (reach > max1A) {
        max1A = reach;
      }
      if (clicks > max2A) {
        max2A = clicks;
      }
    });
    setMaxReachAges(max1A);
    setMaxClicksAges(max2A);
  }, []);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.viewDescription}>
          {/* LEFT */}
          <View style={styles.viewDescriptionLeft}>
            <Text style={styles.pH4}>{data.data.reportName}</Text>
            <Text style={styles.pFecha}>
              {language == "en"
                ? `Of the ${moment(data.data.dateStart).format(
                    "YYYY-MM-DD"
                  )} to the ${moment(data.data.dateEnd).format("YYYY-MM-DD")}`
                : `Del ${moment(data.data.dateStart).format(
                    "YYYY-MM-DD"
                  )} al ${moment(data.data.dateEnd).format("YYYY-MM-DD")}`}
            </Text>
            <View style={styles.containerRow}>
              {/* CONTAINER ROW LEFT */}
              <View>
                <View style={styles.cliente}>
                  <Text style={styles.pTitle}>
                    {language == "en" ? "Client" : "Cliente"}
                  </Text>
                  <Text style={styles.pName}>{data.data.clientName}</Text>
                </View>
                <View style={styles.containerAd}>
                  <Text style={styles.pTitleAd}>
                    {language == "en" ? "Ad delivery" : "Entrega de anuncios"}
                  </Text>
                  <View style={styles.statusAd}>
                    <View style={styles.statusActive}></View>
                    <Text>Activa</Text>
                  </View>
                </View>
                <View style={styles.reportType}>
                  <View style={styles.reportTypeTitle}>
                    <Text>
                      {language == "en" ? "Report type" : "Tipo de reporte"}
                    </Text>
                  </View>
                  <Text style={styles.reportTypeType}>E-commerce</Text>
                </View>
              </View>
              {/* CONTAINER RIGHT */}
              <View style={styles.containerRowRight}>
                <Text style={styles.containerRowRighth4}>
                  {" "}
                  {language == "en" ? "Campaigns" : "Campañas"}{" "}
                </Text>
                <Text style={styles.containerRowRightCampaign}>
                  {data.data.campaign}
                </Text>
              </View>
            </View>
          </View>
          {/* RIGHT */}
          <View style={styles.viewDescriptionRight}>
            <View style={styles.containerPresupuesto}>
              <Text style={styles.presupuestoTitle}>
                {language == "en" ? "Total budget" : "Presupuesto total"}
              </Text>
              <Text style={styles.presupuestoValue}>
                {`${currency}${data.data.stats.global.spend}`}
              </Text>
            </View>
            <View style={styles.containerImporte}>
              <Text style={styles.containerImporteTitle}>
                {`${language == "en" ? "Amount spent" : "Importe gastado"} ${
                  socialSpend.percent
                }% `}
              </Text>
              <Text style={styles.containerImporteValue}>
                {`${currency}${socialSpend.value}`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.containerReporte}>
          <Text style={styles.containerReporteh4}>
            {language == "en"
              ? "Aggregate of all campaigns"
              : "Agregado de todas las campañas"}
          </Text>
          {/* RESULTADOS PRINCIPALES */}
          {data.data.stats.global?.clicks ? (
            <View style={styles.cardsReport}>
              <View style={styles.cardsReportHeader}>
                <View style={styles.cardsReportimgContainer}>
                  <Image
                    source="./static/icons/ri_cursor-line.png"
                    style={styles.cardsReportimg}
                  />
                </View>
                <Text>
                  {language == "en" ? "Main Results" : "Resultados Principales"}
                </Text>
              </View>
              <View style={styles.cardsReportContainer}>
                {metricsGlobal.map((value: any) => (
                  <View style={styles.cardsReportEngament}>
                    <Text style={styles.cardsReportEngamentTitle}>
                      {value.title}
                    </Text>
                    <Text style={styles.cardsReportEngagementValue}>
                      {global2[value.key]}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          ) : (
            <View></View>
          )}
          {data.data.stats.engagement?.post_save ? (
            <View style={styles.cardsReport}>
              <View style={styles.cardsReportHeader}>
                <View style={styles.cardsReportimgContainer}>
                  <Image
                    source="./static/icons/ri_alarm-warning-line.png"
                    style={styles.cardsReportimg}
                  />
                </View>
                <Text>
                  {language == "en"
                    ? "Engagement and Interactions"
                    : "Engagement e Interacciones"}
                </Text>
              </View>
              <View style={styles.cardsReportContainer}>
                {Object.keys(engagement).map((e, i) => {
                  if (i !== 0) {
                    const label = engagementLabels.find(
                      (value) => value.metric === e
                    );
                    return (
                      <View style={styles.cardsReportEngament}>
                        <Text style={styles.cardsReportEngamentTitle}>
                          {language === "en" ? label?.labelE : label?.label}
                        </Text>
                        <Text style={styles.cardsReportEngagementValue}>
                          {engagement[e].value}
                        </Text>
                      </View>
                    );
                  }
                  return <View></View>;
                })}
              </View>
            </View>
          ) : (
            <View></View>
          )}
          {/* Título de Grafico por Genero */}
          <View style={styles.cardsHeaderDistribution}>
            <View style={styles.cardsHeaderDistributionFirst}>
              <View style={styles.cardsReportimgContainer}>
                <Image
                  source="./static/icons/ri_cursor-line.png"
                  style={styles.cardsReportimg}
                />
              </View>
              <Text>
                {language === "en"
                  ? "Distribution by gender and age"
                  : "Distribución por sexo y edades"}
              </Text>
            </View>
          </View>
          {/* GRAFICO GÉNERO  */}
          <View style={styles.generoStatistics}>
            <View style={styles.ageStaticsHeader1}>
              <View style={styles.ageStaticsTitle}>
                <Text> {language === "en" ? "Gender" : "Género"}</Text>
              </View>
              {Object.keys(metrics).map((e) => {
                if (metrics[e]) {
                  if (e === "reach") {
                    return (
                      <View style={styles.generoStatisticsReach}>
                        <Text>{language === "en" ? "Scope" : "Alcance"}</Text>
                      </View>
                    );
                  } else {
                    if (e === "clicks") {
                      return (
                        <View style={styles.generoStatisticsClicks}>
                          <Text>Clicks</Text>
                        </View>
                      );
                    } else {
                      if (e === "cpc") {
                        return (
                          <View style={styles.generoStatisticsCpc}>
                            <Text>CPC</Text>
                          </View>
                        );
                      } else {
                        if (e === "ctr") {
                          return (
                            <View style={styles.generoStatisticsCtr}>
                              <Text>CTR</Text>
                            </View>
                          );
                        } else {
                          if (e === "spend") {
                            return (
                              <View style={styles.generoStatisticsCpc}>
                                <Text>
                                  {language === "en" ? "Cost" : "Coste"}
                                </Text>
                              </View>
                            );
                          }
                        }
                      }
                    }
                  }
                }
              })}
            </View>
            {genders.map((gender) => (
              <View style={styles.generoStatisticsRow}>
                <View style={styles.generoFirst}>
                  <View
                    style={
                      gender.gender === "male"
                        ? styles.generoMaleColor
                        : gender.gender === "female"
                        ? styles.generoFemaleColor
                        : styles.generoUnknowColor
                    }
                  ></View>
                  <Text>
                    {language === "en"
                      ? gender.gender === "male"
                        ? "Male"
                        : gender.gender === "female"
                        ? "Female"
                        : "Unknown"
                      : gender.gender === "male"
                      ? "Hombres"
                      : gender.gender === "female"
                      ? "Mujeres"
                      : "Desconocido"}
                  </Text>
                </View>
                {/* DATOS */}
                <View style={styles.genderRowData}>
                  {Object.keys(metrics).map((e) => {
                    const color =
                      gender.gender === "male"
                        ? "#1772c2"
                        : gender.gender === "female"
                        ? "#ffc301"
                        : "#dbe4ff";
                    const colorText =
                      gender.gender === "male" ? "#fff" : "#000";
                    const colorCtr =
                      gender.gender === "male"
                        ? "#1772c2"
                        : gender.gender === "female"
                        ? "#ffc301"
                        : "#000";

                    if (metrics[e]) {
                      if (e === "reach") {
                        return (
                          <View style={styles.genderReach}>
                            <View
                              style={{
                                width: `${
                                  (parseInt(gender.reach) * 60) / maxReach
                                }%`,
                                minWidth: "6%",
                                backgroundColor: color,
                                textAlign: "right",
                                padding: "2px 4px",
                                color: colorText,
                                fontSize: "10px",
                              }}
                            >
                              <Text>{gender.reach}</Text>
                            </View>
                            <Text style={styles.genderPercentage}>
                              {(
                                (parseInt(gender.reach) * 100) /
                                parseFloat(global2.reach!)
                              ).toFixed(2)}
                              %
                            </Text>
                          </View>
                        );
                      } else {
                        if (e === "clicks") {
                          return (
                            <View style={styles.genderReach}>
                              <View
                                style={{
                                  width: `${
                                    (parseInt(gender.clicks) * 60) / maxClicks
                                  }%`,
                                  minWidth: "6%",
                                  backgroundColor: color,
                                  textAlign: "right",
                                  padding: "2px 4px",
                                  color: colorText,
                                  fontSize: "10px",
                                }}
                              >
                                <Text>{gender.clicks}</Text>
                              </View>
                              <Text style={styles.genderPercentage}>
                                {(
                                  (parseInt(gender.clicks) * 100) /
                                  parseFloat(global2.clicks!)
                                ).toFixed(2)}
                                %
                              </Text>
                            </View>
                          );
                        } else {
                          if (e === "cpc") {
                            return (
                              <View style={styles.genderClickscpc}>
                                <Text style={styles.genderUsd}>
                                  {currency}{" "}
                                </Text>
                                <Text>
                                  {" "}
                                  {parseFloat(gender.cpc).toFixed(4)}
                                </Text>
                              </View>
                            );
                          } else {
                            if (e === "ctr") {
                              return (
                                <View style={styles.genderCtr}>
                                  <Text
                                    style={{
                                      color: colorCtr,
                                    }}
                                  >
                                    {parseFloat(gender.ctr).toFixed(3)}%
                                  </Text>
                                </View>
                              );
                            } else {
                              if (e === "spend") {
                                return (
                                  <View style={styles.genderClickscpc}>
                                    <Text style={styles.genderUsd}>
                                      {currency}{" "}
                                    </Text>
                                    <Text>
                                      {" "}
                                      {parseFloat(gender.spend).toFixed(4)}
                                    </Text>
                                  </View>
                                );
                              }
                            }
                          }
                        }
                      }
                    }
                    return <View></View>;
                  })}
                </View>
              </View>
            ))}
          </View>
          {/* GRÁFICO DE EDADES */}
          <View style={styles.generoStatistics}>
            <View style={styles.ageStaticsHeader1}>
              <View style={styles.ageStaticsTitle}>
                <Text> {language === "en" ? "Ages" : "Edades"}</Text>
              </View>
              {Object.keys(metrics).map((e) => {
                if (metrics[e]) {
                  if (e === "reach") {
                    return (
                      <View style={styles.generoStatisticsReach}>
                        <Text>{language === "en" ? "Scope" : "Alcance"}</Text>
                      </View>
                    );
                  } else {
                    if (e === "clicks") {
                      return (
                        <View style={styles.generoStatisticsClicks}>
                          <Text>Clicks</Text>
                        </View>
                      );
                    } else {
                      if (e === "cpc") {
                        return (
                          <View style={styles.generoStatisticsCpc}>
                            <Text>CPC</Text>
                          </View>
                        );
                      } else {
                        if (e === "ctr") {
                          return (
                            <View style={styles.generoStatisticsCtr}>
                              <Text>CTR</Text>
                            </View>
                          );
                        } else {
                          if (e === "spend") {
                            return (
                              <View style={styles.generoStatisticsCpc}>
                                <Text>
                                  {language === "en" ? "Cost" : "Coste"}
                                </Text>
                              </View>
                            );
                          }
                        }
                      }
                    }
                  }
                }
              })}
            </View>
            {ages.map((age, index) => {
              const color = checkColor(index);
              return (
                <View style={styles.ageRow}>
                  <View style={styles.ageRowDiv}>
                    <View
                      style={{
                        width: "13px",
                        height: "6px",
                        backgroundColor: color,
                        marginRight: "10px",
                      }}
                    ></View>
                    <Text>{age.age}</Text>
                  </View>
                  <View style={styles.ageData}>
                    {Object.keys(metrics).map((e) => {
                      const colorText =
                        index === 0 || index === 2 || index === 3
                          ? "#fff"
                          : "#000";
                      const colorCtr = checkColorCtr(index);

                      if (metrics[e]) {
                        if (e === "reach") {
                          return (
                            <View style={styles.genderReach}>
                              <View
                                style={{
                                  width: `${
                                    (parseInt(age.reach) * 60) / maxReachAges
                                  }%`,
                                  minWidth: "6%",
                                  backgroundColor: color,
                                  textAlign: "right",
                                  padding: "2px 4px",
                                  color: colorText,
                                  // color: "#000",
                                  fontSize: "10px",
                                }}
                              >
                                <Text>{age.reach}</Text>
                              </View>
                              <Text style={styles.genderPercentage}>
                                {(
                                  (parseInt(age.reach) * 100) /
                                  parseFloat(global2.reach!)
                                ).toFixed(2)}
                                %
                              </Text>
                            </View>
                          );
                        } else {
                          if (e === "clicks") {
                            return (
                              <View style={styles.genderReach}>
                                <View
                                  style={{
                                    width: `${
                                      (parseInt(age.clicks) * 60) /
                                      maxClicksAges
                                    }%`,
                                    minWidth: "6%",
                                    backgroundColor: color,
                                    textAlign: "right",
                                    padding: "2px 4px",
                                    color: colorText,
                                    // color: "#000",
                                    fontSize: "10px",
                                  }}
                                >
                                  <Text>{age.clicks}</Text>
                                </View>
                                <Text style={styles.genderPercentage}>
                                  {(
                                    (parseInt(age.clicks) * 100) /
                                    parseFloat(global2.clicks!)
                                  ).toFixed(2)}
                                  %
                                </Text>
                              </View>
                            );
                          } else {
                            if (e === "cpc") {
                              return (
                                <View style={styles.genderClickscpc}>
                                  <Text style={styles.genderUsd}>
                                    {currency}{" "}
                                  </Text>
                                  <Text> {parseFloat(age.cpc).toFixed(4)}</Text>
                                </View>
                              );
                            } else {
                              if (e === "ctr") {
                                return (
                                  <View style={styles.genderCtr}>
                                    <Text
                                      style={{
                                        color: colorCtr,
                                      }}
                                    >
                                      {parseFloat(age.ctr).toFixed(3)}%
                                    </Text>
                                  </View>
                                );
                              } else {
                                if (e === "spend") {
                                  return (
                                    <View style={styles.genderClickscpc}>
                                      <Text style={styles.genderUsd}>
                                        {currency}{" "}
                                      </Text>
                                      <Text>
                                        {" "}
                                        {parseFloat(age.spend).toFixed(4)}
                                      </Text>
                                    </View>
                                  );
                                }
                              }
                            }
                          }
                        }
                      }
                      return <View></View>;
                    })}
                  </View>
                </View>
              );
            })}
          </View>
          {/* Distribución por paises */}
          <View style={styles.cardsReportHeader}>
            <View style={styles.cardsReportimgContainer}>
              <Image
                source="./static/icons/ri_coupon-4-line.png"
                style={styles.cardsReportimg}
              />
            </View>
            <Text>
              {language == "en"
                ? "Distribution by countries"
                : "Distribución por países"}
            </Text>
          </View>
          {/* Grafico paises */}
          <View style={styles.paisesComponent}>
            {/* Headers */}
            <View style={styles.paisesComponentHeader}>
              {/* Pais */}
              <View style={styles.paisesComponentHeader1}>
                <Text>{language === "en" ? "Country" : "País"}</Text>
              </View>
              {/* Alcance */}
              <View style={styles.paisesComponentHeader2}>
                <Text>{language === "en" ? "Scope" : "Alcance"}</Text>
              </View>
              <View style={styles.paisesComponentHeaderSingle}>
                <Image
                  src="./static/icons/ri_cursor-line-country.png"
                  style={styles.iconHeaderCountry}
                />
                <Text>{language === "en" ? "Clicks" : "Clics"}</Text>
              </View>
              <View style={styles.paisesComponentHeaderSingle}>
                <Image
                  src="./static/icons/ri_money-dollar-circle-line.png"
                  style={styles.iconHeaderCountry}
                />
                <Text>CPC</Text>
              </View>
              <View style={styles.paisesComponentHeaderSingle}>
                <Image
                  src="./static/icons/ri_percent-line.png"
                  style={styles.iconHeaderCountry}
                />
                <Text>CTR</Text>
              </View>
              <View style={styles.paisesComponentHeaderSingle}>
                <Image
                  src="./static/icons/ri_creative-commons-nc-line.png"
                  style={styles.iconHeaderCountry}
                />
                <Text>{language === "en" ? "Cost" : "Coste"}</Text>
              </View>
            </View>
            {/* DATA  */}
            <View>
              {countries.map((value) => {
                return (
                  <View>
                    <View style={styles.rowCountryTable}>
                      {/* Bandera del pais */}
                      <View style={styles.countryDiv}>
                        {/* //! ICONO DEL PAÍS */}
                        <Svg />
                        {/* Nombre del país */}
                        <Text>{value.country}</Text>
                      </View>
                      <View style={styles.scopeDiv}>
                        <Text>{value.reach}</Text>
                      </View>
                      <View style={styles.scopeDiv}>
                        <Text>{value.clicks}</Text>
                      </View>
                      <View style={styles.cpcDiv}>
                        <Text style={styles.currency}>{currency}</Text>
                        <Text>{parseFloat(value.cpc).toFixed(4)}</Text>
                      </View>
                      <View style={styles.scopeDiv}>
                        <Text>{parseFloat(value.ctr).toFixed(3)}%</Text>
                      </View>
                      <View style={styles.scopeDiv}>
                        <Text>{value.spend}</Text>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default ReportPdf;
