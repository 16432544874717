import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { searchInteres, updateKeywordFind } from "../redux/actions/interesesAction";
// import { useDispatch } from "react-redux"

export const useFindIntereses = (initialData: any) => {
    const usingAI = useSelector((state: any) => state.intereses.usingAI);
    const facebookToken = useSelector((state: any) => state.cuenta.user.facebookToken);
    const [value, setValue] = useState(initialData);
    const disptach = useDispatch();
    const history = useHistory();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setValue(value)
    }

    const sendKeywordToFind = (lang: string, fromHome: boolean, messageError: Function) => {
        disptach(searchInteres(value, lang, messageError, usingAI, fromHome, history, facebookToken));
        disptach(updateKeywordFind(value));
    }
    return { value, handleChange, sendKeywordToFind, setValue };
}