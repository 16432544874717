import { useSelector } from "react-redux";
import IntlMessages from "../../helpers/IntlMessages";
import { IContainerPlan, IUser } from "../../interfaces/interface";
import iconNote from "../../assets/icons/ri_lightbulb-line.svg";
import { useEffect, useState } from "react";
import moment from "moment";

const ContainerPlan: React.FC<IContainerPlan> = ({ cssClass }) => {
  const user = useSelector<any, IUser>((state: any) => state.cuenta.user);
  const [dateInit, setDateInit] = useState("");
  const [finalDate, setFinalDate] = useState("");

  const typesAdmitsTeam = useSelector<any, any>(
    (state) => state.cuenta.typesAdmitsTeam
  );

  const changeDatesUsesAi = (dateInit: string, dateEnd: string) => {
    // moment
    const fecha = moment(dateInit, "YYYY-MM-DD").toDate();
    const fechaFin = moment(dateEnd, "YYYY-MM-DD").toDate();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    const monthFin = fechaFin.getMonth() + 1;
    const dayFin = fechaFin.getDate();
    // setDateInit(fecha.toLocaleString());
    setDateInit(
      `${day < 10 ? `0${day}` : day}/${
        month < 10 ? `0${month}` : month
      }/${fecha.getFullYear()}`
    );
    setFinalDate(
      `${dayFin < 10 ? `0${dayFin}` : dayFin}/${
        monthFin < 10 ? `0${monthFin}` : monthFin
      }/${fechaFin.getFullYear()}`
    );
  };

  useEffect(() => {
    if (!user.lifetime) {
      if (user.initPlanDate && user.endPlanDate) {
        changeDatesUsesAi(user.initPlanDate, user.endPlanDate);
      }
    }
  }, []);
  return (
    <div className={`ht__planes-container-pro ${cssClass && cssClass}`}>
      {/* <div className="ht__planes-container-ribbon">
            <div className="ribbon">Recomendado</div>
          </div> */}
      <div className="header">
        <p>
          {user.lastAccount === "lite" || user.account === "lite"
            ? "LITE"
            : "PRO"}
          {!user.isGuest && user.lifetime && (
            <span className="text-ai">LIFETIME</span>
          )}
        </p>
      </div>
      <div className="body">
        {user.lastAccount === "lite" || user.account === "lite" ? (
          <div>
            {(user.aditionPlan && user.lastAccount) && (
              <p>
                <IntlMessages id="pages.account.plans.memberspronumsearchs" />
              </p>
            )}
            <p>
              <IntlMessages id="pages.account.plans.membersliteAudiences" />
            </p>
            <p>
              {" "}
              <IntlMessages id="pages.account.plans.membersproextension" />{" "}
            </p>
          </div>
        ) : (
          //***** CUENTAS PRO *****
          <div>
            {/* <p>Acceso ilimitado al software</p> */}
            <p>
              <span>
                <IntlMessages id="pages.account.plans.memberspro" />
              </span>{" "}
              <IntlMessages id="pages.account.plans.membersproteam" />
            </p>
            {user.aditionPlan && (
              <p>
                <IntlMessages id="pages.account.plans.memberspronumsearchs" />
              </p>
            )}
            <p>
              {" "}
              <IntlMessages id="pages.account.plans.membersproilimit" />{" "}
            </p>
            <p>
              <IntlMessages id="pages.account.plans.membersproenginesearch" />
            </p>
            <p>
              {" "}
              <IntlMessages id="pages.account.plans.membersprolibrary" />{" "}
            </p>
            <p>
              {" "}
              <IntlMessages id="pages.account.plans.membersproextension" />{" "}
            </p>
          </div>
        )}
        <div>
          {/* {typesAdmitsTeam.includes(user.account) ? ( */}
          <div className="ht__planPro-note">
            <img src={iconNote} alt="Nota" />
            <p className="ht_textuses-relative">
              <IntlMessages id="pages.account.plans.memberspromyplan" />{" "}
              {user.account === "lite" || user.lastAccount === "lite"
                ? "LITE"
                : "PRO"}{" "}
              {!user.isGuest && user.lifetime && (
                <span className="text-ai">LIFETIME</span>
              )}
            </p>
            {/* <p className="ht_textuses">
            {user.account === "proai" ? (
              <IntlMessages id="pages.account.plans.useaitext" />
            ) : user.aditionPlan ? (
              <IntlMessages id="pages.account.plans.useaitext" />
            ) : (
              ""
            )}
          </p> */}
          </div>
          {user.lastAccount && user.lastAccount !== "" ? (
            <></>
          ) : (
            <div
              className="ht__planPro-payBtn"
              onClickCapture={() => {
                window.open(
                  "https://secure.conviertemas.com/hello-targeting-softwre-con-ai/?utm_campaign=ht-upgrade&utm_medium=ht-ai-prompt&utm_source=ht-app"
                );
              }}
            >
              <IntlMessages id="pages.account.plans.upgradeplan" />
            </div>
          )}
          {!user.lifetime && user.initPlanDate && user.endPlanDate ? (
            <div className="ht__planPro-date">
              <p>
                <IntlMessages id="pages.account.plans.validateaccount" />{" "}
                {dateInit} <IntlMessages id="pages.account.plans.validateTo" />{" "}
                {finalDate}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* ) : (
              // <p>Actualiza a una cuenta pro</p>
              <></>
            )} */}
      </div>
    </div>
  );
};

export default ContainerPlan;
