import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import iconPass from "../../assets/icons/ri_lock-password-line.svg";
import iconMail from "../../assets/icons/ri_mail-line.svg";
import logo from "../../assets/img/logo_login.svg";
import Spinner from "../../components/spinner/Spinner";
import IntlMessages from "../../helpers/IntlMessages";
import { useLogin } from "../../hooks/useLogin";

const LoginScreen = () => {
  const history = useHistory();
  const { values, handleChange, onClickSend } = useLogin({
    email: "",
    password: "",
  });

  const tester = new URLSearchParams(window.location.search).get("tester");
  localStorage.setItem("tester", "true");

  const userLang = useSelector<any, any>((state) => state.cuenta.lang);

  const { fetchLogin } = useSelector((state: any) => state.cuenta);
  const { email, password } = values;

  return (
    <div className="ht__loginScreen">
      <div className="ht__loginScreen-container">
        <div>
          <div className="ht__loginScreen-container-logo">
            <img src={logo} alt="Hello targeting" />
          </div>
        </div>
        <div>
          <div className="ht__loginScreen-container-info">
            {
              <>
                <h4>
                  <IntlMessages id="pages.login.sigin" />
                </h4>
                <div>
                  <label htmlFor="email">
                    <IntlMessages id="label.input.email" />
                  </label>
                  <div>
                    <img src={iconMail} alt="Password" />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder={
                        userLang === "es-ES" ? "Tu correo" : "Your Email"
                      }
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                  <label htmlFor="password">
                    <IntlMessages id="label.input.password" />
                  </label>
                  <div>
                    <img src={iconPass} alt="Password" />
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder={
                        userLang === "es-ES" ? "Tu contraseña" : "Your Password"
                      }
                      value={password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <p onClickCapture={() => history.push("/recover-password")}>
                  <IntlMessages id="pages.login.qforgotpass" />
                </p>
                <div
                  className="ht__loginScreen-btnLogin"
                  onClickCapture={() => {
                    if (!fetchLogin) {
                      onClickSend();
                    }
                  }}
                >
                  {fetchLogin ? (
                    <Spinner cssClass="" />
                  ) : (
                    <IntlMessages id="pages.login.sigin" />
                  )}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
