import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import iconFiltro from "../../assets/icons/ri_filter-3-line.svg";
import iconArrow from "../../assets/icons/Arrow_2.svg";
import findIcon from "../../assets/icons/ri_search-line.svg";
import resetIcon from "../../assets/icons/ri_refresh-line.svg";
import FiltroComponent from "../../components/filtroComponents/FiltroComponent";
import { InterestFindInterface } from "../../interfaces/interface";
import DataTable from "react-data-table-component";
import {
  addInterest,
  addToSuggestionList,
  consultSuggestions,
  filterReset,
  removeInterestToList,
  removeInterestToSuggestion,
} from "../../redux/actions/interesesAction";
import Spinner from "../../components/spinner/Spinner";
import { NotificationManager } from "../../components/common/react-notifications/index";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import LogsAi from "./LogsAi";

const ContainerListIntereses = () => {
  const [openFiltro, setOpenFltro] = useState(false);
  const [resetCss, setResetCss] = useState(false);
  const [interestsFindFilter, setInterestsFindFilter] = useState<any>(null);
  const [interestsSuggestionsFilter, setInterestsSuggestionsFilter] =
    useState<any>(null);
  const dispatch = useDispatch();
  const {
    listSuggestionsActive,
    listFindIntereses,
    keyword,
    listIntereses,
    listInterestSuggestion,
    fetchSuggestions,
    lang,
    filterFindInteresCategory,
    filtersInterest,
  } = useSelector((state: any) => state.intereses);
  const { facebookToken } = useSelector((state: any) => state.cuenta.user);
  const { cuenta } = useSelector((state: any) => state);
  const currentAppLocale = AppLocale[cuenta.lang === "es-ES" ? "es" : "en"];

  const verifyActiveSuggestion = (id: string) => {
    var verify = false;
    listSuggestionsActive?.map((suggestion: InterestFindInterface) => {
      if (suggestion.id === id) {
        verify = true;
      }
      return true;
    });
    return verify;
  };

  const addInterstToList = ({ id, name }: InterestFindInterface) => {
    // const length = listIntereses?.length;
    const listTemp = listIntereses?.filter(
      (interest: InterestFindInterface) => interest.id === id
    );
    if (listTemp?.length === 0) {
      dispatch(addInterest({ id, name }, addNotification, listIntereses));
    } else {
      dispatch(removeInterestToList(id));
      const listSugg = listSuggestionsActive?.filter(
        (interest: InterestFindInterface) => interest.id === id
      );
      if (listSugg?.length > 0) {
        dispatch(removeInterestToSuggestion(id));
      }
    }
  };

  const addListSuggestions = (value: any) => {
    dispatch(addToSuggestionList(value, listSuggestionsActive));
  };

  const findSuggestion = () => {
    const listString: Array<any> = [];
    listSuggestionsActive.forEach((value: InterestFindInterface) => {
      const newObject = {
        name: value.name,
        id: value.id,
        type: "interests",
      };
      listString.push(newObject);
    });
    dispatch(consultSuggestions(JSON.stringify(listString), lang));
  };

  const addNotification = () => {
    return NotificationManager.success(
      `${
        cuenta.lang === "es-ES"
          ? "Se agregó el interés a tu lista"
          : "Interest was added to your list"
      }`,
      `${cuenta.lang === "es-ES" ? "Interés agregado" : "Added interest"}`,
      1500,
      null,
      null,
      ""
    );
  };

  const colums = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      omit: true,
    },
    {
      name: cuenta.lang === "es-ES" ? "Interés" : "interest",
      selector: "name",
      sortable: true,
      wrap: true,
    },
    {
      name: cuenta.lang === "es-ES" ? "Tamaño" : "Size",
      selector: "audience_size",
      sortable: true,
    },
    {
      name: "Path",
      selector: "path",
      sortable: true,
      wrap: true,
    },
  ];

  const paginacionOpciones = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };
  const paginacionOpcionesEn = {
    rowsPerPageText: "Rows per page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const filterFindInterest = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value === "") {
      setInterestsFindFilter(null);
    } else {
      let tempArray: any = [];
      if (filterFindInteresCategory.length > 0) {
        tempArray = filterFindInteresCategory?.filter(
          (interest: InterestFindInterface) => {
            if (
              interest.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
            ) {
              return interest;
            }
          }
        );
      } else {
        tempArray = listFindIntereses?.filter(
          (interest: InterestFindInterface) => {
            if (
              interest.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
            ) {
              return interest;
            }
          }
        );
      }
      //  tempArray =
      //   filterFindInteresCategory.length > 0
      //     ? filterFindInteresCategory
      //     : listFindIntereses?.filter((interest: InterestFindInterface) => {
      //         if (
      //           interest.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      //         ) {
      //           return interest;
      //         }
      //       });
      setInterestsFindFilter(tempArray);
    }
  };
  const filterSuggestionInterest = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value === "") {
      setInterestsSuggestionsFilter(null);
    } else {
      const tempArray = listInterestSuggestion?.filter(
        (interest: InterestFindInterface) => {
          if (interest.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            return interest;
          }
        }
      );

      setInterestsSuggestionsFilter(tempArray);
    }
  };

  const changeResetFilters = () => {
    dispatch(filterReset(setResetCss));
  };

  const conditionalRowStyles = [
    {
      when: (row: any) => {
        let filterInteres = listIntereses.filter(
          (i: any) => i.name === row.name
        );
        return filterInteres.length > 0 ? true : false;
      },
      style: {
        backgroundColor: "#f3f3f3",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  useEffect(() => {
    if (listSuggestionsActive?.length > 0 && facebookToken !== "") {
      findSuggestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSuggestionsActive, facebookToken]);
  return (
    <>
      <div className="ht_containerListIntereses">
        <div className="ht_containerListIntereses-header">
          <div className="ht_containerListIntereses-header-left">
            <p>
              <IntlMessages id="pages.interest.searchResult" />
            </p>
          </div>
          <div className="ht_containerListIntereses-header-right">
            <div className="ht_containerListIntereses-list-containerinput">
              <img src={findIcon} alt="Buscar" />
              <input
                className="ht_containerListIntereses-list-input"
                type="text"
                placeholder={
                  currentAppLocale.messages["pages.audience.placeholderFind"]
                }
                onChange={(e) => {
                  filterSuggestionInterest(e);
                  filterFindInterest(e);
                }}
              />
            </div>
            <div
              className="container-filtro"
              onClickCapture={() => setOpenFltro(true)}
            >
              {Object.keys(filtersInterest?.category)?.length > 0 ||
              filtersInterest?.size?.minimo > 0 ||
              filtersInterest?.size?.maximo > 0 ? (
                <div className="container-filtro-filtersNum">
                  {filtersInterest?.category.length +
                    ((filtersInterest?.size?.minimo > 0 ? 1 : 0) +
                      (filtersInterest?.size?.maximo > 0 ? 1 : 0))}
                </div>
              ) : (
                <img src={iconFiltro} alt="Filtro" />
              )}
              <p>
                {" "}
                <IntlMessages id="pages.interest.filter" />{" "}
              </p>
            </div>
            <div
              className="container-reset"
              onClickCapture={changeResetFilters}
            >
              <img
                className={`${resetCss ? "ht__icon-reset" : ""} `}
                src={resetIcon}
                alt="Resetear"
                title="Resetear Filtros"
              />
            </div>
          </div>
        </div>

        <div className="ht__containerListIntereses-row">
          <div className="ht_containerListIntereses-interests">
            <LogsAi />
            <p>
              <IntlMessages id="pages.interest.realtedTo" />{" "}
              <span>{keyword}</span>
              <span className="num-intereses">
                (
                {interestsFindFilter
                  ? `${interestsFindFilter!.length} / ${
                      listFindIntereses.length
                    }`
                  : filterFindInteresCategory.length > 0
                  ? `${filterFindInteresCategory!.length} / ${
                      listFindIntereses.length
                    }`
                  : listFindIntereses?.length}
                )
              </span>
            </p>
            <div className="ht_containerListIntereses-list">
              <DataTable
                pagination
                // paginationRowsPerPageOptions={[15, 20, 25]}
                paginationPerPage={
                  localStorage.getItem("ht__paginationInterest")
                    ? parseInt(localStorage.getItem("ht__paginationInterest")!)
                    : undefined
                }
                onChangeRowsPerPage={(e) => {
                  localStorage.setItem("ht__paginationInterest", e.toString());
                }}
                paginationComponentOptions={
                  cuenta?.lang === "es-ES"
                    ? paginacionOpciones
                    : paginacionOpcionesEn
                }
                className="data-table"
                columns={colums}
                data={
                  interestsFindFilter
                    ? interestsFindFilter
                    : filterFindInteresCategory.length > 0
                    ? filterFindInteresCategory
                    : listFindIntereses
                }
                fixedHeader
                fixedHeaderScrollHeight="600px"
                onRowClicked={(e) => {
                  addInterstToList(e);
                }}
                conditionalRowStyles={conditionalRowStyles}

                // subHeaderComponent={() => <input type="text" />}
                // subHeader={true}
              />
            </div>
          </div>
          {/* CONTAINER SUGGESTIONS */}
          <div className="ht_containerListIntereses-suggestions">
            <p>
              <IntlMessages id="pages.interest.suggestion" />
              {listInterestSuggestion?.length > 0 ? (
                <span className="num-intereses">
                  {/* (
                  {interestsSuggestionsFilter
                    ? `${interestsSuggestionsFilter!.length} / ${
                        listInterestSuggestion?.length
                      }`
                    : filterFindInteresCategory.length > 0
                    ? `${filterFindInteresCategory!.length} / ${
                        listInterestSuggestion?.length
                      }`
                    : listInterestSuggestion?.length}
                  ) */}
                  (
                  {interestsSuggestionsFilter
                    ? `${interestsSuggestionsFilter!.length} / ${
                        listInterestSuggestion?.length
                      }`
                    : listInterestSuggestion?.length}
                  )
                </span>
              ) : (
                <></>
              )}
            </p>
            {listInterestSuggestion?.length > 0 ? (
              <div className="container-parrafo">
                <p>
                  <IntlMessages id="pages.interest.searchRelatedTo" />
                </p>
              </div>
            ) : (
              <></>
            )}
            <div className="container-suggestions">
              {listIntereses?.map((interes: InterestFindInterface) => (
                <div
                  key={interes.id}
                  className={`${
                    verifyActiveSuggestion(interes.id) ? "active" : ""
                  } interes`}
                  onClickCapture={() => addListSuggestions(interes)}
                >
                  <p>{interes.name}</p>
                </div>
              ))}
            </div>

            {fetchSuggestions ? (
              <div className="ht_containerListIntereses-loadSuggestion">
                <p>
                  <IntlMessages id="pages.interest.searchingSuggestions" />
                </p>
                <Spinner cssClass="ht_interesesScreen-spinner" />
              </div>
            ) : listSuggestionsActive?.length > 0 &&
              listInterestSuggestion?.length > 0 ? (
              <div className="ht_containerListIntereses-list">
                {/* Suggestion Table */}
                <DataTable
                  pagination
                  className="data-table"
                  paginationComponentOptions={
                    cuenta?.lang === "es-ES"
                      ? paginacionOpciones
                      : paginacionOpcionesEn
                  }
                  columns={colums}
                  data={interestsSuggestionsFilter ?? listInterestSuggestion}
                  fixedHeader
                  fixedHeaderScrollHeight="600px"
                  onRowClicked={(e) => {
                    addInterstToList(e);
                  }}
                  conditionalRowStyles={conditionalRowStyles}
                  paginationPerPage={
                    localStorage.getItem("ht__paginationSuggestion")
                      ? parseInt(
                          localStorage.getItem("ht__paginationSuggestion")!
                        )
                      : undefined
                  }
                  onChangeRowsPerPage={(e) => {
                    localStorage.setItem(
                      "ht__paginationSuggestion",
                      e.toString()
                    );
                  }}
                />
              </div>
            ) : listIntereses?.length > 0 ? (
              <div className="ht_containerListIntereses-noList">
                <div>
                  <img src={iconArrow} alt="Selecciona interes" />
                  <h4>
                    {" "}
                    <IntlMessages id="pages.interest.select" />{" "}
                  </h4>
                </div>
                <p>
                  <IntlMessages id="pages.interest.selectDescription" />
                </p>
              </div>
            ) : (
              <div className="ht_containerListIntereses-noList">
                <p>
                  <IntlMessages id="pages.interest.selectEmptyDescription" />
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {openFiltro ? (
        <FiltroComponent funcion={setOpenFltro} isAudiencia={false} />
      ) : (
        <></>
      )}
    </>
  );
};
export default ContainerListIntereses;
