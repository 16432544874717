import arrowIcon from "../../assets/icons/ri_arrow-drop-down-fill.svg";
import fbIcon from "../../assets/icons/ri_facebook-circle-fill.svg";
import closeIcon from "../../assets/icons/ri_close-fill.svg";
import iconInfo from "../../assets/icons/icon-modal-fb.svg";
import { useDispatch, useSelector } from "react-redux";
import ReactFacebookLogin from "react-facebook-login";
import {
  changeLanguageInit,
  loginFacebook,
  unlinkFacebook,
  updateUserData,
} from "../../redux/actions/cuentaAction";
import { IUser } from "../../interfaces/interface";
import IntlMessages from "../../helpers/IntlMessages";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { getLanguagebyLang } from "../../helpers";
import Spinner from "../../components/spinner/Spinner";
import moment from "moment";
import AppLocale from "../../lang";

const InformacionPersonal = () => {
  const dispatch = useDispatch();
  const { facebookName, avatar, facebookToken, isGuest } = useSelector<
    any,
    IUser
  >((state: any) => state.cuenta?.user);
  const { fetchUpdate } = useSelector((state: any) => state.cuenta);
  const user = useSelector<any, IUser>((state) => state.cuenta.user);
  const userLang = useSelector<any, any>((state) => state.cuenta.lang);
  moment.locale(userLang === "en-US" ? "en" : "es");
  const [openLang, SetOpenLang] = useState<boolean>(false);
  const [showInput, setshowInput] = useState<boolean>(false);
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState("");

  const currentAppLocale = AppLocale[userLang === "es-ES" ? "es" : "en"];

  const onHandleFacebookLogin = (response: any) => {
    if (response.accessToken) {
      console.log(response);
      let dataFacebook = {
        token: response.accessToken,
        name: response.name,
        avatar: response.picture.data.url,
      };
      dispatch(loginFacebook(dataFacebook, userLang));
    }
  };

  const openLanguage = () => {
    SetOpenLang(!openLang);
  };

  const changeLang = (l: string) => {
    // Action to change lang
    dispatch(changeLanguageInit(l));
    dispatch(
      updateUserData({
        lang: l === "es-ES" ? "es" : "en",
      })
    );
    SetOpenLang(!openLang);
  };
  const savedata = () => {
    if (!fetchUpdate) {
      dispatch(
        updateUserData(
          {
            name: user.name === name ? null : name,
            email: user.email === email ? null : email,
            password: password === "" ? null : password,
          },
          currentAppLocale.messages["alerts.updateUserSuccess"],
          currentAppLocale.messages["alerts.updateUserError"]
        )
      );
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else {
      if (name === "email") {
        setEmail(value);
      } else {
        if (name === "password") {
          setPassword(value);
        }
      }
    }
  };

  const unlinkAccount = () => {
    dispatch(unlinkFacebook());
  };

  return (
    <div className="ht__miCuenta-container">
      <div className="ht__miCuenta-container-title">
        <h4>
          <IntlMessages id="pages.account" />
        </h4>
      </div>
      <div className="ht__miCuenta-container-row">
        <div>
          <div className="ht__miCuenta-container-formulario">
            <label htmlFor="name">
              <IntlMessages id="label.input.name" />
            </label>
            <div className="icon-input">
              <input
                type="text"
                name="name"
                id="name"
                placeholder={userLang === "es-ES" ? "Tu nombre" : "Your name"}
                value={name}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <label htmlFor="email">
              <IntlMessages id="label.input.email" />
            </label>
            <div className="icon-input">
              {/* <img src={emailIcon} alt="Email" /> */}
              <input
                type="email"
                name="email"
                id="email"
                placeholder={
                  userLang === "es-ES" ? "Tu Correo Electrónico" : "Your e-mail"
                }
                value={email}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          {!showInput ? (
            <div className="ht__miCuenta-container-password">
              <p>
                <IntlMessages id="label.input.password" />
              </p>
              <div>
                <p>
                  <IntlMessages id="pages.account.updatedAt" />{" "}
                  {moment(user.updatedAt).format("ll")}
                </p>
                <div
                  className="btn-change-pass"
                  onClickCapture={() => setshowInput(true)}
                >
                  <IntlMessages id="pages.account.changepass" />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {showInput ? (
            <div className="ht__miCuenta-inputPassword">
              <label htmlFor="password">
                <IntlMessages id="pages.account.newPass" />
              </label>
              <div className="ht__miCuenta-inputPassword-row">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder={
                    userLang === "es-ES"
                      ? "Escribe tu contraseña"
                      : "Enter your password"
                  }
                  value={password}
                  onChange={(e) => handleChange(e)}
                />
                <img
                  src={closeIcon}
                  alt="Close"
                  onClickCapture={() => setshowInput(false)}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="ht__miCuenta-container-idioma">
            <p>
              <IntlMessages id="label.input.lang" />
            </p>
            <div className="ht__dropdown">
              <div className="ht__dropdown-btn" onClickCapture={openLanguage}>
                <p>{getLanguagebyLang(userLang)}</p>
                <img src={arrowIcon} alt="Más" />
              </div>
              {openLang && (
                <>
                  <div className="ht__dropdown-content">
                    <div
                      className="ht__dropdown-item"
                      onClickCapture={() => changeLang("es-ES")}
                    >
                      <IntlMessages id="label.es" />
                    </div>
                    <div
                      className="ht__dropdown-item"
                      onClickCapture={() => changeLang("en-US")}
                    >
                      <IntlMessages id="label.en" />
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* <div className="btn-change-idioma">
              <p>Español</p>
              <img src={arrowIcon} alt="Más" />
            </div> */}
          </div>
          <div
            className="ht__miCuenta-container-saveBtn"
            onClickCapture={savedata}
          >
            {fetchUpdate ? (
              <Spinner cssClass="" />
            ) : userLang === "es-ES" ? (
              "Guardar"
            ) : (
              "Save"
            )}
          </div>
        </div>
        {/* FACEBOOK SECTION */}
        <div>
          <p>
            <IntlMessages id="pages.account.fb" />
          </p>

          {facebookToken === "" || !facebookToken ? (
            !isGuest ? (
              <>
                <ReactFacebookLogin
                  appId="1033138630189966"
                  autoLoad={false}
                  fields="name,email,picture"
                  scope={
                    "ads_management,ads_read,email,read_insights,public_profile,pages_show_list"
                  }
                  callback={onHandleFacebookLogin}
                  cssClass={"btn-facebook"}
                  textButton={
                    userLang === "es-ES"
                      ? "Vincular con Facebook"
                      : "Link with Facebook"
                  }
                  isMobile={false}
                  disableMobileRedirect={true}
                  version={"11.0"}
                />
                {/* <LoginSocialFacebook
                  ref={facebookRef}
                  appId={'1342007772672305'}
                  onLoginStart={onLoginStart}
                  onLogoutSuccess={onLogoutSuccess}
                  onResolve={({ provider, data }: IResolveParams) => {
                    console.log("Provider", provider);
                    console.log("Data", data);
                  }}
                  onReject={(err) => {
                    console.log(err)
                  }}
                  // version={'12.0'}
                  scope={
                    "ads_management,ads_read,email,read_insights,public_profile,pages_show_list"
                  }
                >
                  <FacebookLoginButton />
                </LoginSocialFacebook> */}
              </>
            ) : (
              <div className="ht__miCuenta-container-noaccountfb">
                <img src={iconInfo} alt="Informacion" />
                <p>
                  <IntlMessages id="pages.account.messagenofacebook" />
                </p>
              </div>
            )
          ) : (
            <>
              <div className="ht__miCuenta-container-perfil">
                <div>
                  <img src={avatar} alt="Perfil" />
                </div>
                <p>{facebookName}</p>
              </div>
              {/* BOTON DE FACEBOOK */}
              {!isGuest ? (
                <div className="ht__miCuenta-container-desvincularfb">
                  <div>
                    <img src={fbIcon} alt="" />
                  </div>
                  <p onClick={unlinkAccount}>
                    <IntlMessages id="pages.account.unLinkfb" />
                  </p>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InformacionPersonal;
