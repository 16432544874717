import iconClose from "../../assets/icons/ri_close-fill.svg";
import iconVerify from "../../assets/icons/ri_checkbox-circle-fill.svg";
// import imgPerfil from "../../assets/img/perfil.png";
import { IPropsModalFB, IUser } from "../../interfaces/interface";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import IntlMessages from "../../helpers/IntlMessages";

const ModalFBAccountSynced: React.FC<IPropsModalFB> = ({
  setOpenModalSynced,
}) => {
  const closeModal = () => {
    setOpenModalSynced!(false);
  };
  useEffect(() => {
    setTimeout(() => {
      closeModal();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { facebookName, avatar } = useSelector<any, IUser>(
    (state: any) => state.cuenta?.user
  );

  return (
    <div className="ht__modalNoFbAccount">
      <div className="ht__modalFBAccountSynced-header">
        <div className="ht__modalFBAccountSynced-header-profileImg">
          <div className="container-img">
            <img src={avatar} alt="Profile" />
          </div>
          <img src={iconVerify} alt="Verify" />
        </div>
        {/* <div className="ht__modalFBAccountSynced-header-close"> */}
        <img
          className="ht__modalFBAccountSynced-header-close"
          src={iconClose}
          alt="close"
          onClickCapture={() => setOpenModalSynced!(false)}
        />
        {/* </div> */}
      </div>
      <div className="ht__modalFBAccountSynced-name"> {facebookName} </div>
      <div className="ht__modalFBAccountSynced-message">
        <IntlMessages id="modal.facebookaccount.syncedText" />
      </div>
    </div>
  );
};

export default ModalFBAccountSynced;
