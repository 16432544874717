const LoadingComponent = () => {
  return (
    <div className="ht__loadingComponent">
      <div className="ht__loadingComponent-header">
        <div></div>
        <div></div>
      </div>
      <div className="ht__loadingComponent-cards">
        {/* Card 1 */}
        <div>
          <div className="ht__loadingComponent-cards-img"></div>
          <div className="ht__loadingComponent-cards-info">
            <div></div>
            <div></div>
          </div>
        </div>
        {/* Card 2 */}
        <div>
          <div className="ht__loadingComponent-cards-img"></div>
          <div className="ht__loadingComponent-cards-info">
            <div></div>
            <div></div>
          </div>
        </div>
        {/* Card 3 */}
        <div>
          <div className="ht__loadingComponent-cards-img"></div>
          <div className="ht__loadingComponent-cards-info">
            <div></div>
            <div></div>
          </div>
        </div>
        {/* Card 4 */}
        <div>
          <div className="ht__loadingComponent-cards-img"></div>
          <div className="ht__loadingComponent-cards-info">
            <div></div>
            <div></div>
          </div>
        </div>
        {/* Card 5 */}
        <div>
          <div className="ht__loadingComponent-cards-img"></div>
          <div className="ht__loadingComponent-cards-info">
            <div></div>
            <div></div>
          </div>
        </div>
        {/* Card 6 */}
        <div>
          <div className="ht__loadingComponent-cards-img"></div>
          <div className="ht__loadingComponent-cards-info">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingComponent;
