import { useSelector } from "react-redux";
import { abreviarNumeros } from "../../helpers";
import { IInterest, ILibrary } from "../../interfaces/library.interface";
// import GraphCategory from "./graphCategory";
import { TagCloud } from "react-tagcloud";
import { useEffect, useState } from "react";
import IntlMessages from "../../helpers/IntlMessages";

const ContainerGraphics = () => {
  const [bestInterest, setBestInterest] = useState<any>([]);
  const [averageSize, setAvergaeSize] = useState("");
  const [less100mil, setLess100mil] = useState("");
  const [b100mil500mil, setb100mil500mil] = useState("");
  const [b500mil1mill, setb500mil1mill] = useState("");
  const [b1mill2mill, setb1mill2mill] = useState("");
  const [b2mill, setb2mill] = useState("");

  const library = useSelector<any, ILibrary>(
    (state) => state.audiencias.library
  );

  const calcAverage = () => {
    const interests = library.interests;
    let totalSize = 0;

    interests?.forEach((interest: IInterest) => {
      if (interest) {
        totalSize += parseInt(interest.size);
      }
    });

    const formattedNum = abreviarNumeros(totalSize / interests.length);
    setAvergaeSize(formattedNum);
  };

  const calcSizeGraphics = () => {
    const interests = library.interests;
    let templess100mil = 0;
    let tempbetween100mil500mil = 0;
    let tempbetween500mil1mill = 0;
    let tempbetween1mill2mill = 0;
    let tempmore2mill = 0;

    interests?.forEach((interest: IInterest) => {
      if (interest) {
        const size = parseInt(interest.size);

        if (size < 100000) {
          templess100mil += 1;
        } else {
          if (size >= 100000 && size < 500000) {
            tempbetween100mil500mil += 1;
          } else {
            if (size >= 500000 && size < 1000000) {
              tempbetween500mil1mill += 1;
            } else {
              if (size >= 1000000 && size <= 2500000) {
                tempbetween1mill2mill += 1;
              } else {
                tempmore2mill += 1;
              }
            }
          }
        }
      }
    });

    templess100mil = (templess100mil * 100) / interests.length;
    setLess100mil(templess100mil.toFixed(2));
    tempbetween100mil500mil =
      (tempbetween100mil500mil * 100) / interests.length;
    setb100mil500mil(tempbetween100mil500mil.toFixed(2));
    tempbetween500mil1mill = (tempbetween500mil1mill * 100) / interests.length;
    setb500mil1mill(tempbetween500mil1mill.toFixed(2));
    tempbetween1mill2mill = (tempbetween1mill2mill * 100) / interests.length;
    setb1mill2mill(tempbetween1mill2mill.toFixed(2));
    tempmore2mill = (tempmore2mill * 100) / interests.length;
    setb2mill(tempmore2mill.toFixed(2));
  };

  useEffect(() => {
    if (bestInterest?.length <= 0) {
      const interests = library.interests;
      let mostRelevant = interests.sort((a, b) => (a.size > b.size ? 1 : -1));
      let relevant30 = mostRelevant.slice(0, 15);
      let relevantPush = relevant30.map((i) => ({
        value: i.name,
        count: i.size,
      }));
      setBestInterest(relevantPush);
    }
    calcAverage();
    calcSizeGraphics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ht__containerGraphics">
      <div className="ht__containerGraphics-left">
        {/* Size Graph */}
        <div className="ht__containerGraphics-left-size">
          <p className="title">
            <IntlMessages id="pages.audience.titleSizeGraphic" />{" "}
          </p>
          <p className="total-average"> {averageSize} </p>
          {/* GRÁFICO  */}
          <div className="ht__containerGraphics-bar">
            <div className="bar">
              <p>
                {" "}
                <IntlMessages id="pages.audience.less100thousand" />{" "}
              </p>
              <div className="statistics">
                <div
                  style={{
                    width: `${(parseInt(less100mil) * 90) / 100}%`,
                  }}
                  className="first-bar color1"
                ></div>
                <p>{less100mil}%</p>
              </div>
            </div>
            <div className="bar">
              <p>
                {" "}
                <IntlMessages id="pages.audience.between100m500m" />{" "}
              </p>
              <div className="statistics">
                <div
                  style={{
                    width: `${(parseInt(b100mil500mil) * 90) / 100}%`,
                  }}
                  className="first-bar color2"
                ></div>
                <p>{b100mil500mil}%</p>
              </div>
            </div>
            <div className="bar">
              <p>
                {" "}
                <IntlMessages id="pages.audience.between500m1mil" />{" "}
              </p>
              <div className="statistics">
                <div
                  style={{
                    width: `${(parseInt(b500mil1mill) * 90) / 100}%`,
                  }}
                  className="first-bar color3"
                ></div>
                <p>{b500mil1mill}%</p>
              </div>
            </div>
            <div className="bar">
              <p>
                <IntlMessages id="pages.audience.between1mill2mill" />
              </p>
              <div className="statistics">
                <div
                  style={{
                    width: `${(parseInt(b1mill2mill) * 90) / 100}%`,
                  }}
                  className="first-bar color4"
                ></div>
                <p>{b1mill2mill}%</p>
              </div>
            </div>
            <div className="bar">
              <p>
                {" "}
                <IntlMessages id="pages.audience.more2mill" />{" "}
              </p>
              <div className="statistics">
                <div
                  style={{
                    width: `${(parseInt(b2mill) * 90) / 100}%`,
                  }}
                  className="first-bar color5"
                ></div>
                <p>{b2mill}%</p>
              </div>
            </div>
          </div>
        </div>
        {/* Category Graph */}
      </div>
      {/* PRINCIPAL RIGHT */}
      <div className="ht__containerGraphics-right">
        <div className="ht__containerGraphics-left-category">
          {/* <p className="title">Categorías de los intereses</p> */}
          {/* <GraphCategory /> */}
          <p className="title">
            <IntlMessages id="pages.audience.relevantInterests" />
          </p>
          <TagCloud
            minSize={15}
            maxSize={25}
            shuffle={false}
            tags={bestInterest}
            // onClick={(tag: any) => alert(`'${tag.value}' was selected!`)}
          />
        </div>
      </div>
    </div>
  );
};
export default ContainerGraphics;
