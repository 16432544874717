import HeaderScreen from "../../components/header-screens/HeaderScreen";
import iconFind from "../../assets/icons/ri_search-line.svg";
import iconShop from "../../assets/icons/ri_shopping-cart-line.svg";
import iconDownload from "../../assets/icons/ri_download-line.svg";
import iconBin from "../../assets/icons/ri_delete-bin-4-line-normal.svg";
import iconArrowButton from "../../assets/icons/ri_arrow-right-s-line-blue.svg";
import iconArrowTop from "../../assets/icons/ri_arrow-top-s-line-blue.svg";
import iconReport from "../../assets/icons/report.svg";
import RowTableContent from "./RowTableContent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ModalComponent from "../../components/modalComponents/modalComponent";
import GenerarReporte from "./modal/generarReporte";
import { getreports, obtenerCuentas } from "../../redux/actions/reportesAction";
import { IReport } from "../../interfaces/report.interface";
import IntlMessages from "../../helpers/IntlMessages";
import ModalNoFBAccount from "../ModalNoFBAccount/ModalNoFBAccount";
import AppLocale from "../../lang";

const ReportesScreen = () => {
  const dispatch = useDispatch();
  const {
    reportes: { misReportes },
  } = useSelector((state: any) => state);
  const accessToken = useSelector((state: any) => state.cuenta.accessToken);
  const lang = useSelector((state: any) => state.cuenta.lang);
  const facebookToken = useSelector((state: any) => state.cuenta.facebookToken);
  const [showDialog, setShowDialog] = useState(false);
  const currentAppLocale = AppLocale[lang === "es-ES" ? "es" : "en"];
  const getReportsDispatch = () => {
    dispatch(getreports());
  };

  const startGenerateReport = () => {
    dispatch(obtenerCuentas());
    setShowDialog(true);
  };

  useEffect(() => {
    if (accessToken) {
      getReportsDispatch();
    }
  }, [accessToken]);

  return (
    <>
      <HeaderScreen showInput={false} title="Reporte" />
      <div className="ht__reportesScreen">
        {misReportes?.length > 0 ? (
          <>
            <div className="ht__reportesScreen-container">
              <div className="ht__reportesScreen-container-header">
                <p>
                  <IntlMessages id="pages.reports.myreports" />
                </p>
                <div>
                  <div className="icon-find">
                    <img src={iconFind} alt="Buscar" />
                  </div>
                  {/* <div className="ht__reportesScreen-container-header-clientes"> */}
                  <select name="clientes" id="clientes">
                    <option value="todos">
                      {currentAppLocale.messages["pages.interest.placeholder"]}
                    </option>
                  </select>
                  {/* </div> */}
                  <div
                    className="ht__reportesScreen-container-header-btnGenerar"
                    onClickCapture={() => startGenerateReport()}
                  >
                    <IntlMessages id="pages.reports.generate" />
                  </div>
                </div>
              </div>

              <div className="ht__reportesScreen-container-table-header">
                <div>
                  <IntlMessages id="pages.reports.campaign" />{" "}
                </div>
                <div>
                  <IntlMessages id="pages.reports.client" />
                </div>
                <div>
                  <IntlMessages id="pages.reports.date" />
                </div>
                <div>
                  <IntlMessages id="pages.reports.actions" />
                </div>
                <div></div>
              </div>
              {misReportes?.map((report: IReport) => (
                <RowTableContent
                  iconArrowButton={iconArrowButton}
                  iconBin={iconBin}
                  iconDownload={iconDownload}
                  iconShop={iconShop}
                  iconArrowTop={iconArrowTop}
                  report={report}
                />
              ))}
            </div>
            <div
              className="ht__reportesScreen-container-header-btnGenerar btn-generar-mobile"
              onClickCapture={() => setShowDialog(true)}
            >
              <IntlMessages id="pages.reports.initialCreate" />
            </div>
          </>
        ) : (
          <div className="ht__reportesScreen-noReport">
            <div>
              <img src={iconReport} alt="reporte" />
            </div>
            <h4>
              <IntlMessages id="pages.reports.create" />
            </h4>
            <p>
              <IntlMessages id="pages.reports.initialCreate" />
            </p>
            <div
              className="btn-reporte"
              onClickCapture={() => startGenerateReport()}
            >
              <IntlMessages id="pages.reports.generate" />
            </div>
          </div>
        )}
      </div>

      {showDialog ? (
        <ModalComponent
          stateShowDialog={setShowDialog}
          component={GenerarReporte}
        />
      ) : (
        <></>
      )}
      {facebookToken === "" ? (
        <ModalComponent classCss="fb-modalclass" component={ModalNoFBAccount} />
      ) : (
        <></>
      )}
    </>
  );
};

export default ReportesScreen;
