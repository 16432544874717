import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { NotificationManager } from "../../components/common/react-notifications";
import IntlMessages from "../../helpers/IntlMessages";
import { InterestFindInterface } from "../../interfaces/interface";
import { IInterest, ILibrary } from "../../interfaces/library.interface";
import AppLocale from "../../lang";
import { addInterestImported } from "../../redux/actions/interesesAction";

const ContainerInfoDescription: React.FC<any> = ({ interestSelected }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const library = useSelector<any, ILibrary>(
    (state) => state.audiencias.library
  );
  const listIntereses = useSelector<any, any>(
    (state) => state.intereses.listIntereses
  );

  const userLang = useSelector<any, any>((state) => state.cuenta.lang);
  const currentAppLocale = AppLocale[userLang === "es-ES" ? "es" : "en"];

  const addNotification = () => {
    return NotificationManager.success(
      currentAppLocale.messages["alerts.addinterestoktitle"],
      currentAppLocale.messages["alerts.addinterestokmessage"],
      1500,
      null,
      null,
      ""
    );
  };
  const addInterestToList = (interests: any) => {
    let newInterest;
    if (interestSelected.length > 0) {
      newInterest = interestSelected;
    } else {
      newInterest = interests.map(({ id, name }: any) => ({
        id,
        name,
      }));
    }
    let interestPass: any[] = [];
    newInterest = newInterest.map((interest: IInterest) => {
      let existe = listIntereses.filter((i: IInterest) => i.id === interest.id);
      if (existe.length <= 0) {
        interestPass.push(interest);
      }
    });

    dispatch(
      addInterestImported(interestPass, addNotification, userLang, history)
    );
  };

  const copyInterests = (interests: any) => {
    let newInterest;
    if (interestSelected.length > 0) {
      newInterest = interestSelected;
    } else {
      newInterest = interests.map(({ id, name }: any) => ({
        id,
        name,
      }));
    }

    let interestPass: any[] = [];
    newInterest = newInterest.map((interest: IInterest) => {
      let existe = listIntereses.filter((i: IInterest) => i.id === interest.id);
      if (existe.length <= 0) {
        interestPass.push(interest);
      }
    });
    try {
      var textField = document.createElement("textarea");
      let textCopy = "";
      interestPass.forEach((interest: InterestFindInterface) => {
        if (textCopy.length === 0) {
          textCopy = textCopy + `${interest.name}`;
        } else {
          textCopy = textCopy + `, ${interest.name}`;
        }
      });
      textField.innerText = textCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      NotificationManager.success(
        currentAppLocale.messages["alerts.copied"],
        "",
        1500,
        null,
        "",
        ""
      );
    } catch (error) {}
  };

  return (
    <div className="ht__containerInfoDescription">
      {/* Left */}
      <h4>{userLang === "es-ES" ? library.name : library.nameEN}</h4>
      <div className="ht__containerInfoDescription-row">
        <div className="ht__containerInfoDescription-left">
          <p>
            {" "}
            {userLang === "es-ES" ? library.description : library.descriptionEN}
          </p>
          <div className="ht__singleAudiencia-containerInfo-containerBtn">
            <div
              className="btnAgregar"
              onClick={() => addInterestToList(library.interests)}
            >
              <IntlMessages id="label.add" />
            </div>
            <div
              className="btn-copiar"
              onClickCapture={() => copyInterests(library.interests)}
            >
              <IntlMessages id="pages.interest.copy" />{" "}
            </div>
          </div>
        </div>
        {/* Right */}
        <div className="ht__containerInfoDescription-right">
          <div className="ht__containerInfoDescription-right-container">
            <p className="title">
              <IntlMessages id="pages.audience.interestNum" />
            </p>
            <p className="numero"> {library.interests?.length} </p>
          </div>
          {/* <div className="ht__containerInfoDescription-right-container">
            <p className="title">Número de interes</p>
            <p className="numero"> {library.interests?.length} </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContainerInfoDescription;
