import { SetStateAction, Dispatch, useState, useEffect } from "react";
import closeIcon from "../../../assets/icons/ri_close-fill.svg";
import arrowShow from "../../../assets/icons/arrow_metrics-show.svg";
import arrowenable from "../../../assets/icons/arrow_metrics-enable.svg";
import { reportMetrics } from "../../../constants/constants";
import IntlMessages from "../../../helpers/IntlMessages";

interface IMetricsPage {
  stateClose: Dispatch<SetStateAction<boolean>>;
  titleComponent: string;
}

const MetricsPage = ({ stateClose, titleComponent }: IMetricsPage) => {
  const [show, setShow] = useState(false);
  const [metrics, setMetrics] = useState<any[]>([]);

  const changeShow = () => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    const array = reportMetrics.find(
      (metric) => metric.name === titleComponent
    );
    if (array) {
      setMetrics(array.metrics);
    }
  }, []);
  return (
    <div className="ht__generarReporte ht__metricsPage">
      {/* //* HEADER COMPONENT */}
      <div className="ht__generarReporte-header">
        <div className="title">
          <h4>{titleComponent}</h4>
          <img
            src={closeIcon}
            alt="Close"
            onClickCapture={() => {
              //   if (!fetchGenerateReport) {
              stateClose(false);
              //   }
            }}
          />
        </div>
        <p>
          <IntlMessages id="modal.reports.page3.metrics.description" />
        </p>
      </div>
      {/* //* BODY COMPONENT */}
      <div className="ht__metricsPage-single" onClickCapture={changeShow}>
        <div>
          <p>CTR Saliente único</p>
        </div>
        <div className="ht__metricsPage-single-img">
          {!show ? (
            <img src={arrowShow} alt="show" />
          ) : (
            <img src={arrowenable} alt="Enabled" />
          )}
        </div>
      </div>
    </div>
  );
};

export default MetricsPage;
