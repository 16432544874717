import { useState } from "react";
import { useSelector } from "react-redux";

const whiteList = ["useradmin1@conviertemas.com"];

const LogsAi = () => {
  const [showLogs, setShowLogs] = useState(false);
  const { role, email } = useSelector((state: any) => state.cuenta.user);
  const { listFindAI } = useSelector((state: any) => state.intereses);

  if (role !== "admin") return <></>;
  if (!whiteList.includes(email)) return <></>;

  return (
    <div className="ht_logsAi">
      <div>
        <label>Mostrar Logs</label>
        <input
          type="checkbox"
          checked={showLogs}
          onChange={() => setShowLogs((s) => !s)}
        />
      </div>
      {showLogs && (
        <div className="ht_logsAi-containerList">
          <p>
            Ai:{" "}
            {listFindAI?.map((l: any, i: number) => {
              if (i === listFindAI.length - 1) {
                return `${l}`;
              }
              return `${l}, `;
            })}
          </p>
        </div>
      )}
    </div>
  );
};

export default LogsAi;
