import { useSelector } from "react-redux";
import IntlMessages from "../../helpers/IntlMessages";
import { useMiCuenta } from "../../hooks/useMiCuenta";
import { IUser } from "../../interfaces/interface";

const MiCuentaTabbar = () => {
  const {
    cuenta: { tabbar },
  } = useSelector((state: any) => state);

  const user = useSelector<any, IUser>((state: any) => state.cuenta.user);
  const typesAdmitsTeam = useSelector<any, any>(
    (state) => state.cuenta.typesAdmitsTeam
  );

  const { changeItemTabbar } = useMiCuenta();
  return (
    <>
      <div className="ht_interesesScreen-tabbar">
        <div
          onClickCapture={() => changeItemTabbar(0)}
          className={`${
            tabbar === 0 ? "ht__interesesScreen-tabbar-active" : ""
          }`}
        >
          <p>
            <IntlMessages id="pages.account.personalInformation" />
          </p>
        </div>

        {typesAdmitsTeam.includes(user.account) && !user.isGuest && (
          <div
            onClickCapture={() => changeItemTabbar(1)}
            className={`${
              tabbar === 1 ? "ht__interesesScreen-tabbar-active" : ""
            }`}
          >
            <p>
              <IntlMessages id="pages.workspace" />
            </p>
          </div>
        )}

        {!user.isGuest ? (
          <div
            onClickCapture={() => changeItemTabbar(2)}
            className={`${
              tabbar === 2 ? "ht__interesesScreen-tabbar-active" : ""
            }`}
          >
            <p>
              <IntlMessages id="pages.account.plans" />
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="ht__interesesScreen-border"></div>
    </>
  );
};

export default MiCuentaTabbar;
