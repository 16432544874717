import { createStore, applyMiddleware, compose } from "redux";
// import { reducers } from './reducers/reducer';
import { combineReducers } from "redux";
import thunk from 'redux-thunk';
import { interesesReducer } from "./reducers/interesesReducer";
import { audienciasReducer } from "./reducers/audienciasReducer";
import { cuentaReducer } from "./reducers/cuentaReducer";
import { reportesReducer } from "./reducers/reportesReducer";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    intereses: interesesReducer,
    audiencias: audienciasReducer,
    cuenta: cuentaReducer,
    reportes: reportesReducer
});

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
);