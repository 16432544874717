import { useSelector } from "react-redux";
import Spinner from "../../../components/spinner/Spinner";
import IntlMessages from "../../../helpers/IntlMessages";
import ContainerListIntereses from "../ContainerListIntereses";
import InputFindInteres from "../InputFindInteres";

const InteresSectionComplete = () => {
  const { fetchFindInterests } = useSelector((state: any) => state.intereses);
  return (
    <>
      <InputFindInteres fromHome={false} />
      {fetchFindInterests ? (
        <div className="ht_interesesScreen-buscando">
          <h4>
            {" "}
            <IntlMessages id="title.search.interest" />{" "}
          </h4>
          <Spinner cssClass="ht_interesesScreen-spinner" />
        </div>
      ) : (
        <ContainerListIntereses />
      )}
    </>
  );
};

export default InteresSectionComplete;
